import React, { useState } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-unresolved
import { useRoomConnection } from "@whereby.com/browser-sdk/react";
import { Box, Typography } from "@mui/material";
import IconButton from "./IconButton";

function MeetingRoomComponent(props) {
  const { roomUrl, localUserName, remoteUserName, handleClose } = props;

  MeetingRoomComponent.propTypes = {
    roomUrl: PropTypes.string.isRequired,
    localUserName: PropTypes.string.isRequired,
    remoteUserName: PropTypes.string.isRequired,
  };

  const [isCameraActive, setIsCameraActive] = useState(true);
  const [isMicrophoneActive, setIsMicrophoneActive] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const roomConnection = useRoomConnection(roomUrl, {
    localMediaOptions: {
      audio: true,
      video: true,
    },
  });

  const { actions, components, state } = roomConnection;
  const { VideoView } = components;
  const { localParticipant, remoteParticipants } = state;
  const { toggleCamera, toggleMicrophone } = actions;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          position: isFullScreen ? "absolute" : "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          width: { xs: "96%", md: isFullScreen ? "96%" : "46%" },
          height: "90vh",
          backgroundColor: remoteParticipants[0]?.isVideoEnabled
            ? ""
            : "#cecece50",
          margin: "2%",
          zIndex: isFullScreen ? 1000 : 0,
        }}
      >
        {remoteParticipants[0]?.stream ? (
          <VideoView
            stream={remoteParticipants[0].stream}
            style={{ borderRadius: "20px", backgroundColor: "#000" }}
          />
        ) : (
          <Typography variant="body1">Sem vídeo!</Typography>
        )}
        <Box
          style={{
            backgroundColor: "#000000a6",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "4px",
            margin: "10px",
            color: "#fff",
          }}
        >
          <Typography variant="body1">{remoteUserName}</Typography>
        </Box>
      </Box>
      {!isFullScreen && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            width: { xs: "96%", md: "46%" },
            height: "90vh",
            backgroundColor: isCameraActive ? "" : "#cecece50",
            margin: "2%",
          }}
        >
          {localParticipant?.stream && isCameraActive ? (
            <VideoView
              muted
              stream={localParticipant.stream}
              style={{
                borderRadius: "10px",
                backgroundColor: "#000",
              }}
            />
          ) : (
            <Typography variant="body1">Sem vídeo!</Typography>
          )}
          <Box
            style={{
              backgroundColor: "#000000a6",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "4px",
              margin: "10px",
              marginBottom: "50px",
              color: "#fff",
            }}
          >
            <Typography variant="body1">{localUserName}</Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          bottom: "0px",
          right: "80px",
          margin: "10px",
          zIndex: 2000,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#fff",
            width: "100%",
            padding: "20px",
            borderRadius: "20px",
            gap: "20px",
            boxShadow: "0px 1px 4px 0px #00000029",
          }}
        >
          <IconButton
            variant="camera"
            isActive={isCameraActive}
            onClick={() => {
              setIsCameraActive((prev) => !prev);
              toggleCamera();
            }}
          />
          <IconButton
            variant="microphone"
            isActive={isMicrophoneActive}
            onClick={() => {
              setIsMicrophoneActive((prev) => !prev);
              toggleMicrophone();
            }}
          />
          <IconButton
            variant="fullscreen"
            isActive={isFullScreen}
            onClick={() => {
              setIsFullScreen((prev) => !prev);
              if (isMicrophoneActive) {
                setIsMicrophoneActive(true);
              }
            }}
          />
          <IconButton variant="close" onClick={() => handleClose()} />
        </Box>
      </Box>
    </Box>
  );
}

export default MeetingRoomComponent;
