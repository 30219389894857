import React, { useEffect, useState } from "react";
import routes from "routes";

import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKInput from "components/MKInput";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

// images
import LogoMPO from "assets/images/logo_horizontal.png";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import { api } from "../../../lib/axios";

const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

function Marketing() {
  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const [services, setServices] = useState([]);
  const [servicesAvaliable, setServicesAvaliable] = useState([]);
  const [marketingsAvaliable, setMarketingsAvaliable] = useState([]);

  const [marketingId, setMarketingId] = useState("");
  const [message, setMessage] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [active, setActive] = useState(true);

  const [openNewEditModal, setOpenNewEditModal] = useState(false);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const toggleSwitch = () => setActive(!active);

  const clearMarketing = () => {
    setMarketingId("");
    setMessage("");
    setServiceId("");
    setActive(true);
  };

  const setMarketingInfo = (cashbackInfoSelected) => {
    setMarketingId(cashbackInfoSelected.id);
    setMessage(cashbackInfoSelected.message);
    setServiceId(cashbackInfoSelected.service.id);
    setActive(cashbackInfoSelected.active);
  };

  const getServices = async () => {
    setLoading(true);
    try {
      const responseServices = await api.get("services");
      setServices(responseServices.data);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getMarketings = async () => {
    setLoading(true);
    try {
      const responseMarketing = await api.get("/marketing");
      const marketingsSelected = responseMarketing.data;
      setMarketingsAvaliable(marketingsSelected);
      const avaliable = services.filter((service) =>
        !marketingsSelected.some(
          (cashback) => cashback.service.id === service.id,
        )
          ? service
          : null,
      );
      setServicesAvaliable(avaliable);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleOpenNewEditModal = (row = null) => {
    clearMarketing();
    if (row) {
      setMarketingInfo(row);
    }
    setOpenNewEditModal(true);
  };

  const handleCloseNewEditModal = () => {
    clearMarketing();
    setOpenNewEditModal(false);
    getMarketings();
  };

  const createUpdateMarketing = async () => {
    setLoading(true);
    if (message === "" || serviceId === "") {
      setAlertComponent({
        message: "Preencha os campos corretamente",
        type: "error",
      });
    } else {
      const marketingInfo = {
        message,
        serviceId,
        active,
      };
      try {
        if (marketingId === "") {
          await api.post("marketing", marketingInfo);
          setAlertComponent({
            message: "Marketing criado com sucesso!",
            type: "success",
          });
        } else {
          await api.put(`marketing/${marketingId}`, marketingInfo);
          setAlertComponent({
            message: "Marketing atualizado com sucesso!",
            type: "success",
          });
        }
        handleCloseNewEditModal();
      } catch (err) {
        setAlertComponent({
          message: err.response?.data?.message || "Ocorreu um erro!",
          type: "error",
        });
      }
    }
    setLoading(false);
  };

  const extractServiceName = (params) => {
    const serviceName = params.row.service.name || "";
    return <span>{serviceName}</span>;
  };

  const extractStatus = (params) => {
    if (params.row.active) {
      return <span>SIM</span>;
    }
    return <span>NÃO</span>;
  };

  const columns = [
    {
      field: "service",
      headerName: "Serviço",
      flex: 1,
      headerClassName: "dataGrid-header",
      renderCell: extractServiceName,
    },
    {
      field: "message",
      headerName: "Mensagem",
      flex: 2,
      headerClassName: "dataGrid-header",
    },
    {
      field: "status",
      headerName: "Ativo",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      renderCell: extractStatus,
    },
    {
      field: "actions",
      headerName: "Operações",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          <IconButton size="large" onClick={() => handleOpenNewEditModal(row)}>
            <EditIcon />
          </IconButton>
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    async function getInfos() {
      if (
        Object.keys(marketingsAvaliable).length === 0 &&
        services.length > 0
      ) {
        await getMarketings();
      }
    }
    getInfos();
  }, [services]);

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      if (Object.keys(services).length === 0) {
        await getServices();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>

      {/* Modal de Criação/Edição */}
      <Dialog
        open={openNewEditModal}
        onClose={handleCloseNewEditModal}
        fullScreen={false}
        scroll="body"
        aria-labelledby="create-coupon-title"
        aria-describedby="create-coupon-description"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle id="create-update-coupon-title" style={styles.dialogTitle}>
          <div>
            <h3>
              {marketingId === "" ? "Criação de " : "Edição de "}Marketing
            </h3>
          </div>
          <div>
            <IconButton onClick={handleCloseNewEditModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {alertComponent.message && (
              <Grid item xs={12}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <MKBox py={1} mx={2}>
                {Object.keys(services).length > 0 && (
                  <FormControl fullWidth>
                    <InputLabel id="serviceNameSelect">Serviço</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="serviceNameSelect"
                      id="serviceId"
                      value={serviceId ?? ""}
                      label="Serviço"
                      sx={{ height: "45px" }}
                      onChange={(e) => setServiceId(e.target.value)}
                    >
                      {serviceId
                        ? services.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            selected={item.id === serviceId}
                          >
                            {item.name}
                          </MenuItem>
                        ))
                        : servicesAvaliable.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            selected={item.id === serviceId}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </MKBox>
              <MKBox py={1} mx={2}>
                <MKInput
                  variant="outlined"
                  label="Mensagem"
                  name="message"
                  multiline
                  rows={8}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  fullWidth
                  required
                />
              </MKBox>
              <MKBox
                width="100%"
                py={1}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Switch checked={active} onChange={toggleSwitch} />
                <MKBox lineHeight={0.5}>
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="bold"
                  >
                    Ativar / Desativar Marketing
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <MKBox width="100%" p={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={clearMarketing}
                  fullWidth
                >
                  Limpar
                </MKButton>
              </MKBox>
              <MKBox width="100%" p={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={createUpdateMarketing}
                  fullWidth
                >
                  Gravar Dados
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        component="section"
        position="relative"
        py={20}
        width="100%"
        height="95vh"
      >
        <Grid
          container
          alignItems="top"
          sx={{ display: "flex", alignContent: "stretch" }}
        >
          <Grid item xs={12} lg={12} py={3}>
            <MKBox
              sx={{
                height: "75vh",
                width: "100%",
                paddingX: "10%",
                margin: "10px",
                textAlign: "center",
                "& .dataGrid-header": {
                  backgroundColor: "#00bbd4",
                  color: "#fff",
                  fontWeight: "bold",
                },
              }}
            >
              {alertComponent.message && (
                <Grid item xs={12}>
                  <MKAlert color={alertComponent.type}>
                    {alertComponent.message}
                  </MKAlert>
                </Grid>
              )}
              <DataGrid
                rows={marketingsAvaliable}
                columns={columns}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              />
            </MKBox>
            {Object.keys(servicesAvaliable).length > 0 && (
              <Fab
                sx={{ position: "absolute", bottom: 40, right: 20 }}
                color="success"
                aria-label="add"
                onClick={() => handleOpenNewEditModal()}
              >
                <AddIcon color="white" />
              </Fab>
            )}
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default Marketing;
