/* eslint-disable import/no-unresolved */
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// SwiperJS
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

// @mui material components
import { Grid, Container } from "@mui/material";
import Icon from "@mui/material/Icon";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";

// Otis Kit PRO examples
import EmployCardsCarrossel from "components/EmployCardsCarrossel";

import { api } from "../../../lib/axios";

function CarouselPsychiatrist() {
  const history = useHistory();
  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [professionals, setProfessionals] = useState([]);

  const getProfessionals = async () => {
    try {
      const response = await api.get(
        "/professionals?service_group_id=902aed78-0ce7-4c43-b558-d09654eea16a",
      );
      setProfessionals(response.data.filter((prof) => prof.status === true));
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
  };

  useEffect(() => {
    async function getInfos() {
      await getProfessionals();
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  const navigationStyles = {
    position: "absolute",
    top: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
    height: "100%",
    textAlign: "center",
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <MKBox position="relative" sx={{ cursor: "pointer" }}>
      <MKBox
        display="flex"
        justifyContent="center"
        alignItems={{ xs: "center", md: "flex-start" }}
      >
        <MKTypography
          variant="body1"
          fontWeight="medium"
          textTransform="uppercase"
        >
          Nossos Profissionais
        </MKTypography>
      </MKBox>
      {alertComponent.message && (
        <Grid item xs={12}>
          <MKAlert color={alertComponent.type}>
            {alertComponent.message}
          </MKAlert>
        </Grid>
      )}
      <Swiper
        modules={[Autoplay, Navigation]}
        onInit={({ params, navigation }) => {
          const { navigation: nav } = params;

          nav.prevEl = navigationPrevRef.current;
          nav.nextEl = navigationNextRef.current;
          navigation.init();
          navigation.update();
        }}
        autoplay={{ delay: 5000 }}
        speed={800}
        spaceBetween={0}
        slidesPerView={1}
        loop
      >
        {professionals &&
          professionals.map((professional) => (
            <SwiperSlide
              key={professional.id}
              onClick={() =>
                history.push(`/professionaldetails/${professional.id}`)
              }
            >
              <Container>
                <EmployCardsCarrossel
                  image={`https://storage.googleapis.com/mpo_images/professionals/photos/${professional.id}.jpeg`}
                  title={professional.name}
                  review={`${professional.curriculum.substring(0, 200)} ...`}
                  author={{
                    logo: "",
                    name: "Psiquiatria",
                    role: "",
                  }}
                />
              </Container>
            </SwiperSlide>
          ))}
        <MKTypography
          variant="h2"
          color="dark"
          sx={{
            ...navigationStyles,
            left: 0,
          }}
          ref={navigationPrevRef}
        >
          <Icon>chevron_left</Icon>
        </MKTypography>
        <MKTypography
          variant="h2"
          color="dark"
          sx={{
            ...navigationStyles,
            right: 0,
          }}
          ref={navigationNextRef}
        >
          <Icon>chevron_right</Icon>
        </MKTypography>
      </Swiper>
    </MKBox>
  );
}

export default CarouselPsychiatrist;
