import React, { useEffect, useState, useRef, useMemo } from "react";
import routes from "routes";

import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";

import { DataGrid, ptBR } from "@mui/x-data-grid";
import "@mui/x-data-grid/DataGrid/useDataGridProps";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";

// images
import LogoMPO from "assets/images/logo_horizontal.png";
import MKAlert from "components/MKAlert";

import { api } from "../../../lib/axios";

function AdminMPOPlanUsers() {
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const [userModal, setUserModal] = useState(false);
  const [newUserModal, setNewUserModal] = useState(false);

  const [cpf, setCpf] = useState("");
  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState({});
  const [totalUsers, setTotalUsers] = useState(0);
  const [showDisabled, setShowDisabled] = useState(true);

  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 50,
  });

  const rowCountRef = useRef(totalUsers);
  const rowCount = useMemo(() => {
    if (totalUsers !== undefined) {
      rowCountRef.current = totalUsers;
    }
    return rowCountRef.current;
  }, [totalUsers]);

  const normalizeCPF = (cpfInput) => {
    return cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const normalizeCellPhone = (cellPhoneInput) => {
    const withoutSpecialCaracters = cellPhoneInput.replace(/\D/g, "").toString();
    const onlyNumbers = withoutSpecialCaracters.slice(-11);
    return onlyNumbers
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  const clearUser = () => {
    setCpf("");
    setUserSelected({});
  };

  const handleCPF = (cpfInput) => {
    if (!cpfInput || cpfInput?.length === 0) {
      return;
    }
    const cpfFormated = cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCpf(cpfFormated);
  };

  const openUserModal = async (userInfo) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/users/${userInfo.user_id}`);
      setUserSelected({
        ...userInfo,
        user: data,
      });
      setUserModal(true);
    } catch (error) {
      setAlertComponent({
        message:
          error?.response?.data?.message || "Erro ao buscar informações do usuário",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const closeUserModal = () => {
    setUserModal(false);
    clearUser();
  }

  const openNewUserModal = () => {
    clearUser();
    setNewUserModal(true);
  };

  const closeNewUserModal = () => {
    setNewUserModal(false);
    clearUser();
  };

  const getUsersMPOPlan = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("mpo-plan", {
        params: {
          page: paginationModel?.page || 1,
          size: paginationModel?.pageSize || 50,
          showDisabled,
        },
      });
      setUsers(data.life);
      setTotalUsers(data.total);
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message ||
          "Erro na busca de usuários do plano MPO",
        type: "error",
      });
    }
    setLoading(false);
  };

  const createNewUserMPOPlan = async () => {
    setLoading(true);
    try {
      await api.post("mpo-plan", {
        document: cpf,
      });
      setAlertComponent({
        message: "Usuário adicionado com sucesso!",
        type: "success",
      });
      closeUserModal();
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message || "Erro ao adicionar usuário do plano MPO",
        type: "error",
      });
    } finally {
      await getUsersMPOPlan();
      setLoading(false);
    }
  }

  const handleActiveUserMPOPlan = async (userInfo) => {
    setLoading(true);
    try {
      await api.put(`mpo-plan/${userInfo.id}`, {
        active: !userInfo.active,
      });
      setAlertComponent({
        message: `Usuário ${userInfo.active ? "desativado" : "ativado"} com sucesso!`,
        type: "success",
      });
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message || "Erro ao atualizar usuário do plano MPO",
        type: "error",
      });
    } finally {
      await getUsersMPOPlan();
      setLoading(false);
    }
  }

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => <Typography>{row?.user?.name ?? "Não cadastrado ainda"}</Typography>,
    },
    {
      field: "document",
      headerName: "CPF",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => <Typography>{normalizeCPF(row.document)}</Typography>,
    },
    {
      field: "active",
      headerName: "Status",
      flex: 0.3,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => <Typography>{row.active ? "Ativo" : "Inativo"}</Typography>,
    },
    {
      field: " - ",
      headerName: "",
      flex: 0.2,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          <Tooltip title="Visualizar Usuário">
            <IconButton size="large" onClick={() => openUserModal(row)}>
              <PreviewIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={row.active ? "Clique para Desativar" : "Clique para Ativar"}>
            <Switch checked={row.active} color={row.active ? "primary" : "error"} onChange={() => handleActiveUserMPOPlan(row)} />
          </Tooltip>
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    if (!authenticatedUser || authenticatedUser.type !== "admin") {
      window.location.replace("/login");
    }
    getUsersMPOPlan();
  }, []);

  useEffect(() => {
    async function getInfos() {
      await getUsersMPOPlan();
    }
    getInfos();
  }, [paginationModel]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 4000);
    }
  }, [alertComponent]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        component="section"
        position="relative"
        py={20}
        width="100%"
        height="100vh"
      >
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Lista de Usuários do Plano MPO
        </Typography>
        <Grid
          container
          alignItems="top"
          sx={{ display: "flex", alignContent: "stretch" }}
        >
          <Grid item xs={12} lg={12} py={1}>
            <MKBox
              sx={{
                height: "70vh",
                width: "100%",
                paddingX: "10%",
                margin: "10px",
                textAlign: "center",
                "& .dataGrid-header": {
                  backgroundColor: "#00bbd4",
                  color: "#fff",
                  fontWeight: "bold",
                },
              }}
            >
              {alertComponent.message && (
                <Grid item xs={12}>
                  <MKAlert color={alertComponent.type}>
                    {alertComponent.message}
                  </MKAlert>
                </Grid>
              )}
              <DataGrid
                rows={users}
                columns={columns}
                rowCount={rowCount}
                loading={loading}
                pageSizeOptions={[10, 25, 50, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                localeText={
                  ptBR.components.MuiDataGrid.defaultProps.localeText
                }
              />
            </MKBox>
            <Fab
              sx={{ position: "absolute", bottom: 50, right: 30 }}
              color="success"
              aria-label="add"
              onClick={() => openNewUserModal()}
            >
              <AddIcon color="white" />
            </Fab>
          </Grid>
        </Grid>
      </MKBox>

      {/* Modal de Criação */}
      <Dialog
        open={newUserModal}
        onClose={closeNewUserModal}
        fullScreen={false}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Adicionar Usuário ao Plano MPO</h3>
          </div>
          <div>
            <IconButton onClick={closeNewUserModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {alertComponent.message && (
              <Grid item xs={12}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <MKInput
                variant="outlined"
                label="CPF"
                name="document"
                value={cpf}
                onChange={(e) => handleCPF(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <MKBox width="100%" py={1}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={createNewUserMPOPlan}
                  fullWidth
                >
                  Adicionar Usuário
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Modal de Visualização */}
      <Dialog
        open={userModal}
        onClose={closeUserModal}
        fullScreen={false}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Detalhes do Usuário</h3>
          </div>
          <div>
            <IconButton onClick={closeUserModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {Object.keys(userSelected).length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
              <Box sx={{ padding: "10px", borderRadius: "5px", marginBottom: "10px", backgroundColor: userSelected.active ? "green" : "red" }}>
                <Typography variant="h6" sx={{ color: "#ffffff", textAlign: "center" }}>{userSelected.active ? "ATIVO" : "INATIVO"}</Typography>
              </Box>
              <Typography variant="h6">Nome: {userSelected.user.name}</Typography>
              <Typography variant="h6">CPF: {normalizeCPF(userSelected.user.cpf)}</Typography>
              <Typography variant="h6">E-mail: {userSelected.user.email}</Typography>
              <Typography variant="h6">Telefone: {normalizeCellPhone(userSelected.user.cellphone)}</Typography>
              <Typography variant="h6">Cidade: {userSelected.user?.city ? `${userSelected.user.city}-${userSelected.user.state}` : "Não informado"}</Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AdminMPOPlanUsers;
