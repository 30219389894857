/* eslint-disable no-plusplus */
/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import routes from "routes";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import { DataGrid, ptBR } from "@mui/x-data-grid";

import LogoMPO from "assets/images/logo_horizontal.png";

import {
  Card,
  CircularProgress,
  Grid,
  Backdrop,
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKAlert from "components/MKAlert";

import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import DownloadIcon from "@mui/icons-material/Download";

import { api } from "../../lib/axios";

function Purchases() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const history = useHistory();
  const { productId } = useParams();

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "ïnfo",
  });
  const [loading, setLoading] = useState(false);

  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [purchase, setPurchase] = useState({});

  const getProduct = async (id) => {
    try {
      const response = await api.get(`/sales_products/${id}`);
      return response.data;
    } catch (error) {
      setAlertComponent({
        message: "Erro na busca do produto",
        type: "error",
      });
    }
    return {};
  };

  const getPurchases = async () => {
    setLoading(true);
    try {
      const response = await api.get("/sales_products", {
        params: {
          user_id: authenticatedUser.id,
        },
      });
      setPurchases(response.data);
    } catch (error) {
      setAlertComponent({
        message: "Erro na busca das compras",
        type: "error",
      });
    }
    setLoading(false);
  };

  const openDetailsModal = async (prod) => {
    if (prod?.id) {
      setPurchase(prod);
    } else {
      const productSelect = await getProduct(prod);
      setPurchase(productSelect);
    }
    setOpenPurchaseModal(true);
  };

  const closeDetailsModal = () => {
    setPurchase({});
    setOpenPurchaseModal(false);
  };

  const downloadFile = (productSelected) => {
    const partsFile = productSelected.product.path.split(".");
    const extension = partsFile[partsFile.length - 1];
    try {
      const response = api.get(
        `/sales_products/download/${productSelected.id}`,
        {
          responseType: "blob",
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${productSelected.product.name}.${extension}`,
      );
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      setAlertComponent({
        message: "Erro ao baixar o arquivo",
        type: "error",
      });
    }
  };

  const extractDateTime = (params) => {
    const registerDateMaskared = dayjs(params.row.date)
      .tz("America/Sao_Paulo")
      .add(3, "hours")
      .format("DD/MM/YYYY HH:mm");

    return <span>{registerDateMaskared}</span>;
  };

  const extractProductName = (params) => {
    const professionalName = params.row?.product?.name || "Não Informado";
    return <span>{professionalName}</span>;
  };

  const extractPaymentValue = (params) => (
    <span>
      {parseFloat(params.row.value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}
    </span>
  );

  const extractPaymentMethod = (params) => {
    const method = params.row?.payment?.method || "Não Informado";
    return <span>{method}</span>;
  };

  const extractPaymentStatus = (params) => {
    const statusPay =
      params.row?.payment?.status === "PAID" ? "PAGO" : "Não Informado";
    return <span>{statusPay}</span>;
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Data/Hora",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      exportable: true,
      filterable: false,
      renderCell: extractDateTime,
    },
    {
      field: "productName",
      headerName: "Produto",
      flex: 1,
      headerClassName: "dataGrid-header",
      exportable: true,
      renderCell: extractProductName,
    },
    {
      field: "price",
      headerName: "Valor",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      exportable: true,
      renderCell: extractPaymentValue,
    },
    {
      field: "paymentMethod",
      headerName: "Método de Pagamento",
      flex: 1,
      headerClassName: "dataGrid-header",
      exportable: true,
      renderCell: extractPaymentMethod,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      exportable: true,
      renderCell: extractPaymentStatus,
    },
    {
      field: "actions",
      headerName: "",
      disableColumnMenu: true,
      disableColumnFilter: true,
      flex: 0.2,
      headerClassName: "dataGrid-header",
      exportable: false,
      filtrable: false,
      sortable: false,
      renderCell: ({ row }) => (
        <MKBox>
          <Tooltip title="Detalhes da compra" placement="top" arrow>
            <IconButton size="large" onClick={() => openDetailsModal(row)}>
              <PreviewIcon />
            </IconButton>
          </Tooltip>
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    if (!authenticatedUser) {
      alert("Usuário não autenticado... faça login");
      window.location.replace("/login");
    }
    async function getInfos() {
      if (purchases.length === 0) {
        await getPurchases();
      }
      if (productId) {
        await openDetailsModal(productId);
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "info",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        component="section"
        position="relative"
        py={{ xs: 18, lg: 20 }}
        paddingX="10%"
        width="100%"
        height="100vh"
      >
        <Card>
          <MKBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={1}
            mb={1}
            textAlign="center"
            xs={12}
            lg={6}
          >
            <MKTypography
              variant="body1"
              fontWeight="medium"
              color="white"
              mt={1}
            >
              Suas Compras na Loja Virtual
            </MKTypography>
          </MKBox>
          <MKBox
            sx={{
              height: "72vh",
              width: "100%",
              paddingX: 2,
              textAlign: "center",
              "& .dataGrid-header": {
                backgroundColor: "#edeeee",
                color: "#000000",
                fontWeight: "bold",
              },
            }}
          >
            {alertComponent.message !== "" && (
              <Grid item xs={12}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </Grid>
            )}
            {purchases && purchases.length > 0 && (
              <DataGrid
                rows={purchases}
                columns={columns}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ height: "70vh" }}
              />
            )}
          </MKBox>
        </Card>
      </MKBox>

      {/* Modal para exibição da compra */}
      <Dialog
        open={openPurchaseModal}
        onClose={closeDetailsModal}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography variant="body1">Dados da Venda</Typography>
          <CloseIcon onClick={closeDetailsModal} sx={{ cursor: "pointer" }} />
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "40px" }}>
          {Object.keys(purchase).length > 0 && (
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <img
                  src={`https://storage.googleapis.com/mpo_images/products/photos/${purchase.product.id}.jpeg`}
                  alt={purchase.product.name}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "50vh",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  fontSize: "1.2rem",
                  padding: 1,
                }}
              >
                <Box>
                  <Box>
                    <b>Produto: </b>
                    {purchase.product.name}
                  </Box>
                  <Box>
                    <b>Tipo: </b>
                    {purchase.product.products_group.name}
                  </Box>
                  <Box>
                    <b>Data/Hora da compra: </b>
                    {dayjs(purchase.data)
                      .tz("America/Sao_Paulo")
                      .add(3, "hours")
                      .format("DD/MM/YYYY HH:mm")}
                  </Box>
                  <Box>
                    <b>Preço: </b>
                    {parseFloat(purchase.value).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Box>
                  <Box>
                    <b>Método de pagamento: </b>
                    {purchase.payment.method !== "OUTRO"
                      ? purchase.payment.method
                      : "AGUARDANDO PAGAMENTO"}
                  </Box>
                  <Box>
                    <b>Status: </b>
                    {purchase.payment.status === "PAID"
                      ? "PAGO"
                      : purchase.payment.status}
                  </Box>
                </Box>
                {purchase.payment.status === "PAID" &&
                  purchase.product?.path && (
                    <Box>
                      <Button
                        onClick={() => downloadFile(purchase)}
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor: "#028b24",
                          color: "#ffffff",
                          "&:hover": {
                            backgroundColor: "#004211",
                            color: "#ffffff",
                          },
                        }}
                      >
                        Fazer Download
                      </Button>
                    </Box>
                  )}
                {purchase.payment.status === "PENDENTE" && (
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => history.push(`/payment/${purchase.id}`)}
                      fullWidth
                      sx={{
                        backgroundColor: "#fda430",
                        color: "#000000",
                        "&:hover": {
                          backgroundColor: "#028b24",
                          color: "#ffffff",
                        },
                      }}
                    >
                      PAGAR AGORA
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Purchases;
