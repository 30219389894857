import React, { useEffect, useState } from "react";
import routes from "routes";

import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";

import { DataGrid, ptBR } from "@mui/x-data-grid";
import "@mui/x-data-grid/DataGrid/useDataGridProps";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import LogoMPO from "assets/images/logo_horizontal.png";
import MKAlert from "components/MKAlert";

import axios from "axios";
import { api } from "../../../lib/axios";

const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

function SplitAccounts() {
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "info",
  });
  const [alertComponentModal, setAlertComponentModal] = useState({
    message: "",
    type: "info",
  });
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [splitAccounts, setSplitAccounts] = useState([]);

  const [userSelected, setUserSelected] = useState({});

  const [userCpf, setUserCpf] = useState("");
  const [userName, setUserName] = useState("");

  const [splitDocument, setSplitDocument] = useState("");
  const [splitType, setSplitType] = useState("individual");
  const [splitName, setSplitName] = useState("");
  const [splitEmail, setSplitEmail] = useState("");

  const [splitBankCode, setSplitBankCode] = useState("");
  const [splitBankName, setSplitBankName] = useState("");
  const [splitAccountType, setSplitAccountType] = useState("checking");
  const [splitBankAgencyNumber, setSplitBankAgencyNumber] = useState("");
  const [splitBankAgencyVerify, setSplitBankAgencyVerify] = useState("");
  const [splitBankAccountNumber, setSplitBankAccountNumber] = useState("");
  const [splitBankAccountVerify, setSplitBankAccountVerify] = useState("");

  const [onlyRead, setOnlyRead] = useState(true);

  const clearSplitAccount = () => {
    setUserSelected({});
    setUserCpf("");
    setUserName("");
    setSplitDocument("");
    setSplitType("individual");
    setSplitName("");
    setSplitEmail("");
    setSplitBankCode("");
    setSplitBankName("");
    setSplitAccountType("checking");
    setSplitBankAgencyNumber("");
    setSplitBankAgencyVerify("");
    setSplitBankAccountNumber("");
    setSplitBankAccountVerify("");
  };

  const setSplitAccountInfos = (splitInfos) => {
    setUserSelected(splitInfos.user);
    setUserCpf(splitInfos.user.cpf);
    setSplitName(splitInfos.user.name);
    setSplitEmail(splitInfos.user.email);
    setSplitDocument(splitInfos.document);
    setSplitType(splitInfos.type);
    setSplitName(splitInfos.name);
    setSplitEmail(splitInfos?.email || "");
    setSplitBankCode(splitInfos.bankAccountBank);
    setSplitBankName(splitInfos.bankAccountHolderName);
    setSplitAccountType(splitInfos.bankAccountType);
    setSplitBankAgencyNumber(splitInfos.bankAccountBranchNumber);
    setSplitBankAgencyVerify(splitInfos.bankAccountBranchCheckDigit);
    setSplitBankAccountNumber(splitInfos.bankAccountNumber);
    setSplitBankAccountVerify(splitInfos.bankAccountCheckDigit);
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const cpfTratado = userCpf.replace(/\D/g, "");
      if (cpfTratado.length !== 11) {
        setAlertComponentModal({
          message: "CPF inválido!",
          type: "error",
        });
        setLoading(false);
        return;
      }
      const response = await api.get("/users", {
        params: {
          cpf: cpfTratado,
        },
      });
      const userFound = response.data[0];
      setUserSelected(userFound);
      setUserCpf(userFound.cpf);
      setUserName(userFound.name);
      setSplitName(userFound.name);
      setSplitEmail(userFound.email);
      setSplitDocument(userFound.cpf);
      setSplitType("individual");
    } catch (error) {
      setAlertComponentModal({
        message: "Nenhum usuário com esse CPF!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getSplitAccounts = async () => {
    setLoading(true);
    try {
      const response = await api.get("/split_accounts");
      setSplitAccounts(response.data);
    } catch (error) {
      setAlertComponent({
        message: "Erro na extração das contas para split de pagamento.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleBankNameByCode = async (bankCode) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `https://brasilapi.com.br/api/banks/v1/${bankCode}`
      );
      setSplitBankName(data.fullName);
    } catch (error) {
      setAlertComponentModal({
        message: "Banco não encontrado na base de dados nacional!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleSplitDocument = (documentInput) => {
    const inputedDocument = documentInput.replace(/\D/g, "");
    let newDocument = "";
    if (inputedDocument.length > 11) {
      newDocument = inputedDocument
        .replace(/^(\d{2})(\d)/, "$1.$2")
        .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        .replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, "$1.$2.$3/$4")
        .replace(/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, "$1.$2.$3/$4-$5")
        .replace(/(-\d{2})\d+?$/, "$1");
    } else {
      newDocument = inputedDocument
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
    }
    setSplitDocument(newDocument);
  };

  const splitDocumentValidation = async () => {
    const document = splitDocument.replace(/\D/g, "");
    setLoading(true);
    if (document.length === 14) {
      try {
        const businessInfos = await axios.get(
          `https://brasilapi.com.br/api/cnpj/v1/${document}`
        );
        setSplitName(businessInfos.data.razao_social);
        setSplitType("company");
      } catch (error) {
        setAlertComponent({
          message: "CNPJ não encontrado na base de registro nacional.",
          type: "error",
        });
        setLoading(false);
        return;
      }
    }
    if (document.length === 11) {
      if (document.replace(/\D/g, "") !== userCpf.replace(/\D/g, "")) {
        setAlertComponent({
          message: "CPF não corresponde ao usuário selecionado",
          type: "error",
        });
        return;
      }
      setSplitName(userSelected.name);
      setSplitDocument(userSelected.cpf);
      setSplitEmail(userSelected.email);
      setSplitType("individual");
    }
    setLoading(false);
  };

  const handleOpenModal = (row = null) => {
    clearSplitAccount();
    if (row) {
      setSplitAccountInfos(row);
    } else {
      setOnlyRead(false);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    clearSplitAccount();
    setOnlyRead(true);
    setOpenModal(false);
  };

  const createSplit = async () => {
    const docSelected = splitDocument.replace(/\D/g, "");
    if (
      docSelected.length === 11 &&
      userSelected.cpf !== "" &&
      userSelected.cpf.replace(/\D/g, "") !== docSelected
    ) {
      setAlertComponentModal({
        message: "CPF divergente ou ausente no cadastro do usuário.",
        type: "error",
      });
      return;
    }
    setLoading(true);
    const dataInfo = {
      userId: userSelected.id,
      name: splitName,
      description: splitName,
      email: splitEmail,
      document: docSelected,
      type: splitType,
      code: userSelected.id,
      bankAccountHolderName: splitBankName,
      bankAccountBank: splitBankCode,
      bankAccountBranchNumber: splitBankAgencyNumber,
      bankAccountBranchCheckDigit: splitBankAgencyVerify,
      bankAccountNumber: splitBankAccountNumber,
      bankAccountCheckDigit: splitBankAccountVerify,
      bankAccountHolderType: splitType,
      bankAccountHolderDocument: docSelected,
      bankAccountType: splitAccountType,
      status: true,
    };
    try {
      await api.post("/split_accounts", dataInfo);
      setAlertComponent({
        message: "Split criado com sucesso",
        type: "success",
      });
      await getSplitAccounts();
      handleCloseModal();
    } catch (error) {
      setAlertComponentModal({
        message:
          error?.response?.data?.message ||
          "Erro na criação do split de pagamento.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleAccountStatus = async (account) => {
    setLoading(true);
    try {
      await api.put(`/split_accounts/${account.id}`, {
        status: !account.status,
      });
      await getSplitAccounts();
      handleCloseModal();
      setAlertComponent({
        message: "Status da conta split alterada com sucesso",
        type: "success",
      });
    } catch (error) {
      setAlertComponent({
        message: "Erro na execução da alteração da conta",
        type: "error",
      });
    }
    setLoading(false);
  };

  const extractUserName = (params) => <span>{params.row.user.name}</span>;

  const extractStatus = (params) => {
    if (params.row.status) {
      return <span>Sim</span>;
    }
    return <span>Não</span>;
  };

  const extractAccountName = (params) => (
    <span>
      {params.row.bankAccountHolderName} - {params.row.bankAccountBranchNumber}-
      {params.row.bankAccountBranchCheckDigit}/{params.row.bankAccountNumber}-
      {params.row.bankAccountCheckDigit}
    </span>
  );

  const columns = [
    {
      field: "userName",
      headerName: "Usuário",
      flex: 2,
      headerClassName: "dataGrid-header",
      renderCell: extractUserName,
    },
    {
      field: "accountName",
      headerName: "Conta",
      flex: 2,
      headerClassName: "dataGrid-header",
      renderCell: extractAccountName,
    },
    {
      field: "status",
      headerName: "Ativo",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      renderCell: extractStatus,
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          <IconButton size="large" onClick={() => handleOpenModal(row)}>
            <Tooltip title="Visualizar Conta Split" placement="top" arrow>
              <PreviewIcon />
            </Tooltip>
          </IconButton>
          <IconButton size="large" onClick={() => handleAccountStatus(row)}>
            {row.status ? (
              <Tooltip title="Desativar Conta Split" placement="top" arrow>
                <CancelIcon color="error" />
              </Tooltip>
            ) : (
              <Tooltip title="Ativar Conta Split" placement="top" arrow>
                <CheckCircleIcon color="success" />
              </Tooltip>
            )}
          </IconButton>
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      if (!splitAccounts || Object.keys(splitAccounts).length === 0) {
        await getSplitAccounts();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 4000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (alertComponentModal.message !== "") {
      setTimeout(() => {
        setAlertComponentModal({
          message: "",
          type: "",
        });
      }, 4000);
    }
  }, [alertComponentModal]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        component="section"
        position="relative"
        py={20}
        width="100%"
        height="100vh"
      >
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Contas para split de pagamentos
        </Typography>
        <Grid
          container
          alignItems="top"
          sx={{ display: "flex", alignContent: "stretch" }}
        >
          {alertComponent.message !== "" && (
            <Grid item xs={12} sx={{ paddingX: "10%" }}>
              <MKAlert color={alertComponent.type}>
                {alertComponent.message}
              </MKAlert>
            </Grid>
          )}
          <Grid item xs={12} lg={12} py={1}>
            <MKBox
              sx={{
                height: "75vh",
                width: "100%",
                paddingX: "10%",
                textAlign: "center",
                "& .dataGrid-header": {
                  backgroundColor: "#00bbd4",
                  color: "#fff",
                  fontWeight: "bold",
                },
              }}
            >
              <DataGrid
                rows={splitAccounts}
                columns={columns}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              />
            </MKBox>
            <Fab
              sx={{ position: "absolute", bottom: 50, right: 30 }}
              color="success"
              aria-label="add"
              onClick={() => handleOpenModal()}
            >
              <AddIcon color="white" />
            </Fab>
          </Grid>
        </Grid>
      </MKBox>

      {/* Modal de Criação / Edição */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullScreen={false}
        scroll="body"
        maxWidth="xxl"
        fullWidth
      >
        <DialogTitle style={styles.dialogTitle}>
          <div>
            <h3>Conta para Split de Pagamentos</h3>
          </div>
          <div>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {alertComponentModal.message && (
              <Grid item xs={12}>
                <MKAlert color={alertComponentModal.type}>
                  {alertComponentModal.message}
                </MKAlert>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              Dados de Cadastro
            </Grid>
            <Grid item xs={12} md={4}>
              <MKInput
                variant="outlined"
                name="userCpf"
                label="CPF"
                value={userCpf}
                onChange={(e) => setUserCpf(e.target.value)}
                onBlur={getUser}
                fullWidth
                required
                disabled={onlyRead}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <MKInput
                variant="outlined"
                name="userName"
                label="Nome do Usuário"
                value={userName}
                fullWidth
                disabled={onlyRead}
              />
            </Grid>
            {userCpf && (
              <>
                <Grid item xs={12} md={4}>
                  <Select
                    variant="outlined"
                    name="splitType"
                    sx={{ height: "45px" }}
                    value={splitType}
                    onChange={(e) => setSplitType(e.target.value)}
                    defaultValue="individual"
                    fullWidth
                    required
                    disabled={onlyRead}
                  >
                    <MenuItem
                      value="individual"
                      selected={splitType === "individual"}
                    >
                      Pessoa Física
                    </MenuItem>
                    <MenuItem
                      value="company"
                      selected={splitType === "company"}
                    >
                      Pessoa Jurídica
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={8}>
                  <MKInput
                    variant="outlined"
                    name="splitEmail"
                    label="E-mail de Notificação"
                    value={splitEmail}
                    onChange={(e) => setSplitEmail(e.target.value)}
                    type="email"
                    fullWidth
                    required
                    disabled={onlyRead}
                  />
                </Grid>
              </>
            )}
            {splitType === "company" && (
              <>
                <Grid item xs={12} md={6}>
                  <MKInput
                    variant="outlined"
                    name="splitDocument"
                    label="CNPJ"
                    value={splitDocument}
                    onChange={(e) => handleSplitDocument(e.target.value)}
                    onBlur={splitDocumentValidation}
                    fullWidth
                    required
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    variant="outlined"
                    name="splitName"
                    label="Nome da Empresa"
                    value={splitName}
                    fullWidth
                    disabled={onlyRead}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={12}>
              Dados Bancários
            </Grid>
            <Grid item xs={12} md={3}>
              <MKInput
                variant="outlined"
                name="splitBankCode"
                label="Código Banco"
                value={splitBankCode}
                onChange={(e) => setSplitBankCode(e.target.value)}
                onBlur={(e) => handleBankNameByCode(e.target.value)}
                fullWidth
                required
                disabled={onlyRead}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MKInput
                variant="outlined"
                name="splitBankName"
                label="Banco"
                value={splitBankName}
                onChange={(e) => setSplitBankName(e.target.value)}
                fullWidth
                required
                disabled={onlyRead}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Select
                variant="outlined"
                name="splitAccountType"
                sx={{ height: "45px" }}
                value={splitAccountType}
                onChange={(e) => setSplitAccountType(e.target.value)}
                fullWidth
                required
                disabled={onlyRead}
              >
                <MenuItem value="checking">Conta Corrente</MenuItem>
                <MenuItem value="savings ">Poupança</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <MKInput
                variant="outlined"
                name="splitBankAgencyNumber"
                label="Código Agência"
                value={splitBankAgencyNumber}
                onChange={(e) => setSplitBankAgencyNumber(e.target.value)}
                fullWidth
                required
                disabled={onlyRead}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <MKInput
                variant="outlined"
                name="splitBankAgencyVerify"
                label="Verificador Agência"
                value={splitBankAgencyVerify}
                onChange={(e) => setSplitBankAgencyVerify(e.target.value)}
                fullWidth
                defaultValue="0"
                disabled={onlyRead}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MKInput
                variant="outlined"
                name="splitBankAccountNumber"
                label="Número da Conta"
                value={splitBankAccountNumber}
                onChange={(e) => setSplitBankAccountNumber(e.target.value)}
                fullWidth
                required
                disabled={onlyRead}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <MKInput
                variant="outlined"
                name="splitBankAccountVerify"
                label="Verificador Conta"
                value={splitBankAccountVerify}
                onChange={(e) => setSplitBankAccountVerify(e.target.value)}
                fullWidth
                required
                disabled={onlyRead}
              />
            </Grid>
            {!onlyRead && (
              <Grid item xs={12} md={12}>
                <MKBox width="100%">
                  <MKButton
                    variant="gradient"
                    color="info"
                    onClick={createSplit}
                    fullWidth
                  >
                    Gravar Dados
                  </MKButton>
                </MKBox>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SplitAccounts;
