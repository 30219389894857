/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import { Container, Grid, Icon, Rating } from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { api } from "../../../lib/axios";

function ProfessionalTestimonials({ professionalId, serviceId }) {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const initialDateEvaluation = new Date();
  initialDateEvaluation.setDate(
    initialDateEvaluation.getDate() - process.env.REACT_APP_EVALUATION_INTERVAL,
  );
  const [testimonials, setTestimonials] = useState(null);
  const getTestimonials = async () => {
    const dataParams = {
      professional_id: professionalId,
      services: [serviceId],
      interval: process.env.REACT_APP_EVALUATION_INTERVAL,
    };
    const response = await api.get("/schedules/testimonials", {
      params: dataParams,
    });
    setTestimonials(response.data);
  };

  const navigationStyles = {
    position: "absolute",
    top: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
    height: "100%",
    textAlign: "center",
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  useEffect(() => {
    async function getInfos() {
      if (!testimonials) {
        await getTestimonials();
      }
    }
    getInfos();
  }, []);

  return (
    <Grid container sx={{ width: "100%", textAlign: "center", py: 2 }}>
      <Grid item sx={{ width: "100%", textAlign: "center" }}>
        <MKTypography
          variant="body2"
          textTransform="uppercase"
          textAlign="center"
        >
          Avaliações desse profissional
        </MKTypography>
      </Grid>
      {!testimonials || testimonials.length === 0 ? (
        <Grid textAlign="center" width="100%">
          <MKTypography variant="body2">
            Sem avaliações para mostrar no momento.
          </MKTypography>
        </Grid>
      ) : (
        <Swiper
          modules={[Autoplay, Navigation]}
          onInit={({ params, navigation }) => {
            const { navigation: nav } = params;

            nav.prevEl = navigationPrevRef.current;
            nav.nextEl = navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          autoplay={{ delay: 5000 }}
          speed={800}
          spaceBetween={0}
          slidesPerView={1}
          loop
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <Container>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="xl"
                  shadow="md"
                  py={2}
                  px={5}
                >
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      alignSelf="center"
                      textAlign="center"
                    >
                      <MKBox>
                        <MKTypography variant="button" color="white">
                          <Icon>schedule</Icon>&nbsp;
                          {dayjs(testimonial.date_start)
                            .tz("America/Sao_Paulo")
                            .add(3, "hours")
                            .format("DD/MM/YYYY")}
                        </MKTypography>
                      </MKBox>
                      <MKTypography variant="body2" color="white">
                        &quot;{testimonial.text_evaluation}&quot;
                      </MKTypography>
                      <MKBox>
                        <Rating
                          name="read-only"
                          value={testimonial.rated}
                          readOnly
                        />
                      </MKBox>
                    </Grid>
                  </Grid>
                </MKBox>
              </Container>
            </SwiperSlide>
          ))}
          <MKTypography
            variant="h2"
            color="dark"
            sx={{
              ...navigationStyles,
              left: 0,
            }}
            ref={navigationPrevRef}
          >
            <Icon>chevron_left</Icon>
          </MKTypography>
          <MKTypography
            variant="h2"
            color="dark"
            sx={{
              ...navigationStyles,
              right: 0,
            }}
            ref={navigationNextRef}
          >
            <Icon>chevron_right</Icon>
          </MKTypography>
        </Swiper>
      )}
    </Grid>
  );
}

export default ProfessionalTestimonials;
