/* eslint-disable no-shadow */
/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import routes from "routes";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { DataGrid, ptBR } from "@mui/x-data-grid";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKAlert from "components/MKAlert";

import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";

// images
import LogoMPO from "assets/images/logo_horizontal.png";

import { api } from "../../../lib/axios";

function AdminQuestions() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.locale("pt-br");

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "",
  });
  const [alertComponentModal, setAlertComponentModal] = useState({
    message: "",
    type: "",
  });

  const [loading, setLoading] = useState(false);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [question, setQuestion] = useState({});
  const [questions, setQuestions] = useState({});
  const [services, setServices] = useState({});

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const handleOpenQuestionModal = (row) => {
    setQuestion(row);
    setOpenQuestionModal(true);
  };

  const getQuestions = async () => {
    setLoading(true);
    try {
      const responseQuestions = await api.get("/questions");
      const questionsOrdered = responseQuestions.data;
      questionsOrdered.sort((a, b) =>
        dayjs(b.created_at).diff(dayjs(a.created_at))
      );

      setQuestions(questionsOrdered);
    } catch (err) {
      setAlertComponent({
        message:
          err.response?.data?.message ?? "Erro na extração das perguntas",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getServices = async () => {
    setLoading(true);
    try {
      const responseServices = await api.get("services");
      setServices(responseServices.data);
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message || "Erro na extração dos serviços",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleActivatePublication = async (row) => {
    setLoading(true);
    try {
      let urlRequest = "/questions/disapprove";
      if (row.status === false) {
        urlRequest = "/questions/approve";
      }
      await api.put(`${urlRequest}/${row.id}`);
      setAlertComponentModal({
        message: "Pergunta alterada com sucesso",
        type: "success",
      });
      setTimeout(async () => {
        await getQuestions();
        setLoading(false);
        setOpenQuestionModal(false);
      }, 3000);
    } catch (err) {
      setAlertComponentModal({
        message:
          err.response?.data?.message ?? "Erro na troca do status da pergunta",
        type: "error",
      });
    }
    setLoading(false);
  };

  const removeAnswer = async (id) => {
    setLoading(true);
    try {
      await api.delete(`questions/answer/${id}`);
      setAlertComponentModal({
        message: "Resposta da pergunta removida com sucesso",
        type: "success",
      });
      setTimeout(async () => {
        await getQuestions();
        setLoading(false);
        setOpenQuestionModal(false);
      }, 3000);
    } catch (err) {
      setAlertComponentModal({
        message:
          err.response?.data?.message ??
          "Erro ao remover resposta do profissional",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getDateTimeQuestion = (params) =>
    dayjs
      .tz(params.row.created_at, "America/Sao_Paulo")
      .subtract(3, "hour")
      .format("DD/MM/YYYY HH:mm");

  const getUserName = (params) => {
    const userName = params.row.user.name || "";
    return <span>{userName}</span>;
  };

  const getProfessionalName = (params) => {
    const professionalName = params.row?.professional?.name || "";
    return <span>{professionalName}</span>;
  };

  const getQUestionStatus = (params) => {
    if (params.row.status) {
      return <span>SIM</span>;
    }
    return <span>NÃO</span>;
  };

  const getServiceSelected = (params) => {
    if (params.row.service_id) {
      const serviceSelected = services.find(
        (s) => s.id === params.row.service_id
      );
      return <span>{serviceSelected.name}</span>;
    }
    return null;
  };

  const columns = [
    {
      field: "created_at",
      headerName: "Criado em",
      flex: 1,
      headerClassName: "data-grid-header",
      renderCell: getDateTimeQuestion,
    },
    {
      field: "userName",
      headerName: "Usuário",
      flex: 1,
      headerClassName: "data-grid-header",
      renderCell: getUserName,
    },
    {
      field: "professional",
      headerName: "Profissional",
      flex: 1,
      headerClassName: "data-grid-header",
      renderCell: getProfessionalName,
    },
    {
      field: "service_id",
      headerName: "Serviço Alvo",
      flex: 1,
      headerClassName: "data-grid-header",
      renderCell: getServiceSelected,
    },
    {
      field: "status",
      headerName: "Publicada",
      flex: 1,
      headerClassName: "data-grid-header",
      renderCell: getQUestionStatus,
    },
    {
      field: "actions",
      headerName: "Ações",
      disableColumnMenu: true,
      disableColumnFilter: true,
      flex: 0.5,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          <IconButton size="large" onClick={() => handleOpenQuestionModal(row)}>
            <PreviewIcon />
          </IconButton>
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      if (Object.keys(services).length === 0) {
        await getServices();
      }
      if (Object.keys(questions).length === 0) {
        await getQuestions();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (alertComponentModal.message !== "") {
      setTimeout(() => {
        setAlertComponentModal({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponentModal]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>

      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />

      <Grid
        item
        xs={12}
        lg={12}
        pt={20}
        px={25}
        style={{ height: "900px", width: "100%" }}
      >
        {alertComponent.message !== "" && (
          <Grid item xs={12} p={2}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}
        {Object.keys(questions).length > 0 && (
          <DataGrid
            rows={questions}
            columns={columns}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </Grid>

      <Dialog
        open={openQuestionModal}
        onClose={() => {
          setQuestion({});
          setOpenQuestionModal(false);
        }}
        scroll="body"
        aria-labelledby="times-avaliable-title"
        aria-describedby="times-avaliable-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          id="times-avaliable-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Detalhes da Pergunta</h3>
          </div>
          <div>
            <IconButton onClick={() => setOpenQuestionModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {alertComponentModal.message !== "" && (
            <Grid item xs={12}>
              <MKAlert color={alertComponentModal.type}>
                {alertComponentModal.message}
              </MKAlert>
            </Grid>
          )}
          <Grid container p={2}>
            {Object.keys(question).length > 0 && (
              <Grid item xs={12} md={12}>
                <Typography>{`ID: ${question.id}`}</Typography>
                <Typography>{`Data/Hora: ${dayjs
                  .tz(question.updated_at, "America/Sao_Paulo")
                  .subtract(3, "hours")
                  .format("DD/MM/YYYY HH:mm:ss")}`}</Typography>
                <Typography>{`Usuário: ${question.user.name}`}</Typography>
                <Typography>{`Pergunta: ${question.question}`}</Typography>
                <Typography>{`Resposta: ${question?.answer ?? "NÃO RESPONDIDA"
                  }`}</Typography>
                <Typography>{`Profissional: ${question?.professional?.name ?? "NÃO RESPONDIDA"
                  }`}</Typography>
                <Typography>{`Serviço Alvo: ${question?.service_id
                  ? services.find((s) => s.id === question.service_id).name
                  : "NÃO RESPONDIDA"
                  }`}</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {question.answer !== null && (
            <>
              <Button onClick={() => removeAnswer(question.id)}>
                Remover Resposta
              </Button>
              <Button onClick={() => handleActivatePublication(question)}>
                {question.status ? "Desativar Publicação" : "Ativar Publicação"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AdminQuestions;
