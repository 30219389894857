import React from "react";
import ReactPlayer from "react-player";

import { Fab, Grid, Tooltip } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";

function PlayerVideo() {
  return (
    <Grid
      container
      sx={{ height: "100vh", width: "100%", padding: 0, margin: 0 }}
    >
      <ReactPlayer
        id="video"
        url="https://youtube.com/shorts/gV3Ql266BMM?si=AqlzuiEaBaha5MSf"
        controls
        width="100%"
        height="100vh"
        sx={{ margin: 0, padding: 0 }}
      />
      <Tooltip title="Voltar para página inicial" placement="left" arrow>
        <Fab
          sx={{ position: "fixed", bottom: 30, right: 30 }}
          onClick={() => window.history.back()}
        >
          <UndoIcon color="action" fontSize="large" />
        </Fab>
      </Tooltip>
    </Grid>
  );
}

export default PlayerVideo;
