import React, { useEffect, useState } from "react";
import { Prompt, useParams } from "react-router-dom";
import {
  Alert,
  Backdrop,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import EletronicMedicalRecord from "components/EletronicMedicalRecord";
import MedicalTimeLine from "components/MedicalTimeLine";

import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MeetingRoomComponent from "../../components/MeetingRoomComponent";

import phoneoff from "../../assets/phoneoff.svg";

import { api } from "../../lib/axios";

function MeetingRoom() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { id } = useParams();

  const [documentsModels, setDocumentsModels] = useState([]);

  const [meetingAuthorized, setMeetingAuthorized] = useState(false);
  const [localUsername, setLocalUsername] = useState("");
  const [remoteUserName, setRemoteUserName] = useState("");
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [timeRoomLeft, setTimeRoomLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [meetingFinished, setMeetingFinished] = useState(false);

  const [authenticatedUser, setAuthenticatedUser] = useState({});
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [timeLineInfos, setTimeLineInfos] = useState([]);
  const [notFoundModal, setNotFoundModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "",
  });
  const [scheduleFinished, setScheduleFinished] = useState(false);

  const finalizeAppointment = async () => {
    const timeLimitToFinished = dayjs(appointmentDetails.date_start)
      .add(3, "hours")
      .add(15, "minutes");
    const timeNow = dayjs().tz("America/Sao_Paulo");
    if (timeNow.isBefore(timeLimitToFinished)) {
      setAlertComponent({
        message: "Atendimento não pode ser finalizado com menos 15 minutos.",
        type: "error",
      });
      return;
    }
    try {
      await api.put(`/schedules/${appointmentDetails.id}/finalize`);
      setAlertComponent({
        message: "Atendimento finalizado com sucesso!",
        type: "success",
      });
      setScheduleFinished(true);
    } catch (error) {
      setAlertComponent({
        message:
          error.response?.data?.message || "Erro ao finalizar atendimento",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const finalizeMeeting = async () => {
    setMeetingAuthorized(false);
    setMeetingFinished(true);
    await finalizeAppointment();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
      window.close();
    }
    if (!window.closed) {
      Prompt("Você precisa fechar a janela manualmente.");
    }
  };

  const testCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (error) {
      return false;
    }
  };

  const testMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (error) {
      return false;
    }
  };

  const acceptTermsModal = async () => {
    const statusCamera = await testCameraPermission();
    const statusMic = await testMicrophonePermission();
    if (statusCamera && statusMic) {
      setAcceptModal(false);
    }
  };

  const getTimeLine = async (patientId) => {
    try {
      const { data } = await api.get(`/timeline/${patientId}`);
      setTimeLineInfos(data);
    } catch (err) {
      setAlertComponent({
        message: "Erro na busca dos modelos de prontuário.",
        type: "error",
      });
    }
  };

  const downloadFile = async (fileId) => {
    const host = window.location.origin;
    window.open(`${host}/download/${fileId}`, "_blank");
  };

  const getTemplates = async () => {
    try {
      const { data } = await api.get(`/templates`);
      setDocumentsModels(data);
    } catch (err) {
      setAlertComponent({
        message: "Erro na busca dos modelos de prontuário.",
        type: "error",
      });
    }
  };

  const getMedicalRecord = async (appointmentId) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/schedules/${appointmentId}`);
      setLoading(false);
      return {
        date: data.date_start,
        professional: data.professional.name,
        resume: data.resume,
      };
    } catch (err) {
      setAlertComponent({
        message: "Erro na busca do prontuário.",
        type: "error",
      });
    }
    setLoading(false);
    return [];
  };

  const deleteTemplate = async (templateId) => {
    setLoading(true);
    try {
      await api.delete(`/templates/${templateId}`);
      await getTemplates();
      setAlertComponent({
        message: "Modelo de prontuário excluído com sucesso.",
        type: "success",
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro na exclusão do modelo de prontuário.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const checkIfUserHasMpoPlan = async (userId) => {
    try {
      const { data } = await api.get(`/mpo-plan/uservalidation/${userId}`);
      return data?.hasPlan === true;
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  const getSchedule = async () => {
    setLoading(true);
    let userAuthenticated = {};
    if (
      localStorage.getItem("user") &&
      Object.keys(authenticatedUser).length === 0
    ) {
      userAuthenticated = JSON.parse(localStorage.getItem("user"));
      setAuthenticatedUser(userAuthenticated);
    }
    try {
      const { data } = await api.get(`/schedules/${id}`);

      if (data.status !== "AGENDADO") {
        alert("Atendimento não agendado ou finalizado.");
        window.location.replace("/");
        return;
      }

      if (!data.roomUrl || data.roomUrl === "") {
        window.location.replace(`/meeting-room-legancy/${id}`);
      }

      if (data.professional.id === userAuthenticated?.professional?.id) {
        setLocalUsername(data.professional.name);

        const thisUserHasPLan = await checkIfUserHasMpoPlan(data.user.id);
        setRemoteUserName(
          `${data.user.name} ${thisUserHasPLan ? " (Plano MPO)" : ""}`,
        );
      } else {
        setLocalUsername(data.user.name);
        setRemoteUserName(data.professional.name);
      }
      setAppointmentDetails(data);
      setTimeRoomLeft({
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      if (userAuthenticated?.type === "professional") {
        await getTemplates();
        await getTimeLine(data.user.id);
      }
    } catch (err) {
      setAlertComponent({
        message: "Erro na busca do agendamento",
        type: "error",
      });
      setNotFoundModal(true);
    }
    setLoading(false);
  };

  const saveMedicalRecords = async (medicalRecords) => {
    setLoading(true);
    try {
      await api.post(`/medicalRecords`, {
        schedule_id: appointmentDetails.id,
        resume: medicalRecords,
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro na gravação do prontuário.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const uploadFile = async (fileStream, title) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", fileStream);
      formData.append("schedule_id", appointmentDetails.id);
      formData.append("title", title);

      await api.post(`/documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro na gravação do prontuário.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const createTemplate = async (
    newTitleModel,
    newDescriptionModel,
    newTextModel,
  ) => {
    setLoading(true);
    try {
      await api.post(`/templates`, {
        title: newTitleModel,
        description: newDescriptionModel,
        content: newTextModel,
      });
      await getTemplates();
      setAlertComponent({
        message: "Modelo de prontuário criado com sucesso.",
        type: "success",
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro na criação do modelo de prontuário.",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    async function getInfos() {
      if (Object.keys(appointmentDetails).length === 0) {
        await getSchedule();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (Object.keys(appointmentDetails).length > 0) {
      const meetDateTime = dayjs(appointmentDetails.date_start).add(3, "hours");
      const meetLimit = dayjs(appointmentDetails.date_end).add(3, "hours");

      const timeNow = dayjs().tz("America/Sao_Paulo");

      if (
        timeNow.isAfter(meetDateTime) &&
        timeNow.isBefore(meetLimit) &&
        appointmentDetails.status === "AGENDADO"
      ) {
        setMeetingAuthorized(true);
        setAcceptModal(true);
      } else {
        setMeetingAuthorized(false);
      }
    }
  }, [appointmentDetails]);

  const calculateTimeLeft = () => {
    const meetDateTime = dayjs(appointmentDetails.date_start).add(3, "hours");
    const timeNow = dayjs().tz("America/Sao_Paulo");
    const difference = meetDateTime.diff(timeNow);
    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    if (!meetingFinished) {
      setMeetingAuthorized(true);
    }
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  };

  const calculateTimeRoomLeft = () => {
    const meetLimit = dayjs(appointmentDetails.date_end).add(3, "hours");
    const timeNow = dayjs().tz("America/Sao_Paulo");
    const difference = meetLimit.diff(timeNow);
    if (difference < 0) {
      setMeetingAuthorized(false);
      setMeetingFinished(true);
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    setMeetingFinished(false);
    return {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  useEffect(() => {
    if (Object.keys(appointmentDetails).length > 0 && !meetingAuthorized) {
      setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
    }
  }, [timeLeft, appointmentDetails]);

  useEffect(() => {
    if (Object.keys(appointmentDetails).length > 0 && meetingAuthorized) {
      setTimeout(() => setTimeRoomLeft(calculateTimeRoomLeft()), 1000);
    }
  }, [timeRoomLeft, appointmentDetails]);

  useEffect(() => {
    if (authenticatedUser?.type === "professional") {
      window.addEventListener("beforeunload", (event) => {
        if (!scheduleFinished) {
          event.preventDefault();
        }
      });
    }
    return () => {
      window.removeEventListener("beforeunload", (event) => {
        if (!scheduleFinished) {
          event.preventDefault();
        }
      });
    };
  }, [authenticatedUser]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({ message: "", type: "" });
      }, 5000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>
      {alertComponent.message !== "" && (
        <MKBox sx={{ padding: 3 }}>
          <Alert severity={alertComponent.type}>{alertComponent.message}</Alert>
        </MKBox>
      )}
      {meetingAuthorized &&
        Object.keys(appointmentDetails).length > 0 &&
        appointmentDetails?.roomUrl !== "" &&
        !meetingFinished && (
          <MeetingRoomComponent
            roomUrl={appointmentDetails.roomUrl}
            localUserName={localUsername}
            remoteUserName={remoteUserName}
            handleClose={() => finalizeMeeting()}
          />
        )}
      {meetingAuthorized &&
        Object.keys(appointmentDetails).length > 0 &&
        appointmentDetails?.roomUrl === "" && (
          <MKBox
            component="section"
            py={{ xs: 2, md: 5 }}
            bgColor="#f0f0f0"
            borderRadius="10px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh",
            }}
          >
            <MKBox>
              <MKTypography variant="h5" px={3} textAlign="center">
                Sala de atendimento indisponível
              </MKTypography>
            </MKBox>
          </MKBox>
        )}
      {!meetingAuthorized &&
        Object.keys(appointmentDetails).length > 0 &&
        !meetingFinished && (
          <MKBox
            component="section"
            py={{ xs: 2, md: 5 }}
            bgColor="#f0f0f0"
            borderRadius="10px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh",
            }}
          >
            <MKBox>
              <MKTypography variant="h5" px={3} textAlign="center">
                O atendimento começará em:
              </MKTypography>
              <MKTypography variant="h4" px={3} textAlign="center" py={2}>
                <Grid container display="flex" justifyContent="space-around">
                  <MKBox>
                    <MKTypography variant="h3">
                      {String(timeLeft.days).padStart(2, "0")}
                    </MKTypography>
                    <MKTypography variant="body2">DIAS</MKTypography>
                  </MKBox>
                  <MKBox>
                    <MKTypography variant="h3">
                      {String(timeLeft.hours).padStart(2, "0")}
                    </MKTypography>
                    <MKTypography variant="body2">HORAS</MKTypography>
                  </MKBox>
                  <MKBox>
                    <MKTypography variant="h3">
                      {String(timeLeft.minutes).padStart(2, "0")}
                    </MKTypography>
                    <MKTypography variant="body2">MINUTOS</MKTypography>
                  </MKBox>
                  <MKBox>
                    <MKTypography variant="h3">
                      {String(timeLeft.seconds).padStart(2, "0")}
                    </MKTypography>
                    <MKTypography variant="body2">SEGUNDOS</MKTypography>
                  </MKBox>
                </Grid>
              </MKTypography>
              <MKTypography variant="body2" px={3} textAlign="center">
                Aguarde o horário de seu atendimento para entrar na sala!
              </MKTypography>
            </MKBox>
          </MKBox>
        )}
      {!meetingAuthorized &&
        Object.keys(appointmentDetails).length > 0 &&
        meetingFinished && (
          <MKBox
            component="section"
            py={{ xs: 2, md: 5 }}
            bgColor="#f0f0f0"
            borderRadius="10px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh",
            }}
          >
            <MKBox>
              <MKTypography variant="h3" px={3} textAlign="center">
                Seu atendimento foi finalizado
              </MKTypography>
            </MKBox>
          </MKBox>
        )}
      {authenticatedUser?.type === "professional" &&
        authenticatedUser.professional.id ===
          appointmentDetails?.professional?.id && (
          <>
            <MedicalTimeLine
              timeLineInfos={timeLineInfos}
              getMedicalRecord={getMedicalRecord}
              downloadFile={downloadFile}
            />
            <EletronicMedicalRecord
              createTemplate={createTemplate}
              deleteTemplate={deleteTemplate}
              documentsModels={documentsModels}
              saveMedicalRecord={saveMedicalRecords}
              uploadFile={uploadFile}
              appointmentInfos={appointmentDetails}
            />
            {timeRoomLeft?.seconds && (
              <MKBox
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  width: "200px",
                  height: "60px",
                  borderRadius: "10px",
                  backgroundColor: "#FF785B70",
                  zIndex: 30000,
                }}
              >
                <MKTypography variant="body2" textAlign="center" color="white">
                  TEMPO RESTANTE
                </MKTypography>
                <MKTypography variant="h4" textAlign="center" color="white">
                  {String(timeRoomLeft.hours).padStart(2, "0")}:
                  {String(timeRoomLeft.minutes).padStart(2, "0")}:
                  {String(timeRoomLeft.seconds).padStart(2, "0")}
                </MKTypography>
              </MKBox>
            )}
          </>
        )}

      <Dialog
        open={acceptModal}
        onClose={() => acceptTermsModal()}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography variant="body1">AVISO</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "#FFE8E8",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "500" }}
            >
              Você precisa CONCEDER PERMISSÃO de acesso a sua CÂMERA e MICROFONE
              para utilizar a sala de atendimento.
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "500" }}
            >
              Sem esssa permissão não é possível utilizar a sala de atendimento.
            </Typography>
          </Box>
          {authenticatedUser?.type === "professional" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                backgroundColor: "red",
                borderRadius: "10px",
                padding: 2,
                marginY: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "700", color: "yellow" }}
              >
                PROFISSIONAL...
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    margin: 2,
                    padding: "20px",
                    borderRadius: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <img
                    src={phoneoff}
                    alt="phoneoff"
                    style={{ width: "40px" }}
                  />
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "500",
                    color: "#ffffff",
                  }}
                >
                  Utilize o botão de ENCERRAR A CHAMADA para finalizar o
                  atendimento ou você terá que finalizá-lo no painel de
                  agendamentos.
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ color: "#ffffff" }}>
                O atendimento precisa ser finalizado para liberar a sala para o
                próximo paciente.
              </Typography>
            </Box>
          )}
          {/* <Box
            sx={{
              padding: "20px",
            }}
          >
            <Typography variant="subtitle2" textAlign="justify">
              Seu atendimento poderá ser gravado. Utilizamos esse procedimento
              aleatoriamente para garantir a qualidade de nossos atendimentos.
              Fique tranquilo, essa gravação não é acessível publicamente e
              serve exclusivamente para o processo de análise técnica do sistema
              e do profissional.
            </Typography>
          </Box> */}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingX: "40px",
            paddingY: "10px",
          }}
        >
          <Button
            onClick={() => acceptTermsModal()}
            style={{
              backgroundColor: "#ff8080",
              color: "white",
              textAlign: "center",
            }}
          >
            CLIQUE AQUI PARA CONFIRMAR E ACESSAR A SALA DE ATENDIMENTO
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={notFoundModal}
        maxWidth="sm"
        onClose={() => setNotFoundModal(false)}
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography variant="body1">AGENDAMENTO NÃO ENCONTRADO</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "#FFE8E8",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "700" }}
            >
              Por favor, entre em contato com o suporte.
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: "center", fontWeight: "500" }}
            >
              O botão flutuante no fim da página irá direcionar para nosso
              WhatsApp de atendimento.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default MeetingRoom;
