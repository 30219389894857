/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/no-unresolved */
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

// @mui material components
import { Container, Grid, Icon, Rating, useMediaQuery } from "@mui/material";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

import { api } from "../../../lib/axios";

function QuestionsCarroussel() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.locale("pt-br");

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [questions, setQuestions] = useState({});

  const getQuestions = async () => {
    try {
      const responseQuestions = await api.get("/questions");
      const ordernedQuestionsList = responseQuestions.data.filter(
        (item) => item.status === true,
      );
      ordernedQuestionsList.sort((a, b) =>
        dayjs(b.created_at).diff(dayjs(a.created_at)),
      );

      setQuestions(ordernedQuestionsList);
    } catch (err) {
      console.log(err);
    }
  };

  // Swiper navigation buttons styles
  const navigationStyles = {
    position: "absolute",
    top: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
    height: "100%",
    textAlign: "center",
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  useEffect(() => {
    async function getInfos() {
      if (Object.keys(questions).length === 0) {
        await getQuestions();
      }
    }
    getInfos();
  }, []);

  return (
    <MKBox component="section" py={5} variant="gradient" bgColor="light">
      <MKBox
        display="flex"
        justifyContent="center"
        alignItems={{ xs: "center", md: "flex-start" }}
        px={2}
        mt={-1}
      >
        <MKTypography
          variant="body1"
          fontWeight="medium"
          textTransform="uppercase"
          textAlign="center"
        >
          Perguntas feitas por usuários
        </MKTypography>
      </MKBox>
      <Swiper
        modules={[Autoplay, Navigation]}
        onInit={({ params, navigation }) => {
          const { navigation: nav } = params;

          nav.prevEl = navigationPrevRef.current;
          nav.nextEl = navigationNextRef.current;
          navigation.init();
          navigation.update();
        }}
        autoplay={{ delay: 5000 }}
        speed={800}
        spaceBetween={0}
        slidesPerView={1}
        loop
      >
        {Object.keys(questions).length > 0 &&
          questions.map((questionFiltered) => (
            <SwiperSlide key={questionFiltered.id}>
              <Container>
                <MKBox
                  key={questionFiltered.id}
                  component="section"
                  variant="gradient"
                  bgColor="light"
                  p={1}
                  sx={{
                    border: "solid #f0f0f0",
                    borderRadius: "20px",
                    height: isSmallScreen ? "80vh" : "40vh",
                  }}
                >
                  <Grid
                    container
                    bgcolor="#fff"
                    sx={{
                      border: "solid #f0f0f0",
                      borderRadius: "15px",
                    }}
                  >
                    <Grid
                      p={2}
                      textAlign="justify"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <MKTypography variant="button">
                        {questionFiltered.question}
                      </MKTypography>
                      <MKTypography variant="button">
                        <b>Pergunta feita por: </b>{" "}
                        {questionFiltered.user.name.split(" ")[0]} em{" "}
                        {dayjs
                          .tz(questionFiltered.created_at, "America/Sao_Paulo")
                          .subtract(3, "hours")
                          .format("DD/MM/YYYY HH:mm")}
                      </MKTypography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      lg={8}
                      p={2}
                      textAlign="justify"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <MKTypography variant="button">
                        {questionFiltered.answer}
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={4} p={2}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          lg={4}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <MKAvatar
                            src={`https://storage.googleapis.com/mpo_images/professionals/photos/${questionFiltered.professional.id}.jpeg`}
                            alt="xxl"
                            size="xxl"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={8}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <MKTypography>
                            {questionFiltered.professional.name}
                          </MKTypography>
                          <Rating
                            name="read-only"
                            value={questionFiltered.professional.rating}
                            readOnly
                          />
                          <MKBox p={1}>
                            <MKButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              component={Link}
                              to={{
                                pathname: `/professionaldetails/${questionFiltered.professional.id}`,
                                state: {
                                  from: "/home",
                                  serviceId: questionFiltered.service_id,
                                },
                              }}
                            >
                              Agendar Atendimento
                            </MKButton>
                          </MKBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MKBox>
              </Container>
            </SwiperSlide>
          ))}
        <MKTypography
          variant="h2"
          color="dark"
          sx={{
            ...navigationStyles,
            left: 0,
          }}
          ref={navigationPrevRef}
        >
          <Icon>chevron_left</Icon>
        </MKTypography>
        <MKTypography
          variant="h2"
          color="dark"
          sx={{
            ...navigationStyles,
            right: 0,
          }}
          ref={navigationNextRef}
        >
          <Icon>chevron_right</Icon>
        </MKTypography>
      </Swiper>
      <MKBox sx={{ px: "20%", py: "20px" }}>
        <MKButton
          variant="gradient"
          color="info"
          component={Link}
          to={"/questions"}
          size="large"
          fullWidth
        >
          PÁGINA DE PERGUNTAS E RESPOSTAS
        </MKButton>
        <MKTypography variant="body2" textAlign="center">
          Faça a sua pergunta aos nossos profissionais
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

export default QuestionsCarroussel;
