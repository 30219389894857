import { useEffect, useState } from "react";

import { api } from "lib/axios";
import * as jose from "jose";

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);
    }
  }, []);

  function setToken(responseToken) {
    localStorage.setItem("token", responseToken);

    const { user } = jose.decodeJwt(responseToken);

    if (user.type === 1) {
      user.type = "admin";
    } else if (user.type === 3) {
      user.type = "professional";
    } else if (user.type === 4) {
      user.type = "supervisor";
    } else {
      user.type = "user";
    }

    localStorage.setItem("user", JSON.stringify(user));
    api.defaults.headers.Authorization = `Bearer ${responseToken}`;
    setAuthenticated(true);
  }

  async function handleLogin(params) {
    try {
      const userDataLogin = params?.type
        ? {
          type: params.type,
          token: params.token,
          email: params?.email,
          name: params?.name,
          rememberMe: true,
        }
        : {
          mailOrCPF: params.mailOrCPF,
          password: params.password,
          rememberMe: params.rememberMe,
        };

      let response;
      if (!userDataLogin.type) {
        response = await api.post("/sessions", userDataLogin);
      } else if (userDataLogin.type === "apple") {
        response = await api.post("/sessions/apple", userDataLogin);
      } else if (userDataLogin.type === "google") {
        response = await api.post("/sessions/google", userDataLogin);
      }

      setToken(response.data);
    } catch (err) {
      setAuthenticated(false);
    }
  }

  function getAuthenticatedUser() {
    const responseToken = localStorage.getItem("token");
    if (!responseToken) {
      return null;
    }
    const { user } = jose.decodeJwt(responseToken);
    return user ?? null;
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    api.defaults.headers.Authorization = undefined;
  }

  return { authenticated, handleLogin, handleLogout, getAuthenticatedUser };
}
