/* eslint-disable import/prefer-default-export */
import axios from "axios";

// eslint-disable-next-line import/prefer-default-export
const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    UTM: localStorage.getItem("utm") ?? null,
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      api.defaults.headers.Authorization = null;
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export { api };
