/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useContext } from "react";
import { Context } from "Context/AuthContext";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import Menu from "components/Menu";
import DefaultFooter from "components/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// images
import LogoMPO from "assets/images/logo_horizontal.png";
import { useHistory } from "react-router-dom";

function Logout() {
  const history = useHistory();
  const { handleLogout } = useContext(Context);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  useEffect(() => {
    async function getInfos() {
      const timer = setTimeout(async () => {
        await handleLogout();
        localStorage.clear();
        history.push("/");
      }, 3000);
      return () => clearTimeout(timer);
    }
    getInfos();
  }, []);
  return (
    <>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox component="section" pt={20} pb={12}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Card>
                  <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={1}
                    mb={1}
                    textAlign="center"
                  >
                    <MKTypography variant="body2" color="white" mt={0}>
                      Ação de Usuário
                    </MKTypography>
                  </MKBox>
                  <MKBox pb={6} px={6}>
                    <MKTypography variant="body2" mt={6} mb={3}>
                      Fazendo o logout...
                    </MKTypography>
                  </MKBox>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <MKBox pt={1} mt={1}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Container>
    </>
  );
}

export default Logout;
