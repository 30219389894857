/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Grid } from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import melvi from "assets/images/logos/melvi.png";
import masterplan from "assets/images/logos/Masterplan_Saude.png";
import itaipu from "assets/images/logos/itaipu.png";
import unimed from "assets/images/logos/unimed_RS.png";

function Logos() {
  return (
    <MKBox component="section" py={2} sx={{ with: "100%" }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
          <MKTypography>Nossos Parceiros</MKTypography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            alignContent: "center",
            flexWrap: "wrap",
          }}
        >
          <MKBox
            component="img"
            src={melvi}
            alt="melvi"
            width="150px"
            sx={{ margin: 2 }}
            opacity={0.6}
          />
          <MKBox
            component="img"
            src={masterplan}
            alt="MasterPlan"
            width="300px"
            sx={{ margin: 2 }}
            opacity={0.6}
          />
          <MKBox
            component="img"
            src={itaipu}
            alt="Itaipu"
            width="200px"
            sx={{ margin: 2 }}
            opacity={0.6}
          />
          <MKBox
            component="img"
            src={unimed}
            alt="Unimed"
            width="300px"
            sx={{ margin: 2 }}
            opacity={0.6}
          />
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default Logos;
