import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import routes from "routes";
import Menu from "components/Menu";
import LogoMPO from "assets/images/logo_horizontal.png";

import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import { api } from "../../lib/axios";

function ForgotPassword() {
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const history = useHistory();
  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const solicitarSenhaTemporaria = async () => {
    setLoading(true);
    try {
      const response = await api.post("/users/resetPassword", { email });
      if (response.status === 200) {
        setEmail("");
        setAlertComponent({
          message: "Enviamos uma senha temporária para seu e-mail.",
          type: "success",
        });
        setTimeout(() => {
          history.push(`/login`);
        }, 3000);
      } else {
        setAlertComponent({
          message: `E-mail ${email} não encontrado.`,
          type: "error",
        });
      }
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          paddingTop: "180px",
        }}
      >
        <Card sx={{ minWidth: "50%", maxWidth: "90%" }}>
          <MKBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MKTypography variant="body1" color="white" my={1}>
              Recuperar Senha
            </MKTypography>
            <MKTypography variant="body2" color="white" my={1}>
              Solicite um senha temporária por e-mail!
            </MKTypography>
          </MKBox>
          <MKBox pt={4} pb={3} px={3}>
            {alertComponent.message && (
              <Grid item xs={12}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </Grid>
            )}
            <MKBox component="form" role="form">
              <MKBox mb={4}>
                <MKInput
                  variant="outlined"
                  type="email"
                  name="email"
                  label="E-mail"
                  InputLabelProps={{ shrink: true }}
                  placeholder="usuario@email.com.br"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </MKBox>
              <MKBox mt={6} mb={1}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={() => solicitarSenhaTemporaria()}
                  fullWidth
                  disabled={!email}
                >
                  Solicitar
                </MKButton>
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
      </Container>
    </>
  );
}

export default ForgotPassword;
