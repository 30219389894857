import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import routes from "routes";

import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKAlert from "components/MKAlert";
import HorizontalTeamCard from "components/Cards/HorizontalTeamCard";

import LogoMPO from "assets/images/logo_horizontal.png";
import healthplanimg from "assets/images/plano-saude.jpg";
import itaipu from "assets/images/logos/itaipu.png";
import marterPlan from "assets/images/logos/Masterplan_Saude.png";
import unimed from "assets/images/logos/unimed_RS.png";

import { api } from "../../lib/axios";

const healthplanGroup = process.env.REACT_APP_HEALTHPLAN_GROUP;

function HealthPlan() {
  const location = useLocation();
  const pathOrigin = location.pathname;
  const history = useHistory();

  const { serviceId } = useParams();

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "info",
  });
  const [showNothingProfessional, setShowNothingProfessional] = useState(false);
  const [loading, setLoading] = useState(false);
  const [professionals, setProfessionals] = useState([]);
  const [services, setServices] = useState([]);

  const handleSelectService = (servId) => {
    setProfessionals([]);
    history.push(`/healthplan/${servId}`);
  };

  const getProfessionals = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/professionals?service_group_id=${healthplanGroup}&service_id=${serviceId}`,
      );
      if (data.length === 0) {
        setLoading(false);
        setAlertComponent({
          message: "Nenhum profissional encontrado!",
          type: "error",
        });
        // window.location.replace("/healthplan");
      }
      const professionalsFiltered = data.filter((prof) => prof.status === true);
      setProfessionals(professionalsFiltered);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getServicesByGroup = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/services?service_group_id=${healthplanGroup}`,
      );
      const filtered = data.filter((serv) => serv.deleted_at === null);
      const order = [
        "Psiquiatra",
        "Endocrinologia",
        "Cardiologista",
        "Clínico Geral",
        "Gastroenterologia",
      ];
      const sortedGroupList = filtered.sort((a, b) => {
        const indexA = order.indexOf(a.name);
        const indexB = order.indexOf(b.name);

        // Se ambos estiverem na lista "order", ordena com base na ordem em "order"
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        // Se apenas um estiver na lista "order", ele vem primeiro
        if (indexA !== -1) {
          return -1;
        }
        if (indexB !== -1) {
          return 1;
        }

        // Se nenhum estiver na lista "order", ordena alfabeticamente
        return a.name.localeCompare(b.name);
      });
      console.log("sortedGroupList", sortedGroupList);
      setServices(sortedGroupList);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setShowNothingProfessional(false);
    async function getInfos() {
      const urlSplited = pathOrigin.split("/");
      if (urlSplited.length > 2) {
        await getProfessionals();
      }
      if (services.length === 0 && !serviceId) {
        await getServicesByGroup();
      }
    }
    getInfos();
  }, [serviceId]);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  useEffect(() => {
    if (alertComponent.message !== "") {
      if (
        alertComponent.type === "error" &&
        serviceId &&
        professionals.length === 0
      ) {
        setShowNothingProfessional(true);
      }
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 3000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        minHeight={{ xs: "30vh", lg: "70vh" }}
        width="100%"
        sx={{
          backgroundImage: `url(${healthplanimg})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="left" mx="auto" />
        </Container>
      </MKBox>
      <Card
        sx={{
          mx: { xs: 0, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container spacing={1} sx={{ py: 5, px: 2 }}>
          {alertComponent.message !== "" && (
            <Grid item xs={12}>
              <MKAlert color={alertComponent.type}>
                {alertComponent.message}
              </MKAlert>
            </Grid>
          )}
          <Grid item xs={12} textAlign="center">
            <MKTypography variant="h3">Planos de Saúde</MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            sx={{ textAlign: "center", width: "100%" }}
          >
            <MKBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <MKBox
                component="img"
                src={itaipu}
                alt="Itaipu"
                height={30}
                margin={1}
              />
              <MKBox
                component="img"
                src={marterPlan}
                alt="Marter Plan"
                height={35}
                margin={1}
              />
              <MKBox
                component="img"
                src={unimed}
                alt="Unimed"
                height={40}
                margin={1}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <MKTypography variant="6">
              Solicite mais informações para seu atendimento pelo plano de saúde
              pelo nosso WhatsApp
            </MKTypography>
          </Grid>
          {!serviceId && (
            <>
              {services.map((service) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={service.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      m: { xs: 0, lg: 1 },
                      p: 2,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSelectService(service.id)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          padding: { xs: 0, lg: 2 },
                        }}
                      >
                        <MKBox
                          component="img"
                          src={`/icons/${service.id}.png`}
                          alt=""
                          sx={{ width: 80, height: 80 }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          alignContent: "left",
                          padding: { xs: 1, lg: 2 },
                        }}
                      >
                        <MKTypography variant="h5">{service.name}</MKTypography>
                        <Typography
                          variant="body2"
                          sx={{ textAlign: "justify" }}
                        >
                          {service.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </>
          )}
          {serviceId && (
            <Grid item xs={12}>
              <Divider />
              <Grid container sx={{ paddingY: "1%" }}>
                {professionals.length > 0 &&
                  professionals.map((professional) => (
                    <Grid item xs={12} lg={6} key={professional.id} p="1%">
                      <MKBox mb={1}>
                        <HorizontalTeamCard
                          image={`https://storage.googleapis.com/mpo_images/professionals/photos/${professional.id}.jpeg`}
                          name={professional.name}
                          position={{
                            color: "info",
                            label: `${
                              professional.services.find(
                                (profServ) =>
                                  profServ.group_id === healthplanGroup,
                              ).name
                            }`,
                          }}
                          resume={professional.resume}
                          rating={professional.rating ?? 0}
                          price={0}
                          duration={
                            professional.services.find(
                              (profServ) =>
                                profServ.group_id === healthplanGroup,
                            )?.duration || 60
                          }
                          id={professional.id}
                          service={
                            professional.services.find(
                              (profServ) =>
                                profServ.group_id === healthplanGroup,
                            ).id
                          }
                          pathOrigin={pathOrigin}
                          group={healthplanGroup}
                          percentageDiscount={
                            professional.percentageDiscount || 0
                          }
                        />
                      </MKBox>
                    </Grid>
                  ))}
                {professionals.length === 0 && showNothingProfessional && (
                  <Typography
                    variant="h4"
                    sx={{ textAlign: "center", width: "100%", py: 5 }}
                  >
                    Não temos profissionais atendendo esse serviço no momento.
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
}

export default HealthPlan;
