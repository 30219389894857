import Icon from "@mui/material/Icon";
// import Privacy from "pages/Privacy";
import UserArea from "pages/UserArea";
// import ProfessionalArea from "pages/ProfessionalArea";
import Psychiatrist from "pages/Psychiatrist";
import Psychologists from "pages/Psychologists";
import Logout from "pages/Logout";
import Login from "pages/Login";
import AdministratorArea from "pages/Administrator";
import QuestionsToAnswer from "pages/QuestionsToAnswer";
// import FAQ from "pages/FAQ";
import UserAppointments from "pages/UserAppointments";
import ProfessionalAppointments from "pages/ProfessionalAppointments";
import HealthPlan from "pages/HealthPlan";
import Canabidiol from "pages/Canabidiol";
// import StorePage from "pages/StorePage";
import Questions from "pages/Questions";
// import Purchases from "pages/Purchases";

const routes = {
  user: [
    {
      name: "Psiquiatria",
      icon: <Icon>psychology</Icon>,
      route: "/psychiatrists",
      component: <Psychiatrist />,
      key: "psychiatrists",
    },
    {
      name: "Clínico Geral",
      icon: <Icon>healing</Icon>,
      route: "/clinical",
      key: "clinical",
    },
    {
      name: "Psicologia",
      icon: <Icon>psychology</Icon>,
      route: "/psychologists",
      component: <Psychologists />,
      key: "psychologists",
    },
    {
      name: "CBD",
      icon: <Icon>spa</Icon>,
      route: "/cbd",
      component: <Canabidiol />,
      key: "cbd",
    },
    {
      name: "Plano de Saúde",
      icon: <Icon>medication</Icon>,
      route: "/healthplan",
      component: <HealthPlan />,
      key: "healthplan",
    },
    {
      name: "Loja",
      icon: <Icon>store</Icon>,
      href: "https://loja.meupsiquiatraonline.com.br",
      key: "store",
    },
    {
      name: "Blog",
      icon: <Icon>chat</Icon>,
      href: "https://blog.meupsiquiatraonline.com.br",
      key: "blog",
    },
    // {
    //   name: "Ajuda",
    //   icon: <Icon>article</Icon>,
    //   collapse: [
    //     {
    //       name: "Pergunte ao Expecialista",
    //       description: "Receba respostas para suas dúvidas.",
    //       route: "/questions",
    //       component: <Questions />,
    //       key: "privacy",
    //     },
    //     {
    //       name: "Nossa Política de Privacidade",
    //       description:
    //         "Conheça nossos termos de uso e nossa política de privacidade",
    //       route: "/privacy-politic",
    //       component: <Privacy />,
    //       key: "privacy",
    //     },
    //     {
    //       name: "Perguntas Frequentes",
    //       description:
    //         "Respostas para algumas das perguntas que recebemos com maior frequência",
    //       route: "/faq",
    //       component: <FAQ />,
    //       key: "faq",
    //     },
    //   ],
    // },
    {
      name: "Área do Usuário",
      icon: <Icon>account_circle</Icon>,
      collapse: [
        {
          name: "Perfil",
          route: "/user-area",
          component: <UserArea />,
          key: "user-area",
        },
        {
          name: "Agendamentos",
          route: "/user-appointments",
          component: <UserAppointments />,
          key: "user-appointments",
        },
        // {
        //   name: "Compras",
        //   route: "/user-purchases",
        //   component: <Purchases />,
        //   key: "user-purchases",
        // },
        {
          name: "Perguntas & Respostas",
          description: "Receba respostas para suas dúvidas.",
          route: "/questions",
          component: <Questions />,
          key: "privacy",
        },
        {
          name: "Logout",
          route: "/logout",
          component: <Logout />,
          key: "logout",
        },
      ],
    },
  ],
  professional: [
    {
      name: "Psiquiatria",
      icon: <Icon>psychology</Icon>,
      route: "/psychiatrists",
      component: <Psychiatrist />,
      key: "psychiatrists",
    },
    {
      name: "Clínico Geral",
      icon: <Icon>healing</Icon>,
      route: "/clinical",
      key: "clinical",
    },
    {
      name: "Psicologia",
      icon: <Icon>psychology</Icon>,
      route: "/psychologists",
      component: <Psychologists />,
      key: "psychologists",
    },
    {
      name: "CBD",
      icon: <Icon>spa</Icon>,
      route: "/cbd",
      component: <Canabidiol />,
      key: "cbd",
    },
    {
      name: "Plano de Saúde",
      icon: <Icon>medication</Icon>,
      route: "/healthplan",
      component: <HealthPlan />,
      key: "healthplan",
    },
    {
      name: "Loja",
      icon: <Icon>store</Icon>,
      href: "https://loja.meupsiquiatraonline.com.br",
      key: "store",
    },
    {
      name: "Blog",
      icon: <Icon>chat</Icon>,
      href: "https://blog.meupsiquiatraonline.com.br",
      key: "blog",
    },
    // {
    //   name: "Ajuda",
    //   icon: <Icon>article</Icon>,
    //   collapse: [
    //     {
    //       name: "Nossa Política de Privacidade",
    //       description:
    //         "Conheça nossos termos de uso e nossa política de privacidade",
    //       route: "/privacy-politic",
    //       component: <Privacy />,
    //       key: "privacy",
    //     },
    //   ],
    // },
    {
      name: "Área Profissional",
      icon: <Icon>account_circle</Icon>,
      collapse: [
        {
          name: "Perfil",
          route: "/user-area",
          component: <UserArea />,
          key: "user-area",
        },
        {
          name: "Agendamentos",
          route: "/professional-appointments",
          component: <ProfessionalAppointments />,
          key: "professional-appointments",
        },
        {
          name: "Responder Perguntas",
          route: "/answer-questions",
          component: <QuestionsToAnswer />,
          key: "answer-questions",
        },
        {
          name: "Logout",
          route: "/logout",
          component: <Logout />,
          key: "logout",
        },
      ],
    },
  ],
  admin: [
    {
      name: "Psiquiatria",
      icon: <Icon>psychology</Icon>,
      route: "/psychiatrists",
      component: <Psychiatrist />,
      key: "psychiatrists",
    },
    {
      name: "Clínico Geral",
      icon: <Icon>healing</Icon>,
      route: "/clinical",
      key: "clinical",
    },
    {
      name: "Psicologia",
      icon: <Icon>psychology</Icon>,
      route: "/psychologists",
      component: <Psychologists />,
      key: "psychologists",
    },
    {
      name: "CBD",
      icon: <Icon>spa</Icon>,
      route: "/cbd",
      component: <Canabidiol />,
      key: "cbd",
    },
    {
      name: "Plano de Saúde",
      icon: <Icon>medication</Icon>,
      route: "/healthplan",
      component: <HealthPlan />,
      key: "healthplan",
    },
    {
      name: "Loja",
      icon: <Icon>store</Icon>,
      href: "https://loja.meupsiquiatraonline.com.br",
      key: "store",
    },
    {
      name: "Blog",
      icon: <Icon>chat</Icon>,
      href: "https://blog.meupsiquiatraonline.com.br",
      key: "blog",
    },
    // {
    //   name: "Ajuda",
    //   icon: <Icon>article</Icon>,
    //   collapse: [
    //     {
    //       name: "Nossa Política de Privacidade",
    //       description:
    //         "Conheça nossos termos de uso e nossa política de privacidade",
    //       route: "/privacy-politic",
    //       component: <Privacy />,
    //       key: "privacy",
    //     },
    //   ],
    // },
    {
      name: "Área Administrativa",
      icon: <Icon>account_circle</Icon>,
      collapse: [
        {
          name: "Perfil",
          route: "/user-area",
          component: <UserArea />,
          key: "user-area",
        },
        {
          name: "Painel",
          route: "/administrator-area",
          component: <AdministratorArea />,
          key: "professional-area",
        },
        {
          name: "Logout",
          route: "/logout",
          component: <Logout />,
          key: "logout",
        },
      ],
    },
  ],
  public: [
    {
      name: "Psiquiatria",
      icon: <Icon>psychology</Icon>,
      route: "/psychiatrists",
      component: <Psychiatrist />,
      key: "psychiatrists",
    },
    {
      name: "Clínico Geral",
      icon: <Icon>healing</Icon>,
      route: "/clinical",
      key: "clinical",
    },
    {
      name: "Psicologia",
      icon: <Icon>psychology</Icon>,
      route: "/psychologists",
      component: <Psychologists />,
      key: "psychologists",
    },
    {
      name: "CBD",
      icon: <Icon>spa</Icon>,
      route: "/cbd",
      component: <Canabidiol />,
      key: "cbd",
    },
    {
      name: "Plano de Saúde",
      icon: <Icon>medication</Icon>,
      route: "/healthplan",
      component: <HealthPlan />,
      key: "healthplan",
    },
    {
      name: "Loja",
      icon: <Icon>store</Icon>,
      href: "https://loja.meupsiquiatraonline.com.br",
      key: "store",
    },
    {
      name: "Blog",
      icon: <Icon>chat</Icon>,
      href: "https://blog.meupsiquiatraonline.com.br",
      key: "blog",
    },
    // {
    //   name: "Ajuda",
    //   icon: <Icon>article</Icon>,
    //   collapse: [
    //     // {
    //     //   name: "Pergunte ao Expecialista",
    //     //   description: "Receba respostas para suas dúvidas.",
    //     //   route: "/questions",
    //     //   component: <Questions />,
    //     //   key: "privacy",
    //     // },
    //     {
    //       name: "Nossa Política de Privacidade",
    //       description:
    //         "Conheça nossos termos de uso e nossa política de privacidade",
    //       route: "/privacy-politic",
    //       component: <Privacy />,
    //       key: "privacy",
    //     },
    //     {
    //       name: "Perguntas Frequentes",
    //       description:
    //         "Respostas para algumas das perguntas que recebemos com maior frequência",
    //       route: "/faq",
    //       component: <FAQ />,
    //       key: "faq",
    //     },
    //   ],
    // },
    {
      name: "Área do Usuário",
      icon: <Icon>account_circle</Icon>,
      collapse: [
        {
          name: "Perguntas & Respostas",
          description: "Receba respostas para suas dúvidas.",
          route: "/questions",
          component: <Questions />,
          key: "privacy",
        },
        {
          name: "Login",
          route: "/login",
          component: <Login />,
          key: "login",
        },
      ],
    },
  ],
};

export default routes;
