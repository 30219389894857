/* eslint-disable no-alert */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "Context/AuthContext";
import routes from "routes";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";

import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import bgImage from "assets/images/bg-Questions.jpg";
import LogoMPO from "assets/images/logo_horizontal.png";
import CloseIcon from "@mui/icons-material/Close";

import MKBox from "components/MKBox";
import Menu from "components/Menu";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKAlert from "components/MKAlert";

import { api } from "lib/axios";

function QuestionsToAnswer() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.locale("pt-br");

  const history = useHistory();
  const { handleLogout } = useContext(Context);
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const [loading, setLoading] = useState(false);
  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "",
  });
  const [alertComponentModal, setAlertComponentModal] = useState({
    message: "",
    type: "",
  });

  const [questionsFiltered, setQuestionsFiltered] = useState({});
  const [professional, setProfessional] = useState({});
  const [serviceId, setServiceId] = useState("");
  const [answerQuestion, setAnswerQuestion] = useState("");

  const [questionSelected, setQuestionSelected] = useState({});
  const [openModalToAnswerQuestion, setOpenModalToAnswerQuestion] =
    useState(false);

  const handleOpenModalToAnswerQuestion = (questionId) => {
    const thisQuestion = questionsFiltered.find(
      (question) => question.id === questionId,
    );
    setQuestionSelected(thisQuestion);
    setOpenModalToAnswerQuestion(true);
  };

  const handleCloseModalToAnswerQuestion = () => {
    setQuestionSelected({});
    setServiceId("");
    setAnswerQuestion("");
    setOpenModalToAnswerQuestion(false);
  };

  const getQuestionsToAnswer = async () => {
    setLoading(true);
    try {
      const responseQuestions = await api.get("/questions");
      const questions = responseQuestions.data.filter(
        (question) => question.answer === null,
      );
      const unansweredQuestions = questions.sort((a, b) =>
        dayjs(a.date_start).diff(dayjs(b.date_start)),
      );

      setQuestionsFiltered(unansweredQuestions);
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message && "Erro na extração das perguntas.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getProfessional = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(`/professionals/${id}`);
      setProfessional(response.data);
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message && "Erro na busca do profissional.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const makeAnswerQuestion = async (questionId) => {
    if (answerQuestion === "" || answerQuestion.length < 20) {
      setAlertComponentModal({
        message:
          "O campo resposta precisa ser preenchido (mínimo de 20 caracteres)",
        type: "error",
      });
      return;
    }
    if (serviceId === "") {
      setAlertComponentModal({
        message: "Necessário escolher o serviço de direcionamento do usuário.",
        type: "error",
      });
      return;
    }
    setLoading(true);
    try {
      const dataToWrite = {
        professional_id: authenticatedUser.professional.id,
        answer: answerQuestion,
        service_id: serviceId,
      };
      await api.put(`/questions/answer/${questionId}`, dataToWrite);
      setAlertComponentModal({
        message: "Pergunta respondida com sucesso.",
        type: "success",
      });
      setTimeout(async () => {
        handleCloseModalToAnswerQuestion();
        await getQuestionsToAnswer();
        setLoading(false);
      }, 3000);
    } catch (err) {
      setAlertComponentModal({
        message:
          err?.response?.data?.message && "Erro na gravação da pergunta.",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    async function getInfos() {
      if (!authenticatedUser || authenticatedUser.type !== "professional") {
        await handleLogout();
        localStorage.clear();
        history.push("/");
      }
      if (Object.keys(professional).length === 0) {
        await getProfessional(authenticatedUser.professional.id);
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    async function getInfos() {
      await getQuestionsToAnswer();
    }
    getInfos();
  }, [professional]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (alertComponentModal.message !== "") {
      setTimeout(() => {
        setAlertComponentModal({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponentModal]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="left" mx="auto" />
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 1,
          mx: { xs: 0, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography variant="h3" textAlign="center">
          Responda as perguntas dos usuários
        </MKTypography>
        <Divider />
        <Container>
          <MKTypography variant="body1" textAlign="center" p={5}>
            ATENÇÃO: Responda apenas as perguntas que você possua domínio e
            consiga atender o paciente em uma solução. Cada pergunta será
            respondida apenas por um ÚNICO profissional, portanto, responda de
            maneira clara e objetiva, de forma a facilitar a interpretação e
            entendimento da resposta.
          </MKTypography>
        </Container>
        <Divider />
        <Container>
          {alertComponent.message !== "" && (
            <Grid item xs={12}>
              <MKAlert color={alertComponent.type}>
                {alertComponent.message}
              </MKAlert>
            </Grid>
          )}
          {Object.keys(questionsFiltered).length === 0 && (
            <MKBox
              component="section"
              variant="gradient"
              bgColor="light"
              p={1}
              sx={{
                border: "solid 1px #f0f0f0",
                borderRadius: "20px",
              }}
            >
              <MKTypography variant="h3" p={5} textAlign="center">
                Nenhuma pergunta para responder no momento
              </MKTypography>
            </MKBox>
          )}
          {Object.keys(questionsFiltered).length > 0 &&
            questionsFiltered.map((eachQuerstion) => (
              <MKBox
                key={eachQuerstion.id}
                component="section"
                variant="gradient"
                bgColor="light"
                p={1}
                sx={{
                  border: "solid #f0f0f0",
                  borderRadius: "20px",
                }}
              >
                <Grid
                  container
                  bgcolor="#fff"
                  sx={{
                    border: "solid #f0f0f0",
                    borderRadius: "15px",
                  }}
                >
                  <Grid
                    item
                    p={2}
                    textAlign="justify"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <MKTypography variant="body2">
                      {eachQuerstion.question}
                    </MKTypography>
                    <MKTypography variant="body2" pt={1}>
                      <b>Pergunta feita por: </b>{" "}
                      {eachQuerstion.user.name.split(" ")[0]} em{" "}
                      {dayjs(eachQuerstion.created_at)
                        .tz("America/Sao_Paulo")
                        .add(3, "hours")
                        .format("DD/MM/YYYY HH:mm")}
                    </MKTypography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} py={1}>
                  <MKButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={() =>
                      handleOpenModalToAnswerQuestion(eachQuerstion.id)
                    }
                  >
                    RESPONDER PERGUNTA
                  </MKButton>
                </Grid>
              </MKBox>
            ))}
        </Container>
        <Divider />
      </Card>

      {/* Modal para responder pergunta */}
      <Dialog
        open={openModalToAnswerQuestion}
        onClose={handleCloseModalToAnswerQuestion}
        scroll="body"
        aria-labelledby="times-avaliable-title"
        aria-describedby="times-avaliable-description"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle
          id="times-avaliable-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Responder Pergunta</h3>
          </div>
          <div>
            <IconButton onClick={handleCloseModalToAnswerQuestion}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {alertComponentModal.message !== "" && (
            <Grid item xs={12}>
              <MKAlert color={alertComponentModal.type}>
                {alertComponentModal.message}
              </MKAlert>
            </Grid>
          )}
          {Object.keys(questionSelected).length > 0 && (
            <Grid container>
              <Grid
                item
                p={2}
                textAlign="justify"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MKTypography variant="body1">
                  {questionSelected.question}
                </MKTypography>
                <MKTypography variant="body2" pt={1}>
                  <b>Pergunta feita por: </b>{" "}
                  {questionSelected.user.name.split(" ")[0]} em{" "}
                  {dayjs(questionSelected.created_at)
                    .tz("America/Sao_Paulo")
                    .add(3, "hours")
                    .format("DD/MM/YYYY HH:mm")}
                </MKTypography>
              </Grid>
              <Grid container spacing={1} py={1}>
                <Grid item xs={12} md={12}>
                  <MKInput
                    name="answerQuestion"
                    label="Responda essa pergunta:"
                    value={answerQuestion}
                    onChange={(e) => setAnswerQuestion(e.target.value)}
                    multiline
                    minRows={5}
                    maxRows={10}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", padding: 1 }}
                  >
                    Para qual dos seus serviços devemos direcionar o usuário?
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Serviço</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="service"
                      id="service"
                      value={serviceId}
                      label="Serviço"
                      sx={{ height: "45px" }}
                      onChange={(e) => setServiceId(e.target.value)}
                      required
                    >
                      {Object.keys(professional.services).length > 0 &&
                        professional.services.map((service) => (
                          <MenuItem key={service.id} value={service.id}>
                            {service.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <MKButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={() => makeAnswerQuestion(questionSelected.id)}
                  >
                    RESPONDER PERGUNTA
                  </MKButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default QuestionsToAnswer;
