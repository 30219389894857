import React, { useEffect, useState, useRef, useMemo } from "react";
import routes from "routes";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";

import { DataGrid, ptBR } from "@mui/x-data-grid";

import MKBox from "components/MKBox";
import Menu from "components/Menu";
import MKAlert from "components/MKAlert";

import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import LogoMPO from "assets/images/logo_horizontal.png";

import { api } from "../../../lib/axios";

const SERVICEID = "81e983be-13af-4153-8fa6-49fe858c1103";

function AdminQuickServices() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.locale("pt-br");

  const warningColor = {
    PENDENTE: "#cecece",
    ATENDENDO: "#ffe8a0",
    CONCLUIDO: "#caffa0",
    CANCELADO: "#ffd8d8"
  }

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const [appointments, setAppointments] = useState([]);
  const [appointmentSelected, setAppointmentSelected] = useState({});

  const [uploadModal, setUploadModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileTypeCustom, setFileTypeCustom] = useState("");

  const [patientsWaiting, setPatientsWaiting] = useState(0)
  const [appointmentDetailsModal, setAppointmentDetailsModal] = useState(false);

  const [totalAppointments, setTotalAppointments] = useState(0);
  const [showDisabled, setShowDisabled] = useState(true);

  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 50,
  });

  const rowCountRef = useRef(totalAppointments);
  const rowCount = useMemo(() => {
    if (totalAppointments !== undefined) {
      rowCountRef.current = totalAppointments;
    }
    return rowCountRef.current;
  }, [totalAppointments]);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const openUploadModal = (row) => {
    setAppointmentSelected(row);
    setUploadModal(true);
  };

  const closeUploadModal = () => {
    setUploadModal(false);
    setAppointmentSelected({});
    setFile(null);
    setFileType("");
    setFileTypeCustom("");
  };

  const openAppointmentDetailsModal = (appointment) => {
    setAppointmentSelected(appointment);
    setAppointmentDetailsModal(true);
  };

  const closeAppointmentDetailsModal = () => {
    setAppointmentDetailsModal(false);
    setAppointmentSelected({});
  }

  const normalizeCellPhone = (cellPhoneInput) => {
    const cellPhoneFormated = cellPhoneInput
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
    return cellPhoneFormated;
  };

  const getAppointments = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("quick-service", {
        params: {
          page: paginationModel?.page || 1,
          size: paginationModel?.pageSize || 50,
          showDisabled,
        },
      });
      const pendingFirst = data.quickServices.sort((a, b) => {
        if (a.status === "PENDENTE") {
          return -1;
        }
        return 1;
      });
      setAppointments(pendingFirst);
      setTotalAppointments(data.total);
    } catch (err) {
      setAlertComponent({
        message: "Erro na extração dos agendamentos",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getPatientsWaiting = async () => {
    try {
      const { data } = await api.get(`quick-service/check-queue-quantity?serviceId=${SERVICEID}`)
      setPatientsWaiting(data?.quantity || 0)
    } catch (error) {
      setPatientsWaiting(0)
    }
  }

  const uploadNewFile = async () => {
    if (Object.keys(appointmentSelected).length === 0) {
      setAlertComponent({
        type: "error",
        message: "Agendamento não encontrado.",
      });
      setUploadModal(false);
      return;
    }

    if (!file) {
      setAlertModalInfos({
        type: "error",
        message: "Selecione o arquivo que deseja enviar",
      });
      return;
    }

    if (!fileType) {
      setAlertModalInfos({
        type: "error",
        message: "Selecione o tipo do arquivo",
      });
      return;
    }

    if (fileType === "custom" && fileTypeCustom === "") {
      setAlertModalInfos({
        type: "error",
        message: "Informe o tipo do arquivo no campo de texto",
      });
      return;
    }

    let title = "";
    if (fileType === "custom") {
      title = fileTypeCustom;
    } else {
      title = fileType;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("schedule_id", appointmentSelected.id);
      formData.append("title", title);

      await api.post(`/documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      closeUploadModal();
      setAlertComponent({
        type: "success",
        message: "Arquivo salvo com sucesso",
      });
    } catch (error) {
      closeUploadModal();
      setAlertComponent({
        type: "error",
        message: "Erro ao salvar arquivo",
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "user",
      headerName: "Usuário",
      flex: 1,
      headerClassName: "dataGrid-header",
      disableColumnFilter: true,
      disableColumnMenu: true,
      renderCell: ({ row }) => row?.user?.name || "---",
    },
    {
      field: "phone",
      headerName: "Celular",
      flex: 1,
      headerClassName: "dataGrid-header",
      disableColumnFilter: true,
      disableColumnMenu: true,
      renderCell: ({ row }) => row?.user?.cellphone ? normalizeCellPhone(row.user.cellphone.substr(2)) : "---",
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1.5,
      headerClassName: "dataGrid-header",
      disableColumnFilter: true,
      disableColumnMenu: true,
      renderCell: ({ row }) => row?.user?.email || "---",
    },
    {
      field: "professional",
      headerName: "Profissional",
      flex: 1,
      headerClassName: "dataGrid-header",
      disableColumnFilter: true,
      disableColumnMenu: true,
      renderCell: ({ row }) => row?.professional?.name || "---",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "dataGrid-header",
      disableColumnFilter: true,
      disableColumnMenu: true,
    },

    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      exportable: false,
      filtrable: false,
      sortable: false,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <MKBox>
          <Tooltip title="Detalhes do agendamento" placement="top">
            <IconButton
              size="large"
              onClick={() => openAppointmentDetailsModal(row)}
            >
              <PreviewIcon />
            </IconButton>
          </Tooltip>
          {row.status === "FINALIZADO" && (
            <Tooltip title="Enviar arquivo" placement="top">
              <IconButton size="large" onClick={() => openUploadModal(row)}>
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
          )}
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    if (!authenticatedUser || authenticatedUser.type !== "admin") {
      window.location.replace("/login");
    }
    getAppointments();
    getPatientsWaiting()
    const interval = setInterval(async () => {
      getPatientsWaiting()
      getAppointments();
    }, 60000)
    return () => clearInterval(interval)
  }, []);

  useEffect(() => {
    async function getInfos() {
      await getAppointments();
    }
    getInfos();
  }, [paginationModel]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Box sx={{ paddingTop: "150px", margin: "20px" }}>
        {alertComponent.message && (
          <Grid item xs={12} p={2}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: "#efefef",
          margin: "10px",
          padding: "20px",
          borderRadius: "20px",
          border: "1px solid #aaaaaa",
          textAlign: "center"
        }}>
          <Typography variant="h4"><b>{patientsWaiting} paciente(s) aguarda(m) atendimento.</b></Typography>
        </Box>
        <Box
          sx={{
            height: "65vh",
            width: "100%",
            paddingX: "10px",
            textAlign: "center",
            "& .dataGrid-header": {
              backgroundColor: "#00bbd4",
              color: "#fff",
              fontWeight: "bold",
            },
          }}
        >
          <DataGrid
            rows={appointments}
            columns={columns}
            rowCount={rowCount}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            localeText={
              ptBR.components.MuiDataGrid.defaultProps.localeText
            }
            style={{ minHeight: "50vh", fontSize: "14px" }}
          />
        </Box>
      </Box>

      <Dialog
        open={appointmentDetailsModal}
        onClose={() => closeAppointmentDetailsModal()}
        scroll="body"
        aria-labelledby="times-avaliable-title"
        aria-describedby="times-avaliable-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          id="times-avaliable-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Detalhes do Atendimento</h3>
          </div>
          <div>
            <IconButton onClick={() => closeAppointmentDetailsModal()}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            {Object.keys(appointmentSelected).length > 0 && (
              <Grid item xs={12} md={12} sx={{ padding: "10px" }}>
                <Box sx={{ display: "flex", justifyContent: "center", padding: "10px", backgroundColor: warningColor[appointmentSelected.status] }}>
                  <Typography variant="h6">{appointmentSelected.status}</Typography>
                </Box>
                <Typography>{`ID: ${appointmentSelected.id}`}</Typography>
                <Typography>{`Usuário: ${appointmentSelected?.user?.name || "---"}`}</Typography>
                <Typography>{`E-mail: ${appointmentSelected?.user?.email || "---"}`}</Typography>
                <Typography>{`Telefone: ${appointmentSelected?.user?.cellphone ? normalizeCellPhone(appointmentSelected.user.cellphone.substr(2)) : "---"}`}</Typography>
                {appointmentSelected?.schedule && (
                  <>
                    <Typography>{`Profissional: ${appointmentSelected.professional.name}`}</Typography>
                    <Typography>{`Atendimento: ${dayjs.tz(appointmentSelected.date_start, "America/Sao_Paulo").format("DD/MM/YYYY [às] HH:mm")}`}</Typography>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={uploadModal}
        onClose={closeUploadModal}
        maxWidth="md"
        fullWidth
        sx={{ zIndex: 20000 }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Upload de Arquivo</h3>
          </div>
          <div>
            <IconButton onClick={closeUploadModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {alertComponent.message !== "" && (
            <Box sx={{ padding: "10px" }}>
              <MKAlert color={alertComponent.type}>
                {alertComponent.message}
              </MKAlert>
            </Box>
          )}
          {Object.keys(appointmentSelected).length > 0 && (
            <Box
              sx={{
                padding: 2,
                backgroundColor: "#FFEDE9",
                borderRadius: "10px",
              }}
            >
              <Typography variant="body1" fontWeight="700">
                CONFIRA OS DADOS DO ATENDIMENTO:
              </Typography>
              <Typography variant="body2">
                NOME DO PACIENTE: {appointmentSelected?.user?.name || "---"}
              </Typography>
              <Typography variant="body2">
                NOME DO PROFISSIONAL: {appointmentSelected?.professional?.name || "---"}
              </Typography>
              <Typography variant="body2">
                HORA DO ATENDIMENTO:{" "}
                {dayjs(appointmentSelected.date_start)
                  .add(3, "hours")
                  .tz("America/Sao_Paulo")
                  .format("DD/MM/YYYY [às] HH:mm")}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
            <FormControl>
              <FormLabel>INFORME O TIPO DO ARQUIVO:</FormLabel>
              <RadioGroup
                value={fileType}
                onChange={(e) => setFileType(e.target.value)}
              >
                <FormControlLabel
                  value="Receita Médica"
                  control={<Radio />}
                  label="Receita Médica"
                  sx={{ height: "20px" }}
                />
                <FormControlLabel
                  value="Atestado/Laudo"
                  control={<Radio />}
                  label="Atestado/Laudo"
                  sx={{ height: "20px" }}
                />
                <FormControlLabel
                  value="Relatório Médico"
                  control={<Radio />}
                  label="Relatório Médico"
                  sx={{ height: "20px" }}
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio />}
                  label="Outro Tipo"
                  sx={{ height: "20px" }}
                />
              </RadioGroup>
              {fileType === "custom" && (
                <TextField
                  onChange={(e) => setFileTypeCustom(e.target.value)}
                  helperText="Informe o tipo do arquivo"
                  hel
                  sx={{
                    height: "40px",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                />
              )}
            </FormControl>
            <TextField
              type="file"
              id="file"
              helperText="Selecione o arquivo que deseja enviar"
              onChange={(e) => setFile(e.target.files[0])}
              sx={{ marginBottom: "20px", marginTop: "20px" }}
            />
            <Button
              variant="contained"
              color="success"
              onClick={() => uploadNewFile()}
            >
              Enviar Arquivo
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AdminQuickServices;
