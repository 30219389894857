/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-plusplus */
import React, { useState, useContext, useEffect, useRef } from "react";
import routes from "routes";
import { scroller, Element } from "react-scroll";
import { Link, useHistory, useParams } from "react-router-dom";
import { ScheduleMeeting } from "react-schedule-meeting";
import { Context } from "Context/AuthContext";
import { ptBR } from "date-fns/locale";

import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Switch,
  AppBar,
  Tabs,
  Tab,
  Rating,
  Alert,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Backdrop,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";

import Menu from "components/Menu";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import LogoMPO from "assets/images/logo_horizontal.png";

import * as jose from "jose";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { api } from "../../lib/axios";
import ProfessionalTestimonials from "./sections/ProfessionalTestimonials";

const RQEValidationIds = [
  "ded1b02d-037c-4a61-927f-2c6872ecf6ac",
  "c59ece59-f335-4752-b4c2-da8217870e43",
];

function ProfessionalDetails() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.locale("pt-br");

  const history = useHistory();

  if (!history.location.state?.from || !history.location.state?.serviceId) {
    history.replace("/", true);
  }
  const fromHealthPlanPage = history.location.state.from === "/healthplan";
  const { serviceId } = history.location.state;

  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [userInfoComplete, setUserInfoComplete] = useState(false);
  const [routeIndex, setRouteIndex] = useState("public");

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const { professionalId } = useParams();
  const { handleLogin } = useContext(Context);

  const [warninAddressModal, setWarninAddressModal] = useState(false);

  const [paymentBtnStatus, setPaymentBtnStatus] = useState(true);
  const [errorUserMessage, setErrorUserMessage] = useState(false);
  const [checked, setChecked] = useState(false);
  const [timeSelected, setTimeSelected] = useState();
  const [timeSelectedForBuy, setTimeSelectedForBuy] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("register");
  const [valorTotal, setValorTotal] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [couponValue, setCouponValue] = useState(null);
  const [alertCoupon, setAlertCoupon] = useState({
    message: null,
    type: null,
  });

  const [hideNotifyAddress, setHideNotifyAddress] = useState(false);

  const [rememberMe, setRememberMe] = useState(true);
  const [userLogin, setUserLogin] = useState();
  const [passwordLogin, setPasswordLogin] = useState();
  const [showPasswordLogin, setShowPasswordLogin] = useState(false);

  const [validCPF, setValidCPF] = useState();

  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userPhone, setUserPhone] = useState();
  const [userCPF, setUserCPF] = useState();
  const [userPassword, setUserPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [userPassword2, setUserPassword2] = useState();
  const [showPassword2, setShowPassword2] = useState(false);
  const [professional, setProfessional] = useState({});
  const [availableTimeslots, setAvailableTimeslots] = useState([]);

  const [showFullText, setShowFullText] = useState(false);

  const [professionalService, setProfessionalService] = useState({});

  const bottomRef = useRef(null);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleShowPasswordLogin = () =>
    setShowPasswordLogin(!showPasswordLogin);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowPassword2 = () => setShowPassword2(!showPassword2);

  const socialLoginState = process.env.REACT_APP_SOCIAL_LOGIN === "true";

  const handleWarningAdressModal = async () => {
    setLoading(true);
    try {
      if (hideNotifyAddress) {
        await api.put(`users/${authenticatedUser.id}`, {
          notify_address: false,
        });
      }
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message ||
          "Erro ao desativar a mensagem de ausência de endereço",
        type: "error",
      });
    }
    setWarninAddressModal(false);
    setLoading(false);
  };
  const handleCPF = (cpfInput) => {
    const cpfFormated = cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setUserCPF(cpfFormated);
  };

  const handleCellPhone = (cellPhoneInput) => {
    const cellPhoneFormated = cellPhoneInput
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
    setUserPhone(cellPhoneFormated);
  };

  const isValidCPF = (numbersCPF) => {
    if (numbersCPF.length !== 11) {
      return false;
    }
    if (/^(\d)\1{10}$/.test(numbersCPF)) {
      return false;
    }

    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(numbersCPF[9], 10)) {
      return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(numbersCPF[10], 10)) {
      return false;
    }
    return true;
  };

  const validateUserInfo = async (user) => {
    setLoading(true);
    try {
      const response = await api.get(`users/${user.id}`);
      const userInfo = response.data;
      if (
        !userInfo.name ||
        userInfo.name === "" ||
        !userInfo.cpf ||
        userInfo.cpf.length < 11 ||
        !userInfo.cellphone ||
        userInfo.cellphone.length < 13
      ) {
        if (userInfo?.email) {
          setUserEmail(userInfo.email);
        }
        if (userInfo?.name) {
          setUserName(userInfo.name);
        }
        if (userInfo?.cellphone && userInfo.cellphone.length === 13) {
          handleCellPhone(userInfo.cellphone.substring(2, 13));
        }
        if (userInfo?.cpf) {
          handleCPF(userInfo.cpf);
        }
        setUserInfoComplete(false);
      } else {
        setUserCPF(userInfo.cpf);
        if (!userPhone) {
          setUserPhone(userInfo.cellphone);
        }
        if (!userInfo.zip && userInfo.notify_address) {
          setWarninAddressModal(true);
        }
        setUserInfoComplete(true);
      }
      const cpfValidation = isValidCPF(userInfo.cpf);
      setValidCPF(cpfValidation);
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message || "Erro ao carregar profissional",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getProfessional = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/professionals/${professionalId}/services/${serviceId}`,
      );
      if (!response?.data) {
        history.replace("/prefessional-unavaliable");
      }
      const professionalSelected = response.data;
      setProfessional(professionalSelected);
      setAvailableTimeslots(professionalSelected.timeAvailable);
      setProfessionalService(professionalSelected.service);
      setLoading(false);
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message || "Erro ao carregar profissional",
        type: "error",
      });
    }
    setLoading(false);
    return null;
  };

  const registerUser = async () => {
    if (userPassword !== userPassword2) {
      setAlertComponent({
        message: "As senhas não conferem",
        type: "error",
      });
      return;
    }

    const userCPFNormalized = userCPF.replace(/\D/g, "");
    if (!isValidCPF(userCPFNormalized)) {
      setAlertComponent({
        message: "CPF inválido",
        type: "error",
      });
      setLoading(false);
      return;
    }

    const userCellNormalized = `55${userPhone.replace(/\D/g, "")}`;
    if (
      userCPFNormalized.length !== 11 ||
      userCellNormalized.length !== 13 ||
      userName === "" ||
      userPassword === "" ||
      userEmail === ""
    ) {
      setAlertComponent({
        message: "Verifique os dados informados",
        type: "error",
      });
      setLoading(false);
      return;
    }

    if (!userEmail.includes("@") || !userEmail.includes(".")) {
      setAlertComponent({
        message: "E-mail inválido",
        type: "error",
      });
      setLoading(false);
      return;
    }
    setLoading(true);
    setErrorUserMessage(false);
    try {
      const response = await api.post("users", {
        name: userName,
        cellphone: userCellNormalized,
        email: userEmail,
        password: userPassword,
        cpf: userCPFNormalized,
      });

      if (response.status === 201 && response.data) {
        const token = response.data;
        localStorage.setItem("token", JSON.stringify(token));

        const newUser = jose.decodeJwt(token).user;
        if (newUser.type === 1) {
          newUser.type = "admin";
        } else if (newUser.type === 3) {
          newUser.type = "professional";
        } else if (newUser.type === 4) {
          newUser.type = "supervisor";
        } else {
          newUser.type = "user";
        }

        localStorage.setItem("user", JSON.stringify(newUser));
        api.defaults.headers.Authorization = `Bearer ${token}`;
        const userInfos = JSON.parse(localStorage.getItem("user"));
        setAuthenticatedUser(userInfos);
        validateUserInfo(userInfos);
      } else {
        setErrorUserMessage(true);
      }
    } catch (err) {
      setAlertComponent({
        message: err?.response?.data?.message || "Erro ao cadastrar usuário",
        type: "error",
      });
    }
    setLoading(false);
  };

  const updateUser = async () => {
    setLoading(true);
    setErrorUserMessage(false);
    try {
      let userCell;
      let uCPF;
      if (userPhone) {
        userCell = `55${userPhone.replace(/\D/g, "")}`;
      }
      if (userCPF) {
        uCPF = userCPF.replace(/\D/g, "");
      }
      if (uCPF?.length !== 11 || userCell?.length !== 13) {
        setAlertComponent({
          message: "Verifique os dados informados",
          type: "error",
        });
      } else {
        const response = await api.put(`users/${authenticatedUser.id}`, {
          name: userName,
          cellphone: userCell,
          cpf: uCPF,
        });

        if (response.status === 200 && response.data) {
          const userLocalStorage = JSON.parse(localStorage.getItem("user"));
          if (userLocalStorage.phone === "") {
            userLocalStorage.phone = userCell;
          }
          setAuthenticatedUser(userLocalStorage);
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(userLocalStorage));
          validateUserInfo(userLocalStorage);
        } else {
          setErrorUserMessage(true);
        }
      }
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message || "Erro na atualização do usuário.",
        type: "error",
      });
    }
    setLoading(false);
  };
  const loginUser = async () => {
    setLoading(true);
    setErrorUserMessage(false);
    if (!localStorage.getItem("user")) {
      await handleLogin({
        mailOrCPF: userLogin,
        password: passwordLogin,
        rememberMe,
      });
      if (localStorage.getItem("user")) {
        const userInfos = JSON.parse(localStorage.getItem("user"));
        setAuthenticatedUser(userInfos);
        validateUserInfo(userInfos);
      } else {
        setErrorUserMessage(true);
      }
    } else {
      const userInfos = JSON.parse(localStorage.getItem("user"));
      setAuthenticatedUser(userInfos);
      validateUserInfo(userInfos);
    }
    setLoading(false);
  };

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  const handleChecked = () => setChecked(!checked);

  const updateTimeSelected = (e) => {
    const stringDateTime = e.startTime
      .toString()
      .replace(/GMT[-+]\d{4}/, "GMT-0300")
      .replace(/\(.+?\)/, "(Horário de Brasília)");
    const timeFixed = dayjs(stringDateTime).tz("America/Sao_Paulo");
    const isoTime = dayjs(stringDateTime).toISOString();
    setTimeSelectedForBuy(isoTime);
    setTimeSelected(timeFixed);
  };

  const validateCoupom = async () => {
    setLoading(true);
    try {
      const couponSelected = await api.get("coupons/validate", {
        params: {
          code: coupon,
          user_id: authenticatedUser.id,
          professional_id: professional.id,
          service_id: serviceId,
        },
      });
      setCouponValue(couponSelected.data.value);
    } catch (err) {
      setAlertCoupon({
        message: err?.response?.data?.message || "Erro ao validar cupom",
        type: "error",
      });
      setCoupon("");
      setCouponValue(null);
    }
    setLoading(false);
  };

  const proccessAndGoPayment = async () => {
    setPaymentBtnStatus(true);
    setLoading(true);
    try {
      const appointmentInfos = {
        user_id: authenticatedUser.id,
        professional_id: professionalId,
        date_start: timeSelectedForBuy,
        service_id: serviceId,
        coupon,
        websiteRequest: true,
      };
      const response = await api.post("/schedules", appointmentInfos);

      if (response.status === 201) {
        history.push(`/payment/${response.data.id}`);
      }
    } catch (err) {
      setAlertComponent({
        message: err?.response?.data?.message || "Erro ao processar pagamento",
        type: "error",
      });
    }
    setPaymentBtnStatus(false);
    setLoading(false);
  };

  useEffect(() => {
    if (couponValue) {
      const priceFloat = parseFloat(professionalService.price) || 0;
      const couponFloat = parseFloat(couponValue) || 0;
      if (priceFloat <= couponFloat) {
        setValorTotal(0);
        if (fromHealthPlanPage) {
          setPaymentBtnStatus(false);
        }
      } else {
        const total = Math.max(priceFloat - couponFloat, 0);
        setValorTotal(total);
      }
    }
  }, [couponValue]);

  useEffect(() => {
    if (authenticatedUser !== null) {
      setRouteIndex(authenticatedUser.type);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    async function getInfos() {
      if (
        authenticatedUser === null &&
        localStorage.getItem("user") &&
        routeIndex === "public"
      ) {
        setAuthenticatedUser(JSON.parse(localStorage.getItem("user")));
        validateUserInfo(JSON.parse(localStorage.getItem("user")));
      }

      if (fromHealthPlanPage && !localStorage.getItem("user")) {
        history.push("/login");
      }

      if (!fromHealthPlanPage) {
        setPaymentBtnStatus(false);
      }

      if (Object.keys(professional).length === 0) {
        await getProfessional();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (timeSelected && timeSelected !== "") {
      scroller.scrollTo("scroll-element", {
        duration: 100,
        delay: 0,
      });
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [timeSelected]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (alertCoupon.message !== "") {
      setTimeout(() => {
        setAlertCoupon({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertCoupon]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Grid
        container
        pt="15vh"
        pb="2vh"
        width="100%"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {Object.keys(professional).length > 0 &&
          Object.keys(professionalService).length > 0 && (
            <Grid
              item
              xs={11}
              md={8}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <MKBox sx={{ mx: "5vh" }}>
                <MKBox
                  component="img"
                  src={professional.photo}
                  alt={professional.name}
                  width="100%"
                  borderRadius="md"
                />
              </MKBox>
              <Rating
                name="read-only"
                value={professional.rating ?? 0}
                readOnly
              />
              <MKBox width="100%">
                <MKTypography variant="h5" textAlign="center">
                  {professional.name}
                </MKTypography>
                <MKTypography variant="h6" textAlign="center">
                  {professionalService.group_name}
                </MKTypography>
                <MKTypography variant="body2" color="text" textAlign="center">
                  {professional.resume}{" "}
                  {RQEValidationIds.includes(professionalService.id) &&
                  !professional.resume.includes("RQE")
                    ? "(NÃO Especialista)"
                    : ""}
                </MKTypography>
                <MKBox lineHeight={1}>
                  {professional.curriculum.length < 200 && (
                    <MKTypography
                      style={{
                        fontSize: 12,
                        textAlign: "center",
                        width: "100%",
                        paddingTop: 5,
                      }}
                    >
                      {professional.curriculum}
                    </MKTypography>
                  )}

                  {professional.curriculum.length > 200 && showFullText && (
                    <MKTypography
                      style={{
                        fontSize: 12,
                        textAlign: "justify",
                        width: "100%",
                        paddingTop: 5,
                      }}
                    >
                      {professional.curriculum}
                    </MKTypography>
                  )}
                  {professional.curriculum.length > 200 && !showFullText && (
                    <MKTypography
                      style={{
                        fontSize: 12,
                        textAlign: "center",
                        width: "100%",
                        paddingTop: 5,
                      }}
                    >
                      {professional.curriculum.substring(0, 200)}...
                    </MKTypography>
                  )}

                  {professional.curriculum.length > 200 && !showFullText && (
                    <MKButton
                      onClick={() => setShowFullText(true)}
                      color="light"
                      width="auto"
                      sx={{ paddingX: 3, textAlign: "center" }}
                    >
                      Expandir Currículo
                    </MKButton>
                  )}
                  {professional.curriculum.length > 200 && showFullText && (
                    <MKButton
                      onClick={() => setShowFullText(false)}
                      color="light"
                      width="auto"
                      sx={{ paddingX: 3, textAlign: "center" }}
                    >
                      Ocultar Currículo
                    </MKButton>
                  )}
                </MKBox>
                {!fromHealthPlanPage && (
                  <MKTypography variant="body1" color="text" textAlign="center">
                    Valor: R$ {professionalService.price},00
                  </MKTypography>
                )}
                <MKTypography variant="caption" textAlign="center">
                  Tempo de atendimento: até{" "}
                  {professionalService?.duration !== 0
                    ? professionalService.duration
                    : 60}{" "}
                  minutos
                </MKTypography>
              </MKBox>
            </Grid>
          )}
        {Object.keys(professional).length > 0 && (
          <Grid item xs={11} md={8} textAlign="center">
            <MKBox width="100%">
              <MKTypography
                variant="body1"
                sx={{ textAlign: "center", fontWeight: "400" }}
              >
                Fuso horário de Brasília
              </MKTypography>
              <ScheduleMeeting
                borderRadius={10}
                primaryColor="#3f5b85"
                eventDurationInMinutes={
                  professionalService?.duration !== 0
                    ? professionalService.duration
                    : 60
                }
                availableTimeslots={availableTimeslots}
                onStartTimeSelect={updateTimeSelected}
                // Language props
                locale={ptBR}
                lang_cancelButtonText="Cancelar"
                lang_confirmButtonText="Confirmar"
                lang_emptyListText="Nenhum horário disponível"
                lang_goToNextAvailableDayText="Próximo Dia"
                lang_noFutureTimesText="Nenhum horário futuro disponível"
                // Date format props
                format_locale={ptBR}
                format_nextFutureStartTimeAvailableFormatString="dd/MM/yyyy"
                format_selectedDateDayTitleFormatString="dd/MM/yyyy"
                format_selectedDateMonthTitleFormatString="MMMM yyyy"
                format_startTimeFormatString="HH:mm"
              />
            </MKBox>
            <MKBox sx={{ textAlign: "center", marginBottom: 1 }}>
              <MKTypography variant="body2" color="text">
                Não temos retorno em nossas consultas
              </MKTypography>
            </MKBox>
          </Grid>
        )}
        {Object.keys(professional).length > 0 &&
          authenticatedUser !== null &&
          !validCPF && (
            <Grid item xs={11} md={7}>
              <MKAlert color="error">
                CPF inválido! Atualize-no em Perfil, na área do usuário antes de
                prosseguir.
              </MKAlert>
            </Grid>
          )}
        {alertComponent.message && (
          <Grid item xs={11} md={12} sx={{ textAlign: "center", m: 2 }}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}
        {timeSelected && (
          <Grid item xs={11} md={12}>
            <MKBox
              component="section"
              variant="gradient"
              bgColor="info"
              sx={{ py: 1 }}
            >
              <MKTypography variant="body2" textAlign="center" color="white">
                Horário Escolhido:{" "}
              </MKTypography>
              <MKTypography variant="body2" textAlign="center" color="white">
                {`${timeSelected.format(
                  "dddd DD [de] MMMM [de] YYYY [às] HH:mm[h] [(Horário de Brasília)]",
                )}`}
                ;
              </MKTypography>
            </MKBox>
          </Grid>
        )}
        {timeSelected && authenticatedUser === null && socialLoginState && (
          <Grid item xs={11} md={12}>
            <MKBox width={{ sx: "100%", md: "60%" }}>
              <MKTypography variant="body2" textAlign="center">
                Acesso fácil com Facebook ou Google:
              </MKTypography>
              <MKBox display="flex" justifyContent="center">
                <MKButton variant="outlined" color="light" sx={{ m: 2 }}>
                  <svg
                    width="48px"
                    height="48px"
                    viewBox="0 0 64 64"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        transform="translate(3.000000, 3.000000)"
                        fillRule="nonzero"
                      >
                        <circle
                          fill="#3C5A9A"
                          cx="29.5091719"
                          cy="29.4927506"
                          r="29.4882047"
                        />
                        <path
                          d="M39.0974944,9.05587273 L32.5651312,9.05587273 C28.6886088,9.05587273 24.3768224,10.6862851 24.3768224,16.3054653 C24.395747,18.2634019 24.3768224,20.1385313 24.3768224,22.2488655 L19.8922122,22.2488655 L19.8922122,29.3852113 L24.5156022,29.3852113 L24.5156022,49.9295284 L33.0113092,49.9295284 L33.0113092,29.2496356 L38.6187742,29.2496356 L39.1261316,22.2288395 L32.8649196,22.2288395 C32.8649196,22.2288395 32.8789377,19.1056932 32.8649196,18.1987181 C32.8649196,15.9781412 35.1755132,16.1053059 35.3144932,16.1053059 C36.4140178,16.1053059 38.5518876,16.1085101 39.1006986,16.1053059 L39.1006986,9.05587273 L39.0974944,9.05587273 L39.0974944,9.05587273 Z"
                          fill="#FFFFFF"
                        />
                      </g>
                    </g>
                  </svg>
                </MKButton>
                <MKButton variant="outlined" color="light" sx={{ m: 2 }}>
                  <svg
                    width="48px"
                    height="48px"
                    viewBox="0 0 64 64"
                    version="1.1"
                  >
                    {" "}
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        transform="translate(3.000000, 2.000000)"
                        fillRule="nonzero"
                      >
                        <path
                          d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                          fill="#4285F4"
                        />
                        <path
                          d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                          fill="#34A853"
                        />
                        <path
                          d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                          fill="#FBBC05"
                        />
                        <path
                          d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                          fill="#EB4335"
                        />
                      </g>
                    </g>
                  </svg>
                </MKButton>
              </MKBox>
              <MKTypography variant="body2" textAlign="center">
                ou utilize os formulários
              </MKTypography>
            </MKBox>
          </Grid>
        )}
        {timeSelected && authenticatedUser === null && !socialLoginState && (
          <Grid
            item
            xs={11}
            md={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <MKBox width={{ xs: "90%", md: "60%" }}>
              <AppBar position="static" sx={{ my: 2 }}>
                <Tabs value={activeTab} onChange={handleTabType}>
                  <Tab
                    id="register"
                    label={
                      <MKTypography variant="button">CADASTRO</MKTypography>
                    }
                    sx={{ my: 1 }}
                  />
                  <Tab
                    id="login"
                    label={
                      <MKTypography variant="button">
                        JÁ POSSUO CADASTRO
                      </MKTypography>
                    }
                    sx={{ my: 1 }}
                  />
                </Tabs>
              </AppBar>
            </MKBox>
          </Grid>
        )}
        {errorUserMessage && (
          <Stack sx={{ width: "100%" }}>
            <Alert variant="filled" severity="error">
              Dados inválidos. Tente novamente!
            </Alert>
          </Stack>
        )}
        {timeSelected &&
          authenticatedUser === null &&
          tabType === "register" && (
            <Grid
              item
              xs={11}
              md={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid container spacing={1} width={{ xs: "90%", md: "60%" }}>
                <Grid item xs={12}>
                  <MKInput
                    name="userName"
                    label="Nome Completo"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    name="userEmail"
                    type="email"
                    label="E-mail"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MKInput
                    name="userPhone"
                    type="phone"
                    label="Celular"
                    placeholder="(99) 99999-9999"
                    value={userPhone}
                    onChange={(e) => handleCellPhone(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <MKInput
                    name="userCPF"
                    label="CPF"
                    value={userCPF}
                    placeholder="999.999.999-99"
                    onChange={(e) => handleCPF(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <MKInput
                    name="userPassword"
                    type={showPassword ? "text" : "password"}
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    label="Senha"
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MKInput
                    name="userPassword2"
                    type={showPassword2 ? "text" : "password"}
                    label="Confirme a senha"
                    value={userPassword2}
                    onChange={(e) => setUserPassword2(e.target.value)}
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword2}
                            edge="end"
                          >
                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={() => registerUser()}
                  >
                    REGISTRAR
                  </MKButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        {timeSelected && authenticatedUser === null && tabType === "login" && (
          <Grid
            item
            xs={11}
            md={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <MKBox width={{ xs: "90%", md: "60%" }}>
              <MKInput
                name="userLogin"
                label="Email ou CPF"
                onChange={(e) => setUserLogin(e.target.value)}
                fullWidth
                required
                sx={{ my: 0.7 }}
              />

              <MKInput
                name="passwordlogin"
                type={showPasswordLogin ? "text" : "password"}
                onChange={(e) => setPasswordLogin(e.target.value)}
                label="Senha"
                fullWidth
                required
                sx={{ my: 0.7 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPasswordLogin}
                        edge="end"
                      >
                        {showPasswordLogin ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <MKBox display="flex" alignItems="center">
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  &nbsp;&nbsp;Lembre-se de mim!
                </MKTypography>
              </MKBox>

              <MKButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => loginUser()}
              >
                FAZER LOGIN
              </MKButton>

              <MKBox textAlign="center">
                <MKTypography variant="button" color="text">
                  Esqueceu sua senha?{" "}
                  <MKTypography
                    component={Link}
                    to="/forgot-password"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Recuperar senha!
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
        )}
        {timeSelected && authenticatedUser && userInfoComplete && validCPF && (
          <Grid
            item
            xs={11}
            md={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <MKBox width={{ xs: "90%", md: "60%" }}>
              <MKTypography variant="body1" textAlign="center">
                Dados do Cliente
              </MKTypography>
              <MKTypography variant="body2">
                <strong>Nome:</strong> {authenticatedUser.name}
              </MKTypography>
              <MKTypography variant="body2">
                <strong>E-mail:</strong> {authenticatedUser.email}
              </MKTypography>
              <MKTypography variant="body2">
                <strong>Celular:</strong>{" "}
                {authenticatedUser?.phone &&
                  authenticatedUser.phone.length === 13 &&
                  authenticatedUser.phone
                    .substring(2)
                    .replace(/\D/g, "")
                    .replace(/(\d{2})(\d)/, "($1) $2")
                    .replace(/(\d{5})(\d)/, "$1-$2")
                    .replace(/(-\d{4})\d+?$/, "$1")}
              </MKTypography>
              <Element name="scroll-element">
                <MKBox textAlign="center">
                  <Switch checked={checked} onChange={handleChecked} />
                  <MKTypography
                    variant="caption"
                    sx={{ cursor: "pointer", userSelect: "none" }}
                    onClick={handleChecked}
                  >
                    {checked
                      ? "VOCÊ ACEITOU A POLÍTICA DE PRIVACIDADE E OS TERMOS DE USO"
                      : "TOQUE PARA ACEITAR A POLÍTICA DE PRIVACIDADE E OS TERMOS DE USO"}
                  </MKTypography>
                  {!checked && (
                    <MKBox sx={{ textAlign: "center" }}>
                      <MKTypography variant="caption" color="red">
                        Você PRECISA concordar com a Política de Privacidade e
                        Termos de Uso para CONTINUAR
                      </MKTypography>
                      <MKButton
                        onClick={() => history.push("/privacy-politic")}
                        color="info"
                        style={{ height: "20px" }}
                        fullWidth
                      >
                        <MKTypography variant="button" color="white">
                          Visualizar a Política de Privacidade e Termos de Uso
                        </MKTypography>
                      </MKButton>
                    </MKBox>
                  )}
                </MKBox>
              </Element>
            </MKBox>
          </Grid>
        )}
        {timeSelected &&
          authenticatedUser &&
          !userInfoComplete &&
          !checked &&
          validCPF && (
            <>
              <MKTypography variant="body1" sx={{ padding: 2 }}>
                Você só pode agendar atendimentos se o cadastro estiver
                completo. Informe os dados restantes por favor!
              </MKTypography>
              <Grid
                item
                xs={11}
                md={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <MKBox width={{ xs: "90%", md: "60%" }}>
                  <Tooltip title="Insira o nome completo (campo obrigatório)">
                    <MKInput
                      name="userName"
                      label="Nome Completo"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      fullWidth
                      sx={{ my: 0.7 }}
                    />
                  </Tooltip>

                  <Tooltip title="Insira o celular (campo obrigatório)">
                    <MKInput
                      name="userPhone"
                      type="phone"
                      label="Celular"
                      placeholder="(99) 99999-9999"
                      value={userPhone}
                      onChange={(e) => handleCellPhone(e.target.value)}
                      fullWidth
                      required
                      sx={{ my: 0.7 }}
                    />
                  </Tooltip>

                  <Tooltip title="Insira o CPF (campo obrigatório)">
                    <MKInput
                      name="userCPF"
                      label="CPF"
                      value={userCPF}
                      placeholder="999.999.999-99"
                      onChange={(e) => handleCPF(e.target.value)}
                      fullWidth
                      required
                      sx={{ my: 0.7 }}
                    />
                  </Tooltip>

                  <MKButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={() => updateUser()}
                    sx={{ my: 1 }}
                  >
                    ATUALIZAR
                  </MKButton>
                </MKBox>
              </Grid>
            </>
          )}
        {timeSelected &&
          authenticatedUser &&
          userInfoComplete &&
          checked &&
          validCPF && (
            <Grid
              item
              xs={11}
              md={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <MKBox width={{ xs: "90%", md: "60%" }}>
                <MKButton
                  onClick={() => proccessAndGoPayment()}
                  variant="gradient"
                  color="info"
                  sx={{ height: "50px", mt: 2, with: "100%" }}
                  disabled={paymentBtnStatus}
                  fullWidth
                >
                  {loading ? "PROCESSANDO..." : "AGENDAR ATENDIMENTO"}
                </MKButton>
                {fromHealthPlanPage && !couponValue && (
                  <Typography variant="body2" textAlign="center">
                    Você precisa inserir um cupom em agendamentos para planos de
                    saúde!
                  </Typography>
                )}

                {!couponValue ? (
                  <>
                    <MKTypography
                      variant="body2"
                      textAlign="center"
                      marginY={2}
                    >
                      Você possui um cupom? Insira-o abaixo
                    </MKTypography>
                    {alertCoupon.message && (
                      <Grid
                        item
                        xs={11}
                        md={12}
                        sx={{ textAlign: "center", m: 2 }}
                      >
                        <MKAlert color={alertCoupon.type}>
                          {alertCoupon.message}
                        </MKAlert>
                      </Grid>
                    )}
                    <MKBox
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <MKInput
                        variant="outlined"
                        name="coupon"
                        label="CÓDIGO DO CUPOM"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        fullWidth
                        sx={{ paddingX: 1 }}
                      />
                      <MKButton
                        onClick={() => validateCoupom()}
                        color="secondary"
                        disabled={!!loading}
                        width="auto"
                        sx={{ paddingX: 3, textAlign: "center" }}
                      >
                        {loading ? "PROCESSANDO..." : "VALIDAR"}
                      </MKButton>
                    </MKBox>
                  </>
                ) : (
                  <MKTypography variant="body1" textAlign="center">
                    CUPOM VALIDADO!{" "}
                    {!fromHealthPlanPage &&
                      `Você pagará o valor de R$ ${valorTotal},00`}
                  </MKTypography>
                )}
              </MKBox>
            </Grid>
          )}
      </Grid>
      <div ref={bottomRef} />
      <ProfessionalTestimonials
        professionalId={professionalId}
        serviceId={serviceId}
      />
      <Dialog
        open={warninAddressModal}
        onClose={() => setWarninAddressModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography variant="body1">ATENÇÃO</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "40px" }}>
          <Typography variant="body2">
            Você não informou seu endereço no cadastro. Se desejar a emissão da
            nota fiscal com seu endereço atualize antes de prosseguir com o
            agendamento.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
              alignContent: "center",
              paddingY: "10px",
            }}
          >
            <Switch
              checked={hideNotifyAddress}
              onChange={() => setHideNotifyAddress(!hideNotifyAddress)}
            />
            <Typography variant="body2" sx={{ paddingX: "10px" }}>
              Não exibir mais essa notificação.
            </Typography>
          </Box>
          {hideNotifyAddress && (
            <Typography sx={{ color: "red" }} variant="body2">
              <b>ATENÇÃO:</b> Você não verá mais essa notificação no futuro.
            </Typography>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: "40px",
            paddingY: "10px",
          }}
        >
          <Button
            onClick={() => history.push(`/user-area`)}
            style={{ backgroundColor: "#00B2BC", color: "white" }}
          >
            ATUALIZAR ENDEREÇO
          </Button>
          <Button
            onClick={handleWarningAdressModal}
            style={{ backgroundColor: "#ff8080", color: "white" }}
          >
            CONTINUAR SEM ATUALIZAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProfessionalDetails;
