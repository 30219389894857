import React, { useEffect, useState } from "react";
import {
  Alert,
  Backdrop,
  Box,
  ButtonGroup,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";

import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function EletronicMedicalRecord(params) {
  const {
    createTemplate,
    deleteTemplate,
    documentsModels,
    saveMedicalRecord,
    uploadFile,
    appointmentInfos,
  } = params;

  const [loading, setLoading] = useState(false);
  const [alertInfos, setAlertInfos] = useState({
    type: "",
    message: "",
  });
  const [alertModalInfos, setAlertModalInfos] = useState({
    type: "",
    message: "",
  });

  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileTypeCustom, setFileTypeCustom] = useState("");

  const [newTextModelModal, setNewTextModelModal] = useState(false);
  const [newTextModel, setNewTextModel] = useState("");
  const [newTitleModel, setNewTitleModel] = useState("");
  const [newDescriptionModel, setNewDescriptionModel] = useState("");

  const [toggleWindow, setToggleWindow] = useState(false);
  const [documentText, setDocumentText] = useState("");

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleDrawer = () => {
    setToggleWindow(!toggleWindow);
  };

  const clearDocumentInput = () => {
    setNewTextModel("");
    setNewTitleModel("");
    setNewDescriptionModel("");
  };

  const handleOpenTextModal = () => {
    clearDocumentInput();
    setNewTextModelModal(true);
  };

  const handleCloseNewTextModal = () => {
    setNewTextModelModal(false);
    clearDocumentInput();
  };

  const handleTitleInput = (e) => {
    const slicedTitle = e.target.value.slice(0, 15);
    setNewTitleModel(slicedTitle);
  };

  const handleDescriptionInput = (e) => {
    const slicedDescription = e.target.value.slice(0, 30);
    setNewDescriptionModel(slicedDescription);
  };

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
    ],
  };

  const formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const uploadNewFile = async () => {
    if (!file) {
      setAlertModalInfos({
        type: "error",
        message: "Selecione o arquivo que deseja enviar",
      });
      return;
    }

    if (!fileType) {
      setAlertModalInfos({
        type: "error",
        message: "Selecione o tipo do arquivo",
      });
      return;
    }

    if (fileType === "custom" && fileTypeCustom === "") {
      setAlertModalInfos({
        type: "error",
        message: "Informe o tipo do arquivo no campo de texto",
      });
      return;
    }

    let title = "";
    if (fileType === "custom") {
      title = fileTypeCustom;
    } else {
      title = fileType;
    }

    setLoading(true);
    try {
      uploadFile(file, title);
      setFile(null);
      setUploadedFiles([...uploadedFiles, title]);
      setUploadFileModal(false);
      setAlertInfos({
        type: "success",
        message: "Arquivo enviado com sucesso",
      });
    } catch (error) {
      setAlertModalInfos({
        type: "error",
        message: "Erro ao salvar arquivo",
      });
    }
    setLoading(false);
  };

  const saveNewTemplate = async () => {
    if (newTitleModel === "") {
      setAlertModalInfos({
        type: "error",
        message: "Título do modelo é obrigatório",
      });
      return;
    }

    if (newDescriptionModel === "") {
      setAlertModalInfos({
        type: "error",
        message: "Descrição do modelo é obrigatório",
      });
      return;
    }

    if (newDescriptionModel === "") {
      setAlertModalInfos({
        type: "error",
        message: "Insira informações no modelo para salvá-lo",
      });
      return;
    }
    setLoading(true);
    try {
      createTemplate(newTitleModel, newDescriptionModel, newTextModel);
      handleCloseNewTextModal();
      setAlertModalInfos({
        type: "success",
        message: "Modelo de prontuário salvo com sucesso",
      });
    } catch (error) {
      setAlertModalInfos({
        type: "error",
        message: "Erro ao salvar modelo de prontuário",
      });
    }
    setLoading(false);
  };

  const removeTemplate = async (id) => {
    setLoading(true);
    try {
      deleteTemplate(id);
      setAlertInfos({
        type: "success",
        message: "Modelo de prontuário deletado com sucesso",
      });
    } catch (error) {
      setAlertInfos({
        type: "error",
        message: "Erro ao deletar modelo de prontuário",
      });
    }
    setLoading(false);
  };

  const submitMedicalRecord = async () => {
    setLoading(true);
    try {
      await saveMedicalRecord(documentText);
      setAlertInfos({
        type: "success",
        message: "Prontuário salvo com sucesso",
      });
    } catch (error) {
      setAlertInfos({
        type: "error",
        message: "Erro ao salvar prontuário",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (documentText !== "" || uploadedFiles.length > 0) {
      const localInfos =
        JSON.parse(sessionStorage.getItem("attendanceInfos")) || {};
      localInfos.documentText = documentText;
      localInfos.uploadedFiles = uploadedFiles;
      sessionStorage.setItem("attendanceInfos", JSON.stringify(localInfos));
    }
  }, [documentText, uploadedFiles]);

  useEffect(() => {
    if (sessionStorage.getItem("attendanceInfos")) {
      const localInfos = JSON.parse(sessionStorage.getItem("attendanceInfos"));
      setDocumentText(localInfos.documentText);
      setUploadedFiles(localInfos.uploadedFiles);
    } else {
      setDocumentText(appointmentInfos?.resume || "");
      setUploadedFiles(appointmentInfos?.files || []);
    }
  }, []);

  useEffect(() => {
    if (alertInfos.message !== "") {
      setTimeout(() => {
        setAlertInfos({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertInfos]);

  useEffect(() => {
    if (alertModalInfos.message !== "") {
      setTimeout(() => {
        setAlertModalInfos({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertModalInfos]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          position: "fixed",
          right: `${toggleWindow ? window.innerWidth / 2 : 0}px`,
          backgroundColor: "#FF785B70",
          width: "50px",
          bottom: `${window.innerHeight / 2 - 100}px`,
          borderRadius: "10px 0px 0px 10px",
          zIndex: 20000,
          cursor: "pointer",
        }}
        onClick={() => handleDrawer()}
      >
        <Box sx={{ position: "relative", height: "200px" }}>
          <Typography
            sx={{
              transform: "rotate(-90deg)",
              position: "absolute",
              transformOrigin: "center left",
              width: "250px",
              top: 180,
              left: 20,
              fontWeight: "bold",
              fontSize: "14px",
              zIndex: 30000,
            }}
            variant="caption"
          >
            Prontuário Eletrônico
          </Typography>
        </Box>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </Box>

      <Drawer anchor="right" open={toggleWindow} onClose={handleDrawer}>
        <Box
          style={{
            width: `${window.innerWidth - window.innerWidth / 2}px`,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Prontuário Eletrônico
          </Typography>
          <Box sx={{ backgroundColor: "#f5f5f5" }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              Modelos Pré-Definidos
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ textAlign: "center", width: "100%" }}
            >
              Utilize os modelos pré-definidos abaixo para agilizar o
              atendimento
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              {documentsModels?.length === 0 && (
                <Typography
                  variant="subtitle2"
                  sx={{ textAlign: "center", width: "100%" }}
                >
                  Nenhum modelo de documento cadastrado ainda. Crie o seu
                  próprio modelo de prontuário para agilizar o atendimento.
                </Typography>
              )}
              {documentsModels?.length > 0 &&
                documentsModels.map((document) => (
                  <ButtonGroup
                    key={document.id}
                    sx={{ margin: "3px", width: "240px" }}
                  >
                    <Button
                      onClick={() => setDocumentText(document.content)}
                      variant="contained"
                      sx={{
                        backgroundColor: "#cecece",
                        borderColor: "#cecece",
                        flexDirection: "column",
                        height: "40px",
                        width: "100%",
                        "&:hover": {
                          backgroundColor: "#cecece",
                          borderColor: "#cecece",
                        },
                        "&:current": {
                          backgroundColor: "#FFD8D0",
                          borderColor: "#FFD8D0",
                        },
                      }}
                    >
                      <Box>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: "bold", fontSize: 10 }}
                        >
                          {document.title}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: 8 }}>
                          {document.description}
                        </Typography>
                      </Box>
                    </Button>
                    {document.professionalCpf && (
                      <Button
                        onClick={() => removeTemplate(document.id)}
                        variant="contained"
                        sx={{
                          backgroundColor: "red",
                          borderColor: "red",
                          width: "30px",
                        }}
                      >
                        <DeleteForeverIcon color="white" />
                      </Button>
                    )}
                  </ButtonGroup>
                ))}
            </Box>
          </Box>

          {alertInfos.message !== "" && (
            <Alert
              severity={alertInfos.type}
              sx={{ marginX: "20px", marginY: "10px" }}
            >
              {alertInfos.message}
            </Alert>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => submitMedicalRecord()}
              sx={{ backgroundColor: "green" }}
            >
              <Typography
                variant="body2"
                fontWeight={700}
                sx={{ color: "#ffffff" }}
              >
                Salvar Prontuário
              </Typography>
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => setUploadFileModal(true)}
            >
              Upload de Arquivo
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleOpenTextModal()}
            >
              Criar Novo Modelo
            </Button>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <Typography variant="body2" fontWeight={700} color="red">
              ATENÇÃO: SALVE O PRONTUÁRIO SEMPRE QUE FIZER QUALQUER ALTERAÇÃO
            </Typography>
          </Box>
          {uploadedFiles.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Typography variant="body2">Arquivos enviados:</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {uploadedFiles.map((fileName, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      backgroundColor: "#cecece",
                      padding: "2px",
                      borderRadius: "5px",
                      margin: "2px",
                    }}
                    key={`file${toString(index)}`}
                  >
                    <Typography variant="caption" sx={{ margin: "5px" }}>
                      {fileName}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <ReactQuill
            id="medicalRecordInput"
            theme="snow"
            modules={modules}
            formats={formats}
            placeholder="Insira o texto aqui..."
            value={documentText}
            onChange={(content) => setDocumentText(content)}
            style={{ height: `${window.innerHeight - 400}px`, padding: "10px" }}
          />
        </Box>
      </Drawer>

      <Dialog
        open={newTextModelModal}
        onClose={handleCloseNewTextModal}
        maxWidth="xl"
        fullWidth
        sx={{ zIndex: 20000 }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Novo modelo de prontuário</Typography>
          <IconButton onClick={() => handleCloseNewTextModal()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {alertModalInfos.message !== "" && (
            <Box sx={{ padding: "10px" }}>
              <Alert severity={alertModalInfos.type}>
                {alertModalInfos.message}
              </Alert>
            </Box>
          )}
          <Box sx={{ display: "flex", padding: "10px" }}>
            <TextField
              id="title"
              label="Título"
              value={newTitleModel}
              onChange={handleTitleInput}
              sx={{ width: "100%", margin: "10px" }}
              helperText="Máximo de 15 caracteres"
            />
            <TextField
              id="description"
              label="Descrição"
              value={newDescriptionModel}
              onChange={handleDescriptionInput}
              sx={{ width: "100%", margin: "10px" }}
              helperText="Máximo de 30 caracteres"
            />
          </Box>
          <Box sx={{ paddingBottom: "50px" }}>
            <ReactQuill
              id="inputTextModel"
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="Insira o texto aqui..."
              onChange={(value) => setNewTextModel(value)}
              style={{ height: "400px", margin: "0px 20px" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={() => saveNewTemplate()}
          >
            Salvar Modelo
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={uploadFileModal}
        onClose={() => setUploadFileModal(false)}
        maxWidth="sm"
        fullWidth
        sx={{ zIndex: 20000 }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Upload de Arquivo</h3>
          </div>
          <div>
            <IconButton onClick={() => setUploadFileModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {alertModalInfos.message !== "" && (
            <Box sx={{ padding: "10px" }}>
              <Alert severity={alertModalInfos.type}>
                {alertModalInfos.message}
              </Alert>
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl>
              <FormLabel>Tipo de Arquivo:</FormLabel>
              <RadioGroup
                value={fileType}
                onChange={(e) => setFileType(e.target.value)}
              >
                <FormControlLabel
                  value="Receita Médica"
                  control={<Radio />}
                  label="Receita Médica"
                  sx={{ height: "20px" }}
                />
                <FormControlLabel
                  value="Solicitação de Exame(s)"
                  control={<Radio />}
                  label="Solicitação de Exame(s)"
                  sx={{ height: "20px" }}
                />
                <FormControlLabel
                  value="Atestado/Laudo"
                  control={<Radio />}
                  label="Atestado/Laudo"
                  sx={{ height: "20px" }}
                />
                <FormControlLabel
                  value="Relatório Médico"
                  control={<Radio />}
                  label="Relatório Médico"
                  sx={{ height: "20px" }}
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio />}
                  label="Outro Tipo"
                  sx={{ height: "20px" }}
                />
              </RadioGroup>
              {fileType === "custom" && (
                <TextField
                  onChange={(e) => setFileTypeCustom(e.target.value)}
                  helperText="Informe o tipo do arquivo"
                  hel
                  sx={{
                    height: "40px",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                />
              )}
            </FormControl>
            <TextField
              type="file"
              id="file"
              helperText="Selecione o arquivo que deseja enviar"
              onChange={(e) => setFile(e.target.files[0])}
              sx={{ marginBottom: "20px", marginTop: "20px" }}
            />
            <Button
              variant="contained"
              color="success"
              onClick={() => uploadNewFile()}
            >
              Enviar Arquivo
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EletronicMedicalRecord;
