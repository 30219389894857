/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function CustomCheckbox({ data, preSelectedValues, onSelectionChange }) {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (preSelectedValues) {
      setSelected(preSelectedValues);
    }
  }, [preSelectedValues]);

  const handleChange = (event) => {
    const { checked, value } = event.target;
    let updatedSelections;

    if (checked) {
      updatedSelections = [...selected, value];
    } else {
      updatedSelections = selected.filter((item) => item !== value);
    }

    setSelected(updatedSelections);
    onSelectionChange(updatedSelections);
  };

  return (
    <>
      {data.map((item) => (
        <FormControlLabel
          key={item.id}
          control={
            <Checkbox
              color="primary"
              value={item.id}
              onChange={handleChange}
              checked={selected.includes(item.id)}
            />
          }
          label={item.name}
        />
      ))}
    </>
  );
}

export default CustomCheckbox;
