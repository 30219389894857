/* eslint-disable no-alert */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import routes from "routes";
import CopyToClipboard from "react-copy-to-clipboard";

import Card from "react-credit-cards-2";
// import "react-credit-cards-2/es/styles-compiled.css";

import {
  Card as CardMUI,
  AppBar,
  Tabs,
  Tab,
  Grid,
  Container,
  Divider,
  Button,
} from "@mui/material";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";

// images
import LogoMPO from "assets/images/logo_horizontal.png";

// Importação de exemplo
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import packagesDB from "../../Data/packagesMock.json";

function PackagePayment() {
  const { id } = useParams();
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";
  if (!authenticatedUser) {
    window.location.replace("/login");
  }

  const [packageService, setPackageService] = useState();

  const [activeTab, setActiveTab] = useState(0);

  const [tabType, setTabType] = useState("pix");

  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [isFrontOfCardVisible, setIsFrontOfCardVisible] = useState(true);
  const [pixNumber, setPixNumber] = useState();
  const [pixQR, setPixQR] = useState();
  const [copied, setCopied] = React.useState(false);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  function toggleCardFlip(e) {
    e.preventDefault();
    setIsFrontOfCardVisible(!isFrontOfCardVisible);
  }

  function handleSubmit(e) {
    e.preventDefault();
    alert("Processo de pagamento inciado...");
    // TODO: Lógica para pagamento via cartão
  }

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  useEffect(() => {
    // TODO: busca de pacote pelo ID
    setPackageService(
      packagesDB.filter((packageDB) => packageDB.id === +id)[0],
    );
    // TODO: Gerar PIX para pagamento e avaliar a entrega da imagem do QR Code
    setPixNumber("2187409453428795638971");
    setPixQR("wa_andre.png");
  });

  return (
    <>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox
          component="section"
          position="relative"
          py={20}
          width="100%"
          height="100vh"
        >
          <Grid container alignItems="top">
            <Grid item xs={12} lg={12} py={3} pr={{ xl: 1, lg: 0 }}>
              <CardMUI>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MKTypography
                    variant="h2"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Pagamento
                  </MKTypography>
                </MKBox>
                {packageService && (
                  <Grid container p={2}>
                    <Grid item xs={12} lg={5} py={2}>
                      <MKBox mx={2} mb={1} height="auto">
                        <MKTypography variant="h3" py={2}>
                          Dados do Pacote:
                        </MKTypography>
                        <MKBox width="100%" py={1}>
                          <MKTypography variant="body1">
                            <b>Pacote:</b> {packageService.name}
                          </MKTypography>
                          <MKTypography variant="body1">
                            <b>Descrição:</b> {packageService.resume}
                          </MKTypography>
                          <MKTypography variant="body1">
                            <b>Valor:</b> R${" "}
                            {Number(packageService.price)
                              .toFixed(2)
                              .replace(".", ",")}
                          </MKTypography>
                          <MKTypography
                            variant="body1"
                            py={2}
                            sx={{ textAlign: "justify" }}
                          >
                            Após a confirmação do pagamento, você receberá um
                            e-mail com o código de CUPOM para utilizar em seus
                            agendamentos. Basta inserir esse código no local
                            solocitado para o cupom de desconto no momento
                            agendamento. Se precisar de ajuda é só nos
                            contactar!
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={7}
                      py={2}
                      textAlign="center"
                      bgcolor="#f0f0f0"
                      borderRadius={2}
                    >
                      <MKBox mx={2} mb={1} height="auto">
                        <MKTypography variant="h3" py={2}>
                          Forma de Pagamento
                        </MKTypography>
                        <Grid container>
                          <Grid
                            item
                            xs={7}
                            md={6}
                            lg={4}
                            sx={{ mx: "auto", textAlign: "center" }}
                          >
                            <AppBar position="static">
                              <Tabs value={activeTab} onChange={handleTabType}>
                                <Tab
                                  id="pix"
                                  label={
                                    <MKBox py={0.5} px={2} color="inherit">
                                      PIX
                                    </MKBox>
                                  }
                                />
                                <Tab
                                  id="card"
                                  label={
                                    <MKBox py={0.5} px={2} color="inherit">
                                      Cartão
                                    </MKBox>
                                  }
                                />
                              </Tabs>
                            </AppBar>
                          </Grid>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={8}
                              sx={{ mx: "auto", textAlign: "center" }}
                            >
                              {tabType === "pix" ? (
                                <Grid>
                                  <CopyToClipboard
                                    onCopy={onCopy}
                                    text={pixNumber}
                                  >
                                    <MKBox
                                      component="img"
                                      src={require(
                                        `../../assets/images/${pixQR}`,
                                      )}
                                      alt="WA_ANDRE"
                                      width="100%"
                                      p={2}
                                    />
                                  </CopyToClipboard>
                                  <CopyToClipboard
                                    onCopy={onCopy}
                                    text={pixNumber}
                                  >
                                    <Button>
                                      <b>Código: </b> {pixNumber}
                                    </Button>
                                  </CopyToClipboard>
                                  {copied ? (
                                    <MKTypography variant="body2">
                                      Código copiado!
                                    </MKTypography>
                                  ) : (
                                    <MKTypography variant="body2">
                                      Toque no código para copiá-lo!
                                    </MKTypography>
                                  )}
                                </Grid>
                              ) : (
                                <Grid>
                                  <MKTypography variant="h5" py={2}>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        sx={{ mx: "auto", textAlign: "center" }}
                                        onClick={(e) => toggleCardFlip(e)}
                                      >
                                        <Card
                                          cvc={cvc || ""}
                                          expiry={expiry || ""}
                                          name={name || ""}
                                          number={cardNumber || ""}
                                          focused={
                                            isFrontOfCardVisible
                                              ? "number"
                                              : "cvc"
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        sx={{ mx: "auto", textAlign: "center" }}
                                      >
                                        <MKTypography
                                          variant="h4"
                                          textAlign="center"
                                          sx={{ pt: 5 }}
                                        >
                                          <Grid
                                            container
                                            spacing={3}
                                            paddingX={1}
                                          >
                                            <Grid item xs={12} md={12}>
                                              <MKInput
                                                name="name"
                                                label="Nome"
                                                value={name}
                                                onChange={(e) =>
                                                  setName(e.target.value)
                                                }
                                                onFocus={() =>
                                                  setIsFrontOfCardVisible(true)
                                                }
                                                fullWidth
                                                required
                                              />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                              <MKInput
                                                name="cardNumber"
                                                label="Número"
                                                value={cardNumber}
                                                onChange={(e) =>
                                                  setCardNumber(
                                                    e.target.value.substring(
                                                      0,
                                                      16,
                                                    ),
                                                  )
                                                }
                                                onFocus={() =>
                                                  setIsFrontOfCardVisible(true)
                                                }
                                                fullWidth
                                                required
                                              />
                                            </Grid>
                                            <Grid item xs={8} md={8}>
                                              <MKInput
                                                name="expiry"
                                                label="Validade"
                                                value={expiry}
                                                placeholder="MM/YY"
                                                onChange={(e) =>
                                                  setExpiry(
                                                    e.target.value.substring(
                                                      0,
                                                      5,
                                                    ),
                                                  )
                                                }
                                                onFocus={() =>
                                                  setIsFrontOfCardVisible(true)
                                                }
                                                fullWidth
                                                required
                                              />
                                            </Grid>
                                            <Grid item xs={4} md={4}>
                                              <MKInput
                                                name="cvc"
                                                label="CVC"
                                                value={cvc}
                                                placeholder="CVC"
                                                onChange={(e) =>
                                                  setCvc(
                                                    e.target.value.substring(
                                                      0,
                                                      4,
                                                    ),
                                                  )
                                                }
                                                onFocus={() =>
                                                  setIsFrontOfCardVisible(false)
                                                }
                                                fullWidth
                                                required
                                              />
                                            </Grid>
                                            <Divider />
                                            <Grid item xs={12} md={12}>
                                              <MKButton
                                                type="submit"
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                onClick={(e) => handleSubmit(e)}
                                              >
                                                PAGAR
                                              </MKButton>
                                            </Grid>
                                          </Grid>
                                        </MKTypography>
                                      </Grid>
                                    </Grid>
                                  </MKTypography>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </MKBox>
                    </Grid>
                  </Grid>
                )}
              </CardMUI>
            </Grid>
          </Grid>
        </MKBox>
      </Container>
    </>
  );
}

export default PackagePayment;
