import { useContext, useEffect, useState } from "react";
import LogoMPO from "assets/images/logo_horizontal.png";
import routes from "routes";

import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import Menu from "components/Menu";
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Context } from "Context/AuthContext";
import { api } from "../../lib/axios";

function DeleteMyInfos() {
  const { handleLogin, handleLogout, getAuthenticatedUser } =
    useContext(Context);

  const [authenticatedUser, setAuthenticatedUser] = useState({});

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);
  const [mailOrCPF, setMailOrCPF] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const removeInfos = async () => {
    await handleLogout();
    setLoading(true);
    if (!mailOrCPF || !password) {
      setAlertComponent({
        message: "Por favor, preencha todos os campos.",
        type: "error",
      });
      setLoading(false);
      return;
    }
    await handleLogin({ mailOrCPF, password, RememberMe: false });
    const userAuthenticated = await getAuthenticatedUser();
    if (!userAuthenticated) {
      setAlertComponent({
        message: "Informações de login incorretas!",
        type: "error",
      });
      setLoading(false);
      return;
    }
    setAuthenticatedUser(userAuthenticated);
    setLoading(false);
  };

  const deleteInfos = async () => {
    if (mailOrCPF.includes("@") && mailOrCPF !== authenticatedUser.email) {
      setAlertComponent({
        message: "E-mail não corresponde ao cadastrado.",
        type: "error",
      });
      return;
    }
    if (
      !mailOrCPF.includes("@") &&
      mailOrCPF.replace(/\D/g, "") !== authenticatedUser.cpf
    ) {
      setAlertComponent({
        message: "CPF não corresponde ao cadastrado.",
        type: "error",
      });
      return;
    }
    setLoading(true);
    try {
      await api.delete(`/users/${authenticatedUser.id}`);
      setMailOrCPF("");
      setPassword("");
      await handleLogout();
      alert("Dados removidos com sucesso.");
    } catch (err) {
      setAlertComponent({
        message: err?.response?.data?.message || "Erro na remoção dos dados.",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Object.keys(authenticatedUser).length > 0) {
      deleteInfos();
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes.user} sticky />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          paddingTop: "180px",
        }}
      >
        <Card sx={{ minWidth: "50%", maxWidth: "90%" }}>
          <MKBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MKTypography variant="body1" color="white" my={1}>
              REMOVER DADOS
            </MKTypography>
            <MKTypography variant="body2" color="white" my={1}>
              Solicite a remoção dos seus dados.
            </MKTypography>
          </MKBox>
          <MKBox pt={4} pb={3} px={3}>
            {alertComponent.message && (
              <Grid item xs={12}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </Grid>
            )}
            <MKBox component="form" role="form">
              <MKBox mb={2}>
                <MKInput
                  type="text"
                  label="E-mail ou CPF"
                  name="mailOrCPF"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={mailOrCPF}
                  onChange={(e) => setMailOrCPF(e.target.value)}
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  type={showPassword ? "text" : "password"}
                  label="Senha"
                  name="password"
                  fullWidth
                  placeholder="************"
                  InputLabelProps={{ shrink: true }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MKBox>
              <MKBox mt={6} mb={1}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={() => removeInfos()}
                  fullWidth
                  disabled={!mailOrCPF}
                >
                  Solicitar remover meus dados pessoais
                </MKButton>
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
      </Container>
    </>
  );
}

export default DeleteMyInfos;
