/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import "./styles.css";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

const root = document.getElementById("root");

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  root
);
