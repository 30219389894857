import React, { useState, useContext, useEffect } from "react";
import { Context } from "Context/AuthContext";
import { useHistory } from "react-router-dom";
import * as jose from "jose";
import routes from "routes";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";

import MKBox from "components/MKBox";

import Menu from "components/Menu";

import LogoMPO from "assets/images/logo_horizontal.png";
import { api } from "../../lib/axios";

const SERVICEID = "81e983be-13af-4153-8fa6-49fe858c1103";

function ProfessionalQuickServices() {
  const { handleLogout } = useContext(Context);
  const history = useHistory();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  if (!authenticatedUser) {
    return window.location.replace("/login");
  }
  const routeIndex = authenticatedUser.type ?? "public";

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const [appointment, setAppointment] = useState(null);
  const [patientsWaiting, setPatientsWaiting] = useState(0);

  const getProfessional = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/professionals/${authenticatedUser.professional.id}`,
      );
      if (!response.data.quickService) {
        handleLogout();
        history.push("/login");
      }
    } catch (err) {
      setAlertComponent({
        message:
          err.response?.data?.message ||
          "Não foi possível carregar os dados do profissional.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const getNextAppointment = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `quick-service/next?serviceId=${SERVICEID}`,
      );
      setAlertComponent({
        message: "Próximo atendimento iniciado com sucesso!",
        type: "success",
      });
      setAppointment(data);
    } catch (error) {
      setAlertComponent({
        message:
          error.response?.data?.message ||
          "Erro na busca do próximo atendimento",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const getPatientsWaiting = async () => {
    try {
      const { data } = await api.get(
        `quick-service/check-queue-quantity?serviceId=${SERVICEID}`,
      );
      setPatientsWaiting(data?.quantity || 0);
    } catch (error) {
      setPatientsWaiting(0);
    }
  };

  const finalizeQuickService = async () => {
    const timeLimitToFinished = dayjs(appointment.date_start)
      .add(3, "hours")
      .add(15, "minutes");
    const timeNow = dayjs().tz("America/Sao_Paulo");
    if (timeNow.isBefore(timeLimitToFinished)) {
      setAlertComponent({
        message: "Atendimento não pode ser finalizado com menos 15 minutos.",
        type: "error",
      });
      return;
    }
    setLoading(true);
    try {
      setAppointment(null);
      await api.put(`/schedules/${appointment.schedule.id}/finalize`);
      setAlertComponent({
        message: "Atendimento finalizado com sucesso!",
        type: "success",
      });
      await getPatientsWaiting();
    } catch (error) {
      setAlertComponent({
        message:
          error.response?.data?.message || "Erro ao finalizar atendimento",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getInfos() {
      const token = localStorage.getItem("token");
      if (!token) {
        handleLogout();
        return history.push("/login");
      }
      const decodedToken = jose.decodeJwt(localStorage.getItem("token"));
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (
        authenticatedUser.type !== "professional" ||
        decodedToken.exp <= currentTimestamp
      ) {
        handleLogout();
        return history.push("/login");
      }
      await getProfessional();
      await getPatientsWaiting();
      const interval = setInterval(async () => {
        getPatientsWaiting();
      }, 30000);
      return () => clearInterval(interval);
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <Card style={{ paddingTop: "150px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              margin: "50px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <MKBox
                component="img"
                src="/images/doctorInCellphone.png"
                alt="Profissional"
                width="100%"
                borderRadius="md"
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ margin: { xs: "20px", md: "40px" }, textAlign: "center" }}
              >
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Atendimento Profissional
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundColor: "#efefef",
                  margin: "20px",
                  padding: { xs: "20px", md: "40px" },
                  borderRadius: "20px",
                  border: "1px solid #aaaaaa",
                  textAlign: "center",
                }}
              >
                {!appointment && (
                  <>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                      <b>
                        {patientsWaiting} paciente(s) aguarda(m) atendimento.
                      </b>
                    </Typography>
                    {patientsWaiting > 0 && (
                      <>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Solicite inciar o próximo atendimento clicando no
                          botão abaixo. Será gerada a sala de atendimento e o
                          link enviado ao paciente.
                        </Typography>
                        <Button
                          variant="contained"
                          color="info"
                          sx={{ width: "100%" }}
                          onClick={getNextAppointment}
                        >
                          Iniciar Próximo Atendimento
                        </Button>
                      </>
                    )}
                  </>
                )}
                {appointment && (
                  <>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      <b>Dados do próximo atendimento:</b>
                    </Typography>
                    <Typography variant="body1">
                      Paciente: {appointment.user.name}
                    </Typography>
                    <Typography variant="body1">
                      Telefone: {appointment.user.phone}
                    </Typography>
                    <Typography variant="body1">
                      E-mail: {appointment.user.email}
                    </Typography>
                    <Typography variant="body1">
                      Data de Nascimento:{" "}
                      {appointment.user?.birthDate
                        ? dayjs
                            .tz(appointment.user.birthDate, "America/Sao_Paulo")
                            .format("DD/MM/YYYY")
                        : "Não informado"}
                    </Typography>
                    <Button
                      variant="contained"
                      color="info"
                      sx={{ width: "100%", marginY: "10px" }}
                      onClick={() =>
                        window.open(
                          `${window.location.origin}/meeting-room/${appointment.schedule_id}`,
                          "_blank",
                          "noopener,noreferrer",
                        )
                      }
                    >
                      Acessar Sala de Atendimento
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ width: "100%", marginY: "2px" }}
                      onClick={() => finalizeQuickService()}
                    >
                      Finalizar Atendimento
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Card>
      </Container>
    </>
  );
}

export default ProfessionalQuickServices;
