/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import routes from "routes";

import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Rating,
  Typography,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";

import LogoMPO from "assets/images/logo_horizontal.png";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import MKButton from "../../components/MKButton";
import MKInput from "../../components/MKInput";
import MKBadge from "../../components/MKBadge";
import MKAlert from "../../components/MKAlert";

import { api } from "../../lib/axios";

function AppointmentDetails() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const [ratingValue, setRatingValue] = useState(null);
  const [rated, setRated] = useState(false);
  const [testimony, setTestimony] = useState("");
  const [timeLeft, setTimeLeft] = useState("await");
  const [professionalName, setProfessionalName] = useState("");
  const [professionalId, setProfessionalId] = useState("");
  const [professionalRating, setProfessionalRating] = useState(0);
  const [professionalServiceName, setProfessionalServiceName] = useState("");
  const [professionalResume, setProfessionalResume] = useState("");
  const [schedulePrice, setSchedulePrice] = useState(0);
  const [scheduleStatus, setScheduleStatus] = useState("");
  const [scheduleID, setScheduleID] = useState("");
  const [userID, setUserID] = useState("");

  const [dateTimeAppointment, setDateTimeAppointment] = useState("");

  const { id } = useParams();

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;

  const routeIndex = authenticatedUser.type ?? "public";

  const getSchedules = async () => {
    try {
      const response = await api.get(`/schedules/${id}`);

      setDateTimeAppointment(
        dayjs.tz(response.data.date_start, "America/Sao_Paulo"),
      );
      setProfessionalName(response.data.professional.name);
      setProfessionalId(response.data.professional.id);
      setProfessionalRating(response.data.professional.rating);
      setProfessionalServiceName(response.data.service.name);
      setProfessionalResume(response.data.professional.resume);
      setSchedulePrice(response.data.price);
      setScheduleStatus(response.data.status);
      setScheduleID(response.data.id);
      setUserID(response.data.user_id);
      if (response.data.rated) {
        setRatingValue(response.data.rated);
        setRated(true);
      }
    } catch (err) {
      setAlertComponent({
        message: err.data.message,
        type: "error",
      });
    }
  };

  const calculateTimeLeft = () => {
    const difference = dateTimeAppointment.diff(
      dayjs().tz("America/Sao_Paulo"),
    );
    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return false;
  };

  useEffect(() => {
    if (dateTimeAppointment) {
      setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
    }
  }, [timeLeft, dateTimeAppointment]);

  const ratingProfessional = async () => {
    setLoading(true);
    try {
      if (ratingValue) {
        await api.put(`/schedules/evaluate/${scheduleID}`, {
          star_evaluation: ratingValue,
          text_evaluation: testimony,
        });
        setRated(true);
      } else {
        setAlertComponent({
          message: "Faça a avaliação do atendimento antes de prosseguir.",
          type: "error",
        });
      }
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    async function getInfos() {
      if (!authenticatedUser) {
        window.location.replace("/login");
      }

      if (!userID) {
        await getSchedules();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (scheduleStatus === "AGENDADO") {
      setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
    }
  }, [scheduleStatus]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        {alertComponent.message && (
          <Grid item xs={12}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}
        <MKBox
          component="section"
          position="relative"
          py={20}
          width="100%"
          height="100vh"
        >
          <Grid container alignItems="top">
            <Grid item xs={12} lg={12} py={3}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MKTypography
                    variant="body1"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Informações do Agendamento
                  </MKTypography>
                </MKBox>
                {professionalName && (
                  <Grid container textAlign="center">
                    <Grid item xs={12} lg={6} py={1}>
                      <MKBox
                        mx={2}
                        mb={1}
                        height="auto"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <MKTypography variant="body1" textAlign="center" pb={3}>
                          Detalhes do Profissional
                        </MKTypography>
                        <MKBox
                          src={`https://storage.googleapis.com/mpo_images/professionals/photos/${professionalId}.jpeg`}
                          component="img"
                          alt={professionalName}
                          width="50%"
                          borderRadius="md"
                          shadow="lg"
                        />
                        <MKBox>
                          <Rating
                            name="read-only"
                            value={professionalRating ?? 0}
                            readOnly
                            sx={{ paddingTop: 2 }}
                          />
                        </MKBox>
                        <MKTypography textAlign="center">
                          {professionalName}
                        </MKTypography>
                        <MKTypography variant="button" textAlign="center">
                          {professionalServiceName}
                        </MKTypography>
                        <MKTypography
                          variant="button"
                          color="text"
                          textAlign="center"
                        >
                          {professionalResume}
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      py={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ paddingX: { lg: 3, md: 1 } }}
                    >
                      <MKBox mx={2} mb={1} height="auto">
                        <MKBox component="section" py={{ xs: 2, md: 5 }}>
                          <MKTypography variant="body2" textAlign="center">
                            Data do atendimento:
                          </MKTypography>
                          <MKTypography variant="body2" textAlign="center">
                            {dateTimeAppointment
                              .locale("pt-br")
                              .format("dddd DD/MM/YYYY [às] HH:mm")}
                            {" Horário de Brasília"}
                          </MKTypography>
                          <MKTypography
                            variant="body2"
                            px={3}
                            textAlign="center"
                          >
                            Valor: R$ {schedulePrice},00{" "}
                            {scheduleStatus === "PROCESSANDO" ? (
                              <MKBadge
                                variant="contained"
                                badgeContent="PENDENTE"
                                color="error"
                                size="xs"
                                container
                              />
                            ) : (
                              <MKBadge
                                variant="contained"
                                badgeContent="PAGO"
                                color="success"
                                size="xs"
                                container
                              />
                            )}
                          </MKTypography>
                          {scheduleStatus === "AVALIAÇÃO" && (
                            <Box p={1}>
                              <MKTypography variant="body2" textAlign="justify">
                                Seu atendimento foi finalizado e esperamos que
                                tudo tenha corrido muito bem. Você já sabe, se
                                precisar, pode contar conosco!
                              </MKTypography>
                            </Box>
                          )}
                        </MKBox>
                        {scheduleStatus === "AGENDADO" && (
                          <Grid>
                            {timeLeft && timeLeft !== "await" && (
                              <MKBox
                                component="section"
                                py={{ xs: 2, md: 5 }}
                                bgColor="#f0f0f0"
                                borderRadius="10px"
                              >
                                <MKBox>
                                  <MKTypography
                                    variant="h5"
                                    px={3}
                                    textAlign="center"
                                  >
                                    Seu atendimento começará em:
                                  </MKTypography>
                                  <MKTypography
                                    variant="h4"
                                    px={3}
                                    textAlign="center"
                                    py={2}
                                  >
                                    <Grid
                                      container
                                      display="flex"
                                      justifyContent="space-around"
                                    >
                                      <MKBox>
                                        <MKTypography variant="h3">
                                          {String(timeLeft.days).padStart(
                                            2,
                                            "0",
                                          )}
                                        </MKTypography>
                                        <MKTypography variant="body2">
                                          DIAS
                                        </MKTypography>
                                      </MKBox>
                                      <MKBox>
                                        <MKTypography variant="h3">
                                          {String(timeLeft.hours).padStart(
                                            2,
                                            "0",
                                          )}
                                        </MKTypography>
                                        <MKTypography variant="body2">
                                          HORAS
                                        </MKTypography>
                                      </MKBox>
                                      <MKBox>
                                        <MKTypography variant="h3">
                                          {String(timeLeft.minutes).padStart(
                                            2,
                                            "0",
                                          )}
                                        </MKTypography>
                                        <MKTypography variant="body2">
                                          MINUTOS
                                        </MKTypography>
                                      </MKBox>
                                      <MKBox>
                                        <MKTypography variant="h3">
                                          {String(timeLeft.seconds).padStart(
                                            2,
                                            "0",
                                          )}
                                        </MKTypography>
                                        <MKTypography variant="body2">
                                          SEGUNDOS
                                        </MKTypography>
                                      </MKBox>
                                    </Grid>
                                  </MKTypography>
                                  <MKTypography
                                    variant="body2"
                                    px={3}
                                    textAlign="center"
                                  >
                                    Quando chegar a hora do seu atendimento,
                                    esta mensagem será substituída por um botão
                                    para você se encontrar com o profissional.
                                  </MKTypography>
                                </MKBox>
                              </MKBox>
                            )}
                            {!timeLeft && (
                              <MKButton
                                variant="gradient"
                                color="success"
                                // component={Link}
                                // to={`/meeting-room/${appointmentDetails.id}`}
                                onClick={() =>
                                  window.open(
                                    `${window.location.origin}/meeting-room/${scheduleID}`,
                                    "_blank",
                                    "noopener,noreferrer",
                                  )
                                }
                                size="large"
                                fullWidth
                              >
                                ACESSAR SALA DE ATENDIMENTO
                              </MKButton>
                            )}
                          </Grid>
                        )}
                        {scheduleStatus === "PROCESSANDO" && (
                          <MKBox>
                            <MKButton
                              variant="gradient"
                              color="error"
                              component={Link}
                              to={`/payment/${scheduleID}`}
                              size="large"
                              fullWidth
                            >
                              EFETUAR PAGAMENTO
                            </MKButton>
                          </MKBox>
                        )}
                      </MKBox>
                    </Grid>
                    {scheduleStatus === "AVALIAÇÃO" && rated === false && (
                      <Grid container textAlign="center">
                        <Grid item xs={12} lg={12} py={1}>
                          <MKBox mx={2} mb={1} height="auto">
                            <MKBox
                              component="section"
                              p={3}
                              bgColor="#f0f0f0"
                              borderRadius="10px"
                            >
                              <MKTypography
                                variant="body"
                                px={3}
                                textAlign="center"
                              >
                                Por favor, avalie o atendimento do profissional:
                              </MKTypography>
                              <MKBox>
                                <Rating
                                  name="rating"
                                  value={ratingValue}
                                  onChange={(_event, newValue) => {
                                    setRatingValue(newValue);
                                  }}
                                  size="large"
                                />
                              </MKBox>
                              {ratingValue && (
                                <MKBox>
                                  <MKInput
                                    label="Você poderia deixar um depoimento?"
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={testimony}
                                    onChange={(e) =>
                                      setTestimony(e.target.value)
                                    }
                                  />
                                </MKBox>
                              )}
                              {ratingValue && (
                                <MKBox py={2}>
                                  <MKButton
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    onClick={ratingProfessional}
                                  >
                                    AVALIAR
                                  </MKButton>
                                </MKBox>
                              )}
                            </MKBox>
                          </MKBox>
                        </Grid>
                      </Grid>
                    )}
                    {scheduleStatus === "FINALIZADO" && rated === true && (
                      <Grid container textAlign="center">
                        <Grid item xs={12} lg={12} py={1}>
                          <MKBox mx={2} mb={1} height="auto">
                            <MKBox
                              component="section"
                              p={3}
                              bgColor="#f0f0f0"
                              borderRadius="10px"
                            >
                              <MKTypography
                                variant="body"
                                px={3}
                                textAlign="center"
                              >
                                Você já avaliou esse atendimento. Agradecemos o
                                feedback!
                              </MKTypography>
                            </MKBox>
                          </MKBox>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
        </MKBox>
      </Container>
    </>
  );
}

export default AppointmentDetails;
