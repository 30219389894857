import React, { useEffect, useState } from "react";
import routes from "routes";

import {
  Backdrop,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import LogoMPO from "assets/images/logo_horizontal.png";

import { api } from "../../../lib/axios";

function MensagensPush() {
  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({});
  const [usersSelected, setUsersSelected] = useState({});

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await api.get("/users");
      const usersOrderedFiltered = response.data.filter(
        (user) => user.active && user.pushToken !== null,
      );
      const usersOrdered = usersOrderedFiltered.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      setUsers(usersOrdered);
    } catch (error) {
      setAlertComponent({
        message: "Erro na extração dos usuários",
        type: "error",
      });
    }
    setLoading(false);
  };

  const sendMessage = async () => {
    setLoading(true);
    if (message === "" || title === "") {
      setAlertComponent({
        message: "Preencha os campos corretamente",
        type: "error",
      });
      setLoading(false);
      return null;
    }
    if (Object.keys(usersSelected).length === 0) {
      setAlertComponent({
        message: "Selecione os usuários que receberão a mensagem",
        type: "error",
      });
      setLoading(false);
      return null;
    }
    try {
      const objectMessage = {
        title,
        message,
        usersTokens: usersSelected,
      };

      await api.post("/pushNotification", objectMessage);

      setTitle("");
      setMessage("");

      setAlertComponent({
        message: "Mensagens enviadas com sucesso!",
        type: "success",
      });
    } catch (err) {
      setAlertComponent({
        message: err?.response?.data?.message || "Erro ao enviar mensagem",
        type: "error",
      });
    }
    setLoading(false);
    return null;
  };

  const handleSelectionChange = (selectionModel) => {
    const selectedRows = selectionModel.map((selectedId) =>
      users.find((row) => row.id === selectedId),
    );
    const selectedIds = selectedRows.map((row) => row.pushToken);
    setUsersSelected(selectedIds);
  };

  const getTypeName = (params) => {
    const { type } = params.row;
    if (type === 1) {
      return <span>Administrador</span>;
    }
    if (type === 2) {
      return <span>Usuário</span>;
    }
    return <span>Profissional</span>;
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1.5,
      headerClassName: "data-grid-header",
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
      headerClassName: "data-grid-header",
    },
    {
      field: "cellphone",
      headerName: "Telefone",
      flex: 0.5,
      headerClassName: "data-grid-header",
    },
    {
      field: "type",
      headerName: "Tipo",
      flex: 0.5,
      headerClassName: "data-grid-header",
      renderCell: getTypeName,
    },
  ];

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      if (Object.keys(users).length === 0) {
        await getUsers();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>

      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />

      <Grid
        item
        xs={12}
        lg={12}
        pt={20}
        px={25}
        style={{ height: "900px", width: "100%" }}
      >
        <Typography
          variant="h4"
          style={{ textAlign: "center", padding: "20px" }}
        >
          Envio de mensagens Push
        </Typography>
        {alertComponent.message !== "" && (
          <Grid item xs={12}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}
        <TextField
          id="title"
          label="Título"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{
            width: "100%",
            marginBottom: "10px",
            borderRadius: "10px",
            backgroundColor: "white",
            fontSize: "1.2rem",
          }}
        />
        <TextareaAutosize
          placeholder="Mensagem"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "10px",
            height: "200px",
            fontSize: "1.2rem",
          }}
        />
        <MKButton
          variant="gradient"
          color="info"
          onClick={sendMessage}
          fullWidth
        >
          Enviar Mensagem Push
        </MKButton>
        <Divider style={{ margin: "20px 0" }} />
        {Object.keys(users).length > 0 && (
          <DataGrid
            rows={users}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            onRowSelectionModelChange={handleSelectionChange}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </Grid>
    </>
  );
}

export default MensagensPush;
