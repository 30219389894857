import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import {
  Avatar,
  Box,
  Divider,
  Icon,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { api } from "../../../lib/axios";

function Testimonials() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const servicesForDisplay = JSON.parse(
    process.env.REACT_APP_SERVICES_EVALUATION_DISPLAY,
  );
  const [testimonials, setTestimonials] = useState([]);
  const getTestimonials = async () => {
    const dataParams = {
      services: servicesForDisplay,
      interval: process.env.REACT_APP_EVALUATION_INTERVAL,
    };
    const response = await api.get("/schedules/testimonials", {
      params: dataParams,
    });
    setTestimonials(response.data);
  };

  useEffect(() => {
    async function getInfos() {
      if (testimonials.length === 0) {
        await getTestimonials();
      }
    }
    getInfos();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "linear-gradient(195deg, #EBEFF4, #CED4DA);",
        padding: { xs: 0, md: 2 },
      }}
    >
      <Box
        sx={{
          marginX: { xs: 1, md: 2 },
          padding: { xs: 0, md: 2 },
          borderRadius: "20px",
          backgroundColor: "#ffffff",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Typography
          variant="body1"
          fontWeight="medium"
          paddingY={1}
          paddingX={5}
        >
          Avaliações feitas por nossos clientes pelo atendimento do
          profissional:
        </Typography>
        <Box
          sx={{
            marginX: 2,
            height: "70vh",
            overflowY: "scroll",
            padding: { xs: 0, md: 2 },
            backgroundColor: "#ffffff",
          }}
        >
          {testimonials.length > 0 &&
            testimonials.map((testimonial) => (
              <Box key={testimonial.id}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    alignItems: "center",
                    alignContent: "center",
                    width: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      alignContent: "center",
                      width: "100%",
                    }}
                  >
                    <Avatar
                      src={`https://storage.googleapis.com/mpo_images/professionals/photos/${testimonial.professional.id}.jpeg`}
                      alt={testimonial.professional.name}
                      sx={{
                        width: "70px",
                        height: "70px",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingX: 2,
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography variant="body2" fontWeight="bold">
                        {testimonial.professional.name}
                      </Typography>
                      <Typography variant="button">
                        {testimonial.service.name}
                      </Typography>
                      <Typography variant="caption">
                        <Icon>schedule</Icon>&nbsp;
                        {dayjs
                          .tz(testimonial.date_start, "America/Sao_Paulo")
                          .format("DD/MM/YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                      padding: 2,
                      backgroundColor: "#f3f3f3",
                      borderRadius: "10px",
                      margin: 2,
                      width: "100%",
                    }}
                  >
                    <Typography variant="button">
                      <b>Avaliado por:</b> {testimonial.user ?? "Anônimo"}
                    </Typography>
                    <Typography variant="body2">
                      &quot;{testimonial.text_evaluation}&quot;
                    </Typography>
                    <Rating
                      name="read-only"
                      value={testimonial.rated}
                      readOnly
                    />
                  </Box>
                </Box>
                <Divider />
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Testimonials;
