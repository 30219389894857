/* eslint-disable react/no-unescaped-entities */
import React from "react";
import routes from "routes";
import DefaultFooter from "components/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

import Menu from "components/Menu";

import { Card, Container } from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import LogoMPO from "assets/images/logo_horizontal.png";

function AboutUs() {
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  return (
    <>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox component="section" pt={20}>
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={1}
              mb={1}
              textAlign="center"
            >
              <MKTypography variant="body2" color="white" mt={0}>
                Sobre Nós
              </MKTypography>
              <MKTypography variant="body2" color="white" opacity={0.8}>
                conheça um pouco sobre nós
              </MKTypography>
            </MKBox>
            <MKBox pt={2} px={2} textAlign="justify">
              <MKTypography variant="body2" color="text" textAlign="justify">
                O "Meu Psiquiatra On-line" é uma plataforma voltada a atender
                clientes na área da Psiquiatria. Ela foi desenvolvida para ser
                pioneira no Brasil em suporte psiquiátrico, toda voltada para
                atendimento on-line.
              </MKTypography>
            </MKBox>
            <MKBox pt={2} px={2} textAlign="justify">
              <MKTypography variant="body2" color="text" textAlign="justify">
                Nossa empresa atua em diversas áreas da Medicina e agora lança a
                Telemedicina com os melhores profissionais do ramo da
                Psiquiatria.
              </MKTypography>
            </MKBox>
            <MKBox pt={2} px={2} textAlign="justify">
              <MKTypography variant="body2" color="text" textAlign="justify">
                Gostaríamos de desafiar os modelos apresentado até hoje e
                inaugurar um novo conceito em Psiquiatria.
              </MKTypography>
            </MKBox>
            <MKBox pt={2} px={2} textAlign="justify">
              <MKTypography variant="body2" color="text" textAlign="justify">
                Buscando conforto, comodidade e, sobretudo, estar presente em
                todo momento que você cliente precisa.
              </MKTypography>
            </MKBox>
            <MKBox pb={6} pt={2} px={2} textAlign="justify">
              <MKTypography variant="body2" color="text" textAlign="justify">
                Cuidando das pessoas, edificando vidas!
              </MKTypography>
            </MKBox>
          </Card>
        </MKBox>
        <MKBox pt={1} mt={1}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Container>
    </>
  );
}

export default AboutUs;
