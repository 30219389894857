/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";

import routes from "routes";
import { Context } from "Context/AuthContext";

import {
  Alert,
  AppBar,
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Rating,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import MKBox from "components/MKBox";
import Menu from "components/Menu";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKAvatar from "components/MKAvatar";
import MKAlert from "components/MKAlert";

import bgImage from "assets/images/bg-Questions.jpg";
import LogoMPO from "assets/images/logo_horizontal.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import * as jose from "jose";
import { api } from "../../lib/axios";

function Questions() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.locale("pt-br");

  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [routeIndex, setRouteIndex] = useState("public");

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  const { handleLogin } = useContext(Context);

  const [errorUserMessage, setErrorUserMessage] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("register");

  const [userLogin, setUserLogin] = useState();
  const [passwordLogin, setPasswordLogin] = useState();
  const [rememberMe, setRememberMe] = useState(true);
  const [showPasswordLogin, setShowPasswordLogin] = useState(false);

  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userPhone, setUserPhone] = useState();
  const [userCPF, setUserCPF] = useState();
  const [userPassword, setUserPassword] = useState();
  const [userPassword2, setUserPassword2] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [question, setQuestion] = useState();
  const [questions, setQuestions] = useState([]);

  const [myQuestions, setMyQuestions] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSetMyQuestions = () => setMyQuestions(!myQuestions);

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  const handleShowPasswordLogin = () =>
    setShowPasswordLogin(!showPasswordLogin);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowPassword2 = () => setShowPassword2(!showPassword2);

  const socialLoginState = process.env.REACT_APP_SOCIAL_LOGIN === "true";

  const handleCPF = (cpfInput) => {
    const cpfFormated = cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setUserCPF(cpfFormated);
  };

  const handleCellPhone = (cellPhoneInput) => {
    const cellPhoneFormated = cellPhoneInput
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
    setUserPhone(cellPhoneFormated);
  };

  const validateUserInfo = async (userSelected) => {
    const response = await api.get(`users/${userSelected.id}`);
    const userInfo = response.data;
    if (
      !userInfo.name ||
      userInfo.name === "" ||
      userInfo.name.length < 10 ||
      !userInfo.cpf ||
      userInfo.cpf === "" ||
      !userInfo.cellphone ||
      userInfo.cellphone === ""
    ) {
      setUserEmail(userInfo.email);
      if (userInfo?.name) {
        setUserName(userInfo.name);
      }
      if (userInfo?.cellphone) {
        handleCellPhone(userInfo.cellphone.substring(2, 13));
      }
      if (userInfo?.cpf) {
        handleCPF(userInfo.cpf);
      }
    } else {
      setUserCPF(userInfo.cpf);
      if (!userPhone) {
        setUserPhone(userInfo.cellphone);
      }
    }
  };

  const registerUser = async () => {
    setLoading(true);
    setErrorUserMessage(false);
    if (userPassword === userPassword2) {
      try {
        const userCell = `55${userPhone.replace(/\D/g, "")}`;
        const uCPF = userCPF.replace(/\D/g, "");
        if (
          uCPF.length !== 11 ||
          userCell.length !== 13 ||
          userName === "" ||
          userPassword === "" ||
          userEmail === ""
        ) {
          setAlertComponent({
            message: "Verifique os dados informados",
            type: "error",
          });
        } else {
          const response = await api.post("users", {
            name: userName,
            cellphone: userCell,
            email: userEmail,
            password: userPassword,
            cpf: uCPF,
          });

          if (response.status === 201 && response.data) {
            const token = response.data;
            localStorage.setItem("token", JSON.stringify(token));

            const newUser = jose.decodeJwt(token).user;
            if (newUser.type === 1) {
              newUser.type = "admin";
            } else if (newUser.type === 3) {
              newUser.type = "professional";
            } else if (newUser.type === 4) {
              newUser.type = "supervisor";
            } else {
              newUser.type = "user";
            }

            localStorage.setItem("user", JSON.stringify(newUser));
            api.defaults.headers.Authorization = `Bearer ${token}`;
            const userInfos = JSON.parse(localStorage.getItem("user"));
            setAuthenticatedUser(userInfos);
            validateUserInfo(userInfos);
          } else {
            setErrorUserMessage(true);
          }
        }
      } catch (err) {
        setAlertComponent({
          message: err.response?.data?.message || "Ocorreu um erro!",
          type: "error",
        });
      }
    } else {
      setErrorUserMessage(true);
    }
    setLoading(false);
  };

  const loginUser = async () => {
    setLoading(true);
    setErrorUserMessage(false);
    if (!localStorage.getItem("user")) {
      await handleLogin({
        mailOrCPF: userLogin,
        password: passwordLogin,
        rememberMe,
      });
      if (localStorage.getItem("user")) {
        const userInfos = JSON.parse(localStorage.getItem("user"));
        setAuthenticatedUser(userInfos);
        validateUserInfo(userInfos);
      } else {
        setErrorUserMessage(true);
      }
    } else {
      const userInfos = JSON.parse(localStorage.getItem("user"));
      setAuthenticatedUser(userInfos);
      validateUserInfo(userInfos);
    }
    setLoading(false);
  };

  const getQuestions = async () => {
    setLoading(true);
    try {
      const responseQuestions = await api.get("/questions");
      const ordernedQuestionsList = responseQuestions.data.filter((item) => {
        if (myQuestions) {
          return item.user_id === authenticatedUser.id;
        }
        return item.status === true;
      });
      ordernedQuestionsList.sort((a, b) =>
        dayjs(b.created_at).diff(dayjs(a.created_at)),
      );

      setQuestions(ordernedQuestionsList);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const makeQuestion = async () => {
    setLoading(true);
    try {
      const questionObj = {
        user_id: authenticatedUser.id,
        question,
      };
      const response = await api.post("/questions", questionObj);
      if (response.status === 201) {
        setAlertComponent({
          message: "Enviado com sucesso. Enviaremos a resposta em breve.",
          type: "success",
        });
        setQuestion("");
      } else {
        setAlertComponent({
          message: "Não foi possível enviar a sua pergunta.",
          type: "error",
        });
      }
      await getQuestions();
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (authenticatedUser !== null) {
      setRouteIndex(authenticatedUser.type);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    async function getInfos() {
      if (Object.keys(questions).length === 0) {
        await getQuestions();
      }
      if (
        authenticatedUser === null &&
        localStorage.getItem("user") &&
        routeIndex === "public"
      ) {
        setAuthenticatedUser(JSON.parse(localStorage.getItem("user")));
        validateUserInfo(JSON.parse(localStorage.getItem("user")));
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    getQuestions();
  }, [myQuestions]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="left" mx="auto" />
        </Container>
      </MKBox>
      <Card
        sx={{
          mx: { xs: 0, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography variant="h3" textAlign="center">
          Tire suas dúvidas
        </MKTypography>
        <Divider />
        <Container>
          {alertComponent.message !== "" && (
            <Grid item xs={12}>
              <MKAlert color={alertComponent.type}>
                {alertComponent.message}
              </MKAlert>
            </Grid>
          )}
        </Container>
        {authenticatedUser === null && socialLoginState && (
          <Grid item xs={11} md={12}>
            <MKBox width={{ sx: "100%", md: "60%" }}>
              <MKTypography variant="body2" textAlign="center">
                Acesso fácil com Facebook ou Google:
              </MKTypography>
              <MKBox display="flex" justifyContent="center">
                <MKButton variant="outlined" color="light" sx={{ m: 2 }}>
                  <svg
                    width="48px"
                    height="48px"
                    viewBox="0 0 64 64"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        transform="translate(3.000000, 3.000000)"
                        fillRule="nonzero"
                      >
                        <circle
                          fill="#3C5A9A"
                          cx="29.5091719"
                          cy="29.4927506"
                          r="29.4882047"
                        />
                        <path
                          d="M39.0974944,9.05587273 L32.5651312,9.05587273 C28.6886088,9.05587273 24.3768224,10.6862851 24.3768224,16.3054653 C24.395747,18.2634019 24.3768224,20.1385313 24.3768224,22.2488655 L19.8922122,22.2488655 L19.8922122,29.3852113 L24.5156022,29.3852113 L24.5156022,49.9295284 L33.0113092,49.9295284 L33.0113092,29.2496356 L38.6187742,29.2496356 L39.1261316,22.2288395 L32.8649196,22.2288395 C32.8649196,22.2288395 32.8789377,19.1056932 32.8649196,18.1987181 C32.8649196,15.9781412 35.1755132,16.1053059 35.3144932,16.1053059 C36.4140178,16.1053059 38.5518876,16.1085101 39.1006986,16.1053059 L39.1006986,9.05587273 L39.0974944,9.05587273 L39.0974944,9.05587273 Z"
                          fill="#FFFFFF"
                        />
                      </g>
                    </g>
                  </svg>
                </MKButton>
                <MKButton variant="outlined" color="light" sx={{ m: 2 }}>
                  <svg
                    width="48px"
                    height="48px"
                    viewBox="0 0 64 64"
                    version="1.1"
                  >
                    {" "}
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        transform="translate(3.000000, 2.000000)"
                        fillRule="nonzero"
                      >
                        <path
                          d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                          fill="#4285F4"
                        />
                        <path
                          d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                          fill="#34A853"
                        />
                        <path
                          d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                          fill="#FBBC05"
                        />
                        <path
                          d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                          fill="#EB4335"
                        />
                      </g>
                    </g>
                  </svg>
                </MKButton>
              </MKBox>
              <MKTypography variant="body2" textAlign="center">
                ou utilize os formulários
              </MKTypography>
            </MKBox>
          </Grid>
        )}
        {authenticatedUser === null && !socialLoginState && (
          <Grid
            item
            xs={11}
            md={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <MKBox width={{ xs: "90%", md: "60%" }}>
              <AppBar position="static" sx={{ my: 2 }}>
                <Tabs value={activeTab} onChange={handleTabType}>
                  <Tab
                    id="register"
                    label={
                      <MKTypography variant="button">CADASTRO</MKTypography>
                    }
                    sx={{ my: 1 }}
                  />
                  <Tab
                    id="login"
                    label={
                      <MKTypography variant="button">
                        JÁ POSSUO CADASTRO
                      </MKTypography>
                    }
                    sx={{ my: 1 }}
                  />
                </Tabs>
              </AppBar>
            </MKBox>
          </Grid>
        )}
        {errorUserMessage && (
          <Stack sx={{ width: "100%" }}>
            <Alert variant="filled" severity="error">
              Dados inválidos. Tente novamente!
            </Alert>
          </Stack>
        )}
        {authenticatedUser === null && tabType === "register" && (
          <Grid
            item
            xs={11}
            md={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid container spacing={1} width={{ xs: "90%", md: "60%" }}>
              <Grid item xs={12}>
                <MKInput
                  name="userName"
                  label="Nome Completo"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  name="userEmail"
                  type="email"
                  label="E-mail"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <MKInput
                  name="userPhone"
                  type="phone"
                  label="Celular"
                  placeholder="(99) 99999-9999"
                  value={userPhone}
                  onChange={(e) => handleCellPhone(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <MKInput
                  name="userCPF"
                  label="CPF"
                  value={userCPF}
                  placeholder="999.999.999-99"
                  onChange={(e) => handleCPF(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <MKInput
                  name="userPassword"
                  type={showPassword ? "text" : "password"}
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  label="Senha"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <MKInput
                  name="userPassword2"
                  type={showPassword2 ? "text" : "password"}
                  label="Confirme a senha"
                  value={userPassword2}
                  onChange={(e) => setUserPassword2(e.target.value)}
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword2}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MKButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => registerUser()}
                >
                  REGISTRAR
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        {authenticatedUser === null && tabType === "login" && (
          <Grid
            item
            xs={11}
            md={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <MKBox width={{ xs: "90%", md: "60%" }}>
              <MKInput
                name="userLogin"
                label="Email ou CPF"
                onChange={(e) => setUserLogin(e.target.value)}
                fullWidth
                required
                sx={{ my: 0.7 }}
              />

              <MKInput
                name="passwordlogin"
                type={showPasswordLogin ? "text" : "password"}
                onChange={(e) => setPasswordLogin(e.target.value)}
                label="Senha"
                fullWidth
                required
                sx={{ my: 0.7 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPasswordLogin}
                        edge="end"
                      >
                        {showPasswordLogin ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <MKBox display="flex" alignItems="center">
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  &nbsp;&nbsp;Lembre-se de mim!
                </MKTypography>
              </MKBox>

              <MKButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => loginUser()}
              >
                FAZER LOGIN
              </MKButton>

              <MKBox textAlign="center">
                <MKTypography variant="button" color="text">
                  Esqueceu sua senha?{" "}
                  <MKTypography
                    component={Link}
                    to="/forgot-password"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Recuperar senha!
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
        )}
        {authenticatedUser !== null && (
          <MKBox component="section">
            <Container>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <MKInput
                    name="question"
                    label="Faça sua Pergunta:"
                    multiline
                    minRows={5}
                    maxRows={10}
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MKButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={makeQuestion}
                  >
                    FAZER PERGUNTA
                  </MKButton>
                </Grid>
              </Grid>
              <MKTypography variant="body1" textAlign="center" pt={1}>
                Quando um dos profissionais responder sua pergunta, enviaremos a
                resposta para seu e-mail.
              </MKTypography>
              <MKTypography variant="body2" textAlign="center">
                Publicaremos sua pergunta e resposta do profissional sem expor
                sua identidade (usando apenas o seu primeiro nome)
              </MKTypography>
            </Container>
          </MKBox>
        )}
        <Divider />
        <MKTypography variant="h4" textAlign="center" pt={2}>
          Últimas perguntas
        </MKTypography>
        {authenticatedUser !== null && (
          <MKBox
            component="section"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "#f0f0f0",
              marginY: 2,
              padding: 1,
            }}
          >
            <Switch
              checked={myQuestions}
              onChange={handleSetMyQuestions}
              inputProps={{ "aria-label": "controlled" }}
            />
            <MKTypography variant="h5" textAlign="center">
              Ver apenas as minhas perguntas
            </MKTypography>
          </MKBox>
        )}
        <Divider />
        {Object.keys(questions).length > 0 &&
          questions.map((questionFiltered) => (
            <MKBox
              key={questionFiltered.id}
              component="section"
              variant="gradient"
              bgColor="light"
              p={1}
              m={1}
              sx={{
                border: "solid #f0f0f0",
                borderRadius: "20px",
              }}
            >
              <Grid
                container
                bgcolor="#fff"
                sx={{
                  border: "solid #f0f0f0",
                  borderRadius: "15px",
                }}
              >
                <Grid
                  p={1}
                  textAlign="justify"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <MKTypography variant="body2">
                    {questionFiltered.question}
                  </MKTypography>
                  <MKTypography variant="body2">
                    <b>Pergunta feita por: </b>{" "}
                    {questionFiltered.user.name.split(" ")[0]} em{" "}
                    {dayjs(questionFiltered.created_at)
                      .tz("America/Sao_Paulo")
                      .add(3, "hours")
                      .format("DD/MM/YYYY HH:mm")}
                  </MKTypography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={8}
                  p={2}
                  textAlign="justify"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <MKTypography variant="body2">
                    {questionFiltered.answer ??
                      "Aguardando um profissional responder sua pergunta."}
                  </MKTypography>
                </Grid>
                {questionFiltered.professional && (
                  <Grid item xs={12} lg={4} p={1}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        lg={4}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MKAvatar
                          src={`https://storage.googleapis.com/mpo_images/professionals/photos/${questionFiltered.professional.id}.jpeg`}
                          alt="xxl"
                          size="xxl"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={8}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MKTypography>
                          {questionFiltered.professional.name}
                        </MKTypography>
                        <Rating name="read-only" value={4} readOnly />
                        <MKBox p={1}>
                          <MKButton
                            variant="gradient"
                            color="info"
                            fullWidth
                            component={Link}
                            to={{
                              pathname: `/professionaldetails/${questionFiltered.professional.id}`,
                              state: {
                                from: "/home",
                                serviceId: questionFiltered.service_id,
                              },
                            }}
                          >
                            Agendar Atendimento
                          </MKButton>
                        </MKBox>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </MKBox>
          ))}
      </Card>
    </>
  );
}

export default Questions;
