import React, { useState } from "react";
import routes from "routes";
import footerRoutes from "footer.routes";

import { Card, Container, Grid } from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// import BaseLayout from "components/BaseLayout";
// import View from "components/View";
import FaqCollapse from "components/FaqCollapse";

import Menu from "components/Menu";
import DefaultFooter from "components/Footers/DefaultFooter";

// images
import LogoMPO from "assets/images/logo_horizontal.png";

function FAQ() {
  const [collapse, setCollapse] = useState(false);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  return (
    <>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox component="section" pt={20} pb={12}>
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={1}
              mb={1}
              textAlign="center"
            >
              <MKTypography variant="body2" color="white" mt={0}>
                Perguntas frequentes
              </MKTypography>
            </MKBox>
            <MKBox pb={6} px={{ xs: 2, md: 6 }}>
              <MKBox component="section" py={2}>
                <Container>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} md={6} my={6}>
                      <MKBox mb={2}>
                        <MKTypography
                          variant="body2"
                          align="center"
                          color="text"
                        >
                          O que as pessoas mais perguntam para nós...
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <FaqCollapse
                        title="Como funciona uma consulta on-line?"
                        open={collapse === 1}
                        onClick={() =>
                          collapse === 1 ? setCollapse(false) : setCollapse(1)
                        }
                      >
                        Ela funciona como uma outra consulta qualquer. O Médico
                        irá lhe atender e realizar toda a consulta da mesma
                        forma porém através do seu computador ou celular.
                      </FaqCollapse>
                      <FaqCollapse
                        title="É seguro consulta on-line?"
                        open={collapse === 2}
                        onClick={() =>
                          collapse === 2 ? setCollapse(false) : setCollapse(2)
                        }
                      >
                        Sim. Toda consulta é feita respeitando o sigilo do
                        conteúdo entre paciente-médico, respeitando também todas
                        as regulamentações do Código de Ética Médica bem como as
                        atribuições vigentes para a plena condução do
                        atendimento. Portanto, o conteúdo das conversas entre
                        médico e paciente ficam apenas entre eles.
                      </FaqCollapse>
                      <FaqCollapse
                        title="Posso me consultar com mais de um Psiquiatra?"
                        open={collapse === 3}
                        onClick={() =>
                          collapse === 3 ? setCollapse(false) : setCollapse(3)
                        }
                      >
                        Sim, a escolha do profissional é livre.
                      </FaqCollapse>
                      <FaqCollapse
                        title="Por que preferir uma consulta on-line?"
                        open={collapse === 4}
                        onClick={() =>
                          collapse === 4 ? setCollapse(false) : setCollapse(4)
                        }
                      >
                        Imagine você ter acesso médico especializado a qualquer
                        momento e de onde quiser. Você pode ficar em seu lugar
                        preferido e conversar com seu médico.
                      </FaqCollapse>
                    </Grid>
                  </Grid>
                </Container>
              </MKBox>
            </MKBox>
          </Card>
        </MKBox>
        <MKBox pt={1} mt={1}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Container>
    </>
  );
}

export default FAQ;
