import routes from "routes";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

import MKBox from "components/MKBox";
import Menu from "components/Menu";
import MKAlert from "components/MKAlert";
import HorizontalTeamCard from "components/Cards/HorizontalTeamCard";
import MKTypography from "components/MKTypography";

import LogoMPO from "assets/images/logo_horizontal.png";

import { api } from "../../lib/axios";

function Clinical() {
  const serviceId = "81e983be-13af-4153-8fa6-49fe858c1103";
  const location = useLocation();
  const pathOrigin = location.pathname;
  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [professionals, setProfessionals] = useState(null);
  const [loading, setLoading] = useState(false);

  const getProfessionals = async () => {
    setLoading(true);
    try {
      const responseProfessionals = await api.get(
        `/professionals?service_id=${serviceId}`,
      );
      const ordernedProfessionalList = responseProfessionals.data.filter(
        (prof) => prof.status === true,
      );
      ordernedProfessionalList.sort((a, b) => {
        const orderColumn1 =
          a.order_column !== null
            ? a.order_column.toLowerCase()
            : "ZZZZZZZZZZZZZZZZZZZZZZZZZ";
        const orderColumn2 =
          b.order_column !== null
            ? b.order_column.toLowerCase()
            : "ZZZZZZZZZZZZZZZZZZZZZZZZZ";
        if (orderColumn1 < orderColumn2) {
          return -1;
        }
        return 0;
      });
      setProfessionals(ordernedProfessionalList);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    async function getInfos() {
      if (!professionals) {
        await getProfessionals();
      }
    }
    getInfos();
  }, []);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Grid container py="15vh">
        {alertComponent.message && (
          <Grid item xs={12}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}
        {professionals && professionals.length > 0 ? (
          <>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ mb: 6, textAlign: "center", paddingTop: 4 }}
            >
              <MKTypography variant="h4">Médicos Clínicos</MKTypography>
              <MKTypography variant="body2" opacity={0.8} mx={2}>
                Selecione o profissional de sua preferência e escolha o melhor
                horário para seu atendimento.
              </MKTypography>
            </Grid>
            <Grid container sx={{ paddingY: "3%" }}>
              {professionals.length > 0 &&
                professionals.map((professional) => (
                  <Grid item xs={12} lg={6} key={professional.id} p="1%">
                    <MKBox mb={1}>
                      <HorizontalTeamCard
                        image={`https://storage.googleapis.com/mpo_images/professionals/photos/${professional.id}.jpeg`}
                        name={professional.name}
                        position={{
                          color: "info",
                          label: `${professional.services.find(
                            (profServ) => profServ.id === serviceId,
                          ).group_name
                            }`,
                        }}
                        resume={professional.resume}
                        rating={professional.rating ?? 0}
                        price={
                          professional.services.find(
                            (profServ) => profServ.id === serviceId,
                          ).price
                        }
                        duration={
                          professional.services.find(
                            (profServ) => profServ.id === serviceId,
                          )?.duration ?? 60
                        }
                        id={professional.id}
                        service={serviceId}
                        pathOrigin={pathOrigin}
                        group=""
                        percentageDiscount={0}
                      />
                    </MKBox>
                  </Grid>
                ))}
            </Grid>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              width: "100%",
              height: "70vh",
            }}
          >
            <MKTypography variant="h4" textAlign="center" width="100%">
              Nenhum profissional disponível no momento!
            </MKTypography>
          </Box>
        )}
      </Grid>
    </>
  );
}

export default Clinical;
