/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Box } from "@mui/material";

const RQEValidationIds = [
  "ded1b02d-037c-4a61-927f-2c6872ecf6ac",
  "c59ece59-f335-4752-b4c2-da8217870e43",
];
function HorizontalTeamCard({
  image,
  name,
  position,
  resume,
  rating,
  price,
  duration,
  id = null,
  service,
  pathOrigin,
  group,
  percentageDiscount = 0,
}) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: -4 }}>
          <MKBox
            width="100%"
            pt={2}
            pb={1}
            px={{ xs: 0, lg: 2 }}
            component={Link}
            to={{
              pathname: `/professionaldetails/${id}`,
              state: { from: pathOrigin, serviceId: service },
            }}
          >
            <MKBox
              component="img"
              src={image}
              alt={name}
              width="100%"
              borderRadius="md"
              shadow="lg"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
          <MKBox
            pt={{ xs: 1, lg: 2.5 }}
            pb={2.5}
            pr={4}
            pl={{ xs: 4, lg: 4 }}
            lineHeight={1}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <MKTypography variant="h5">{name}</MKTypography>
            <MKTypography
              variant="h6"
              color={position.color}
              sx={{ lineHeight: 1.2 }}
            >
              {position.label}
            </MKTypography>
            <MKTypography variant="button" color="text">
              {resume}{" "}
              {RQEValidationIds.includes(service) && !resume.includes("RQE")
                ? "(NÃO Especialista)"
                : ""}
            </MKTypography>
            {price !== 0 && group === "" && (
              <MKTypography variant="body2" color="text">
                <strong>{`R$ ${parseFloat(price)
                  .toFixed(2)
                  .replace(".", ",")}`}</strong>
              </MKTypography>
            )}
            {price !== 0 &&
              group === process.env.REACT_APP_HEALTHPLAN_GROUP && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <MKTypography
                    variant="body2"
                    color="text"
                    sx={{ textDecoration: "line-through" }}
                  >
                    {`R$ ${parseFloat(price).toFixed(2).replace(".", ",")} `}
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    <strong>{`... no plano: R$ ${parseFloat(
                      +price - (+price / 100) * percentageDiscount,
                    )
                      .toFixed(2)
                      .replace(".", ",")}`}</strong>
                  </MKTypography>
                </Box>
              )}
            {process.env.REACT_APP_DISPLAY_DURATIONS === "true" && (
              <MKTypography variant="caption" color="text">
                Tempo de atendimento: até {duration !== 0 ? duration : 60}{" "}
                minutos
              </MKTypography>
            )}
            <Rating name="read-only" value={rating} readOnly />
          </MKBox>
        </Grid>
      </Grid>
      <MKBox py={1} px={2} position="relative">
        <MKButton
          variant="gradient"
          color="info"
          fullWidth
          component={Link}
          to={{
            pathname: `/professionaldetails/${id}`,
            state: { from: pathOrigin, serviceId: service },
          }}
        >
          Agendar Atendimento
        </MKButton>
      </MKBox>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  resume: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  pathOrigin: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  percentageDiscount: PropTypes.number.isRequired,
};

export default HorizontalTeamCard;
