/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import routes from "routes";

import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";

import Menu from "components/Menu";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKAlert from "components/MKAlert";

import LogoMPO from "assets/images/logo_horizontal.png";

import { api } from "lib/axios";

function ProductDetails() {
  const { id } = useParams();
  const history = useHistory();
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [routeIndex, setRouteIndex] = useState("public");
  const [product, setProduct] = useState({});

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "info",
  });
  const [loading, setLoading] = useState(false);

  const [couponSelected, setCouponSelected] = useState({});

  const [coupon, setCoupon] = useState("");

  const handleProduct = async (productId) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/products/${productId}`);
      setProduct(data);
    } catch (error) {
      setAlertComponent({
        message: error?.response?.data?.message || "Erro na busca do produto",
        type: "error",
      });
    }
    setLoading(false);
  };

  const validateCoupom = async () => {
    setLoading(true);
    try {
      if (coupon.length === 0) {
        setAlertComponent({
          message: "Informe o código do cupom",
          type: "error",
        });
        setLoading(false);
        return;
      }

      const dataParams = {
        code: coupon,
        user_id: authenticatedUser.id,
        product_id: product.id,
      };

      const response = await api.get("coupons/validate", {
        params: dataParams,
      });

      setCouponSelected(response.data);
    } catch (err) {
      setAlertComponent({
        message: err.response.data?.message || "Erro na busca do cupom",
        type: "error",
      });
      setCoupon("");
    }
    setLoading(false);
  };

  const proccessAndGoPayment = async () => {
    setLoading(true);
    try {
      const dataBuy = {
        product_id: product.id,
        user_id: authenticatedUser.id,
        coupon: couponSelected.code,
      };

      const response = await api.post("/sales_products", dataBuy);

      history.push(`/storepayment/${response.data.id}`);
    } catch (err) {
      setAlertComponent({
        message: err.response.data.message,
        type: "error",
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (
      authenticatedUser === null &&
      localStorage.getItem("user") &&
      routeIndex === "public"
    ) {
      setAuthenticatedUser(JSON.parse(localStorage.getItem("user")));
    }

    async function getProduct() {
      await handleProduct(id);
    }
    getProduct();
  }, []);

  useEffect(() => {
    if (authenticatedUser !== null) {
      setRouteIndex(authenticatedUser.type);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox sx={{ paddingTop: { md: 18, xs: 15 } }}>
        {product && Object.keys(product).length > 0 && (
          <>
            <MKBox sx={{ padding: 1, textAlign: "center" }}>
              <img
                src={`https://storage.googleapis.com/mpo_images/products/photos/${product.id}.jpeg`}
                // src={LogoMPO}
                alt={product.name}
                style={{ height: "auto", maxWidth: "100%", maxHeight: "50vh" }}
              />
            </MKBox>
            <MKTypography
              variant="h2"
              style={{ textAlign: "center", marginX: { lg: "12%", xs: "5%" } }}
            >
              {product.name}
            </MKTypography>
            <Divider />
            <Typography
              variant="body2"
              component="div"
              dangerouslySetInnerHTML={{
                __html: product.description,
              }}
            />
            <Divider />
            <MKTypography
              variant="h2"
              sx={{
                textAlign: "center",
                marginY: 2,
                width: "100%",
              }}
            >
              {product.price.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </MKTypography>
            <Divider />
            {alertComponent.message !== "" && (
              <MKBox sx={{ marginX: { lg: "12%", xs: "5%" }, marginY: 2 }}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </MKBox>
            )}
            {authenticatedUser !== null ? (
              <>
                {Object.keys(couponSelected).length === 0 ? (
                  <MKBox sx={{ marginX: { lg: "15%", xs: "5%" }, marginY: 2 }}>
                    <MKTypography
                      variant="body2"
                      textAlign="center"
                      marginY={2}
                    >
                      Você possui um cupom? Insira-o abaixo
                    </MKTypography>
                    <MKBox
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <MKInput
                        variant="outlined"
                        name="coupon"
                        label="CÓDIGO DO CUPOM"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        sx={{ paddingX: 1, width: "50%" }}
                      />
                      <MKButton
                        onClick={() => validateCoupom()}
                        color="secondary"
                        disabled={!!loading}
                        width="auto"
                        sx={{ paddingX: 3, textAlign: "center", width: "50%" }}
                      >
                        VALIDAR CUPOM
                      </MKButton>
                    </MKBox>
                  </MKBox>
                ) : (
                  <MKTypography variant="body1" textAlign="center">
                    CUPOM VALIDADO! Você pagará o valor de{" "}
                    {couponSelected.value >= product.price
                      ? "R$ 0,00"
                      : (product.price - couponSelected.value).toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                  </MKTypography>
                )}
                <Divider />

                <MKBox sx={{ marginX: { lg: "12%", xs: "5%" }, marginY: 2 }}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    sx={{ width: "100%", height: 52 }}
                    onClick={() => proccessAndGoPayment()}
                  >
                    COMPRAR AGORA
                  </MKButton>
                </MKBox>
                <Divider />
              </>
            ) : (
              <MKBox
                sx={{
                  textAlign: "center",
                  width: "100%",
                  padding: "50px",
                  backgroundColor: "#b80000",
                }}
              >
                <Typography variant="h4" sx={{ color: "yellow"}}>
                  Você precisa estar logado para comprar produtos!
                </Typography>
              </MKBox>
            )}
          </>
        )}
      </MKBox>
    </>
  );
}

export default ProductDetails;
