/* eslint-disable no-plusplus */
/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import routes from "routes";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import LogoMPO from "assets/images/logo_horizontal.png";

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  Container,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";

import { DataGrid, ptBR } from "@mui/x-data-grid";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import { api } from "../../lib/axios";

function UserAppointments() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale("pt-br");

  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [routeIndex, setRouteIndex] = useState("public");

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [alertModalInfos, setAlertModalInfos] = useState({
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const [schedules, setSchedules] = useState([]);
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [confirmedAppointments, setConfirmedAppointments] = useState([]);
  const [awaitRateAppointments, setAwaitRateAppointments] = useState([]);
  const [rowsAppointments, setRowsAppointments] = useState([]);

  const [scheduleInfo, setScheduleInfo] = useState({});
  const [appointmentInfoModal, setAppointmentInfoModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileTypeCustom, setFileTypeCustom] = useState("");

  const isValidCPF = (numbersCPF) => {
    if (numbersCPF.length !== 11) {
      return false;
    }
    if (/^(\d)\1{10}$/.test(numbersCPF)) {
      return false;
    }

    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(numbersCPF[9], 10)) {
      return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(numbersCPF[10], 10)) {
      return false;
    }
    return true;
  };

  const getSchedules = async () => {
    setLoading(true);
    try {
      const response = await api.get("/schedules", {
        params: { user_id: authenticatedUser.id },
      });
      const appointmentExtract = response.data.sort((a, b) => {
        const dateA = dayjs(a.date_start).tz("America/Sao_Paulo");
        const dateB = dayjs(b.date_start).tz("America/Sao_Paulo");
        return dateB - dateA;
      });
      setSchedules(appointmentExtract);

      const timeNow = dayjs().tz("America/Sao_Paulo");

      setPendingAppointments(
        appointmentExtract.filter(
          (schedule) =>
            schedule.status === "PROCESSANDO" &&
            dayjs(schedule.date_start)
              .tz("America/Sao_Paulo")
              .add(3, "hours")
              .isAfter(timeNow),
        ),
      );

      setConfirmedAppointments(
        appointmentExtract.filter(
          (schedule) =>
            schedule.status === "AGENDADO" &&
            dayjs(schedule.date_start)
              .tz("America/Sao_Paulo")
              .add(5, "hours")
              .isAfter(timeNow),
        ),
      );

      setAwaitRateAppointments(
        appointmentExtract.filter(
          (schedule) => schedule.status === "AVALIAÇÃO",
        ),
      );
    } catch (err) {
      setAlertComponent({
        message:
          err.response?.data?.message ||
          "Não foi possível carregar os agendamentos.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleOpenInfoModal = (row) => {
    setScheduleInfo(row);
    setAppointmentInfoModal(true);
  };

  const handleCloseInfoModal = () => {
    setAppointmentInfoModal(false);
    setScheduleInfo({});
  };

  const handleOpenUploadModal = (row) => {
    setScheduleInfo(row);
    setUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setUploadModal(false);
    setScheduleInfo({});
    setFile(null);
    setFileType("");
    setFileTypeCustom("");
  };

  const uploadNewFile = async () => {
    if (Object.keys(scheduleInfo).length === 0) {
      setAlertComponent({
        type: "error",
        message: "Agendamento não encontrado.",
      });
      setUploadModal(false);
      return;
    }

    if (!file) {
      setAlertModalInfos({
        type: "error",
        message: "Selecione o arquivo que deseja enviar",
      });
      return;
    }

    if (!fileType) {
      setAlertModalInfos({
        type: "error",
        message: "Selecione o tipo do arquivo",
      });
      return;
    }

    if (fileType === "custom" && fileTypeCustom === "") {
      setAlertModalInfos({
        type: "error",
        message: "Informe o tipo do arquivo no campo de texto",
      });
      return;
    }

    let title = "";
    if (fileType === "custom") {
      title = fileTypeCustom;
    } else {
      title = fileType;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("schedule_id", scheduleInfo.id);
      formData.append("title", title);

      await api.post(`/documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      handleCloseUploadModal();
      setAlertComponent({
        type: "success",
        message: "Arquivo salvo com sucesso",
      });
    } catch (error) {
      handleCloseUploadModal();
      setAlertComponent({
        type: "error",
        message: "Erro ao salvar arquivo",
      });
    }
    setLoading(false);
  };

  const extractDate = (params) =>
    dayjs(params.row.date_start)
      .add(3, "hours")
      .tz("America/Sao_Paulo")
      .format("DD/MM/YYYY [às] HH:mm");

  const appointmentsColumns = [
    {
      field: "professional",
      headerName: "Profissional",
      flex: 1,
      valueGetter: (params) => params.row.professional.name,
    },
    { field: "data", headerName: "Data", flex: 1, valueGetter: extractDate },
    { field: "status", headerName: "Status", flex: 0.5 },
    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          {/* <Tooltip title="Enviar arquivo" placement="top">
            <IconButton size="large" onClick={() => handleOpenUploadModal(row)}>
              <CloudUploadIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Informação do agendamento" placement="top">
            <IconButton size="large" onClick={() => handleOpenInfoModal(row)}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    if (authenticatedUser === null && !localStorage.getItem("user")) {
      window.location.replace("/login");
    }
    if (authenticatedUser === null && localStorage.getItem("user")) {
      setAuthenticatedUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  useEffect(() => {
    if (schedules) {
      const rowsForTable = [];
      schedules.forEach((schedule) => {
        rowsForTable.push({
          profissional: schedule.professional.name,
          data: dayjs(schedule.date_start)
            .tz("America/Sao_Paulo")
            .add(3, "hours")
            .format("DD/MM/YYYY [às] HH:mm"),
          estado: schedule.status,
        });
      });
      setRowsAppointments(rowsForTable);
    }
  }, [schedules]);

  useEffect(() => {
    async function getInfos() {
      if (authenticatedUser) {
        setRouteIndex(authenticatedUser.type);
        if (schedules.length === 0) {
          await getSchedules();
        }
        const cpf = isValidCPF(authenticatedUser.cpf);
        if (cpf !== true) {
          setAlertComponent({
            message: "CPF inválido! Corrija-o antes de prosseguir.",
            type: "error",
          });
        }
      }
    }
    getInfos();
  }, [authenticatedUser]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (alertModalInfos.message !== "") {
      setTimeout(() => {
        setAlertModalInfos({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertModalInfos]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox
          component="section"
          position="relative"
          py={20}
          width="100%"
          height="100vh"
        >
          {alertComponent.message && (
            <MKBox sx={{ marginY: 2 }}>
              <MKAlert color={alertComponent.type}>
                {alertComponent.message}
              </MKAlert>
            </MKBox>
          )}
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={1}
              mb={1}
              textAlign="center"
            >
              <MKTypography
                variant="body1"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Próximos Agendamentos
              </MKTypography>
            </MKBox>
            <MKBox mx={2} mb={1} height="auto">
              <MKTypography variant="body2" fontWeight="medium" pt={2}>
                Confirmados:
              </MKTypography>
              {confirmedAppointments.length > 0 && (
                <Grid>
                  {confirmedAppointments.map((appointment) => (
                    <Box py={0.3} key={appointment.id}>
                      <MKButton
                        variant="gradient"
                        color="success"
                        component={Link}
                        to={`/appointment-details/${appointment.id}`}
                        size="large"
                        fullWidth
                      >
                        {dayjs(appointment.date_start)
                          .tz("America/Sao_Paulo")
                          .add(3, "hours")
                          .format("dddd DD/MM/YYYY [às] HH:mm")}{" "}
                        - {appointment.professional.name}
                      </MKButton>
                    </Box>
                  ))}
                </Grid>
              )}
              <MKTypography variant="body2" fontWeight="medium" pt={2}>
                Pendentes de Pagamento:
              </MKTypography>
              {pendingAppointments.length > 0 && (
                <Grid>
                  {pendingAppointments.map((appointment) => (
                    <Box py={0.3} key={appointment.id}>
                      <MKButton
                        variant="gradient"
                        color="error"
                        component={Link}
                        to={`/appointment-details/${appointment.id}`}
                        size="large"
                        fullWidth
                      >
                        {dayjs(appointment.date_start)
                          .tz("America/Sao_Paulo")
                          .add(3, "hours")
                          .format("dddd DD/MM/YYYY [às] HH:mm")}{" "}
                        - {appointment.professional.name}
                      </MKButton>
                    </Box>
                  ))}
                </Grid>
              )}
              <MKTypography variant="body2" fontWeight="medium" pt={2}>
                Aguardando Sua Avaliação:
              </MKTypography>
              {awaitRateAppointments.length > 0 && (
                <Grid>
                  {awaitRateAppointments.map((appointment) => (
                    <Box py={0.3} key={appointment.id}>
                      <MKButton
                        variant="gradient"
                        color="info"
                        component={Link}
                        to={`/appointment-details/${appointment.id}`}
                        size="large"
                        fullWidth
                      >
                        {dayjs(appointment.date_start)
                          .tz("America/Sao_Paulo")
                          .add(3, "hours")
                          .format("dddd DD/MM/YYYY [às] HH:mm")}{" "}
                        - {appointment.professional.name}
                      </MKButton>
                    </Box>
                  ))}
                </Grid>
              )}
            </MKBox>
            <MKBox width="100%" py={2}>
              <MKTypography variant="body2" align="center">
                Clique no agendamento para abrir a tela com os detalhes.
              </MKTypography>
            </MKBox>
          </Card>
          <Divider />
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={1}
              mb={1}
              textAlign="center"
            >
              <MKTypography
                variant="body1"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Histórico de Agendamentos
              </MKTypography>
            </MKBox>
            <MKBox mx={2} mb={1} py={1}>
              {rowsAppointments && (
                <DataGrid
                  rows={schedules}
                  columns={appointmentsColumns}
                  pageSize={100}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              )}
            </MKBox>
          </Card>
          <Divider />
        </MKBox>
      </Container>

      {Object.keys(scheduleInfo).length > 0 && (
        <>
          <Dialog
            open={uploadModal}
            onClose={handleCloseUploadModal}
            maxWidth="md"
            fullWidth
            sx={{ zIndex: 20000 }}
          >
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h3>Upload de Arquivo</h3>
              </div>
              <div>
                <IconButton onClick={handleCloseUploadModal}>
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent dividers>
              {alertModalInfos.message !== "" && (
                <Box sx={{ padding: "10px" }}>
                  <Alert severity={alertModalInfos.type}>
                    {alertModalInfos.message}
                  </Alert>
                </Box>
              )}
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: "#FFEDE9",
                  borderRadius: "10px",
                }}
              >
                <Typography variant="body1" fontWeight="700">
                  CONFIRA OS DADOS DO ATENDIMENTO:
                </Typography>
                <Typography variant="body2">
                  NOME DO PROFISSIONAL: {scheduleInfo.professional.name}
                </Typography>
                <Typography variant="body2">
                  HORA DO ATENDIMENTO:{" "}
                  {dayjs(scheduleInfo.date_start)
                    .add(3, "hours")
                    .tz("America/Sao_Paulo")
                    .format("DD/MM/YYYY [às] HH:mm")}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", padding: 1 }}
              >
                <FormControl>
                  <FormLabel>INFORME O TIPO DO ARQUIVO:</FormLabel>
                  <RadioGroup
                    value={fileType}
                    onChange={(e) => setFileType(e.target.value)}
                  >
                    <FormControlLabel
                      value="Resultado de Exame"
                      control={<Radio />}
                      label="Resultado de Exame"
                      sx={{ height: "20px" }}
                    />
                    <FormControlLabel
                      value="custom"
                      control={<Radio />}
                      label="Outro Tipo"
                      sx={{ height: "20px" }}
                    />
                  </RadioGroup>
                  {fileType === "custom" && (
                    <TextField
                      onChange={(e) => setFileTypeCustom(e.target.value)}
                      helperText="Informe o tipo do arquivo"
                      hel
                      sx={{
                        height: "40px",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    />
                  )}
                </FormControl>
                <TextField
                  type="file"
                  id="file"
                  helperText="Selecione o arquivo que deseja enviar"
                  onChange={(e) => setFile(e.target.files[0])}
                  sx={{ marginBottom: "20px", marginTop: "20px" }}
                />
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => uploadNewFile()}
                >
                  Enviar Arquivo
                </Button>
              </Box>
            </DialogContent>
          </Dialog>

          <Dialog
            open={appointmentInfoModal}
            onClose={handleCloseInfoModal}
            maxWidth="md"
            fullWidth
            sx={{ zIndex: 20000 }}
          >
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h3>Informações do Agendamento</h3>
              </div>
              <div>
                <IconButton onClick={handleCloseInfoModal}>
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent dividers>
              <Box
                sx={{ display: "flex", flexDirection: "column", padding: 1 }}
              >
                <Typography variant="body2">
                  PROFISSIONAL: {scheduleInfo.professional.name} -{" "}
                  {scheduleInfo.service.name}
                </Typography>
                <Typography variant="body2">
                  HORA DO ATENDIMENTO:{" "}
                  {dayjs(scheduleInfo.date_start)
                    .add(3, "hours")
                    .tz("America/Sao_Paulo")
                    .format("DD/MM/YYYY [às] HH:mm")}
                </Typography>
                <Typography variant="body2">
                  STATUS: {scheduleInfo.status}
                </Typography>
                <Typography variant="body2">
                  VALOR PAGO: R${" "}
                  {scheduleInfo.price.toLocaleString("pt-BR", {
                    currency: "BRL",
                    style: "currency",
                  })}
                </Typography>
                {scheduleInfo.text_evaluation && (
                  <>
                    <Typography variant="body2">
                      AVALIAÇÃO DO PROFISSIONAL: {scheduleInfo?.rated || "SEM"}{" "}
                      estrelas
                    </Typography>
                    <Typography variant="body2">
                      SEU TEXTO DE AVALIAÇÃO: {scheduleInfo.text_evaluation}
                    </Typography>
                  </>
                )}
                {/* <Box
                  sx={{
                    padding: 2,
                    marginTop: "20px",
                    backgroundColor: "#FFEDE9",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="body1" fontWeight="700">
                    PRECISA ENVIAR ALGUM ARQUIVO?
                  </Typography>
                  <Typography variant="body2">
                    Clique no ícone de upload na relação de agendamentos para
                    enviar. Não se esqueça de informar o tipo do arquivo!
                  </Typography>
                </Box> */}
              </Box>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
}

export default UserAppointments;
