import React, { useEffect, useState } from "react";
import OtpInput from "react18-input-otp";

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import "dayjs/locale/pt-br";

import { api } from "../../lib/axios";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("pt-br");

const states = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

function DocumentSignature(params) {
  const { appointmentInfo, setSignatureModal } = params;

  const [alertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const [document, setDocument] = useState("");
  const [uf, setUf] = useState("");
  const [otpCode, setOtpCode] = useState("");

  const clearSignatureInfos = () => {
    setDocument("");
    setOtpCode("");
  };

  const handleSignatureDocument = async () => {
    if (document === "" || otpCode === "" || uf === "") {
      setAlertMessage({
        type: "error",
        message: "Preencha todos os campos.",
      });
      return;
    }
    setLoading(true);
    try {
      const dataRequest = {
        appointmentId: appointmentInfo.id,
        document,
        uf,
        otpCode,
      };
      await api.post("/signature", dataRequest);
      setAlertMessage({
        type: "success",
        message: "Documento assinado com sucesso",
      });
      clearSignatureInfos();
      setSignatureModal(false);
    } catch (err) {
      setAlertMessage({
        type: "error",
        message: "Erro ao assinar documento",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (alertMessage.message !== "") {
      setTimeout(() => {
        setAlertMessage({
          type: "",
          message: "",
        });
      }, 10000);
    }
  }, [alertMessage]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={2}>
          {alertMessage.message !== "" && (
            <Grid item xs={12} md={12}>
              <Alert
                severity={alertMessage.type}
                sx={{ marginX: "20px", marginY: "10px" }}
              >
                {alertMessage.message}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f0f0f0",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              {appointmentInfo && (
                <>
                  <Typography variant="body2">
                    Paciente: {appointmentInfo?.user?.name}
                  </Typography>
                  <Typography variant="body2">
                    Data/Hora:{" "}
                    {dayjs
                      .tz(appointmentInfo.date_start, "America/Sao_Paulo")
                      .format("DD/MM/YYYY [às] HH:mm")}
                  </Typography>
                  <Typography variant="body2">
                    Serviço: {appointmentInfo.service.name}
                  </Typography>
                  <Typography variant="body2">
                    Status: {appointmentInfo.status}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={8}>
            <InputLabel htmlFor="document">
              Documento do Profissional
            </InputLabel>
            <TextField
              id="document"
              value={document}
              onChange={(e) => setDocument(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel htmlFor="uf">Estado</InputLabel>
            <Select
              native
              value={uf}
              onChange={(e) => setUf(e.target.value)}
              fullWidth
              displayEmpty
            >
              <option value="" disabled>
                SELECIONE
              </option>
              {Object.keys(states).map((state) => (
                <option key={state} value={state}>
                  {states[state]}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel htmlFor="otpCode" sx={{ textAlign: "center" }}>
              Código de Validação
            </InputLabel>
            <OtpInput
              id="otpCode"
              numInputs={6}
              value={otpCode}
              onChange={(value) => setOtpCode(value)}
              inputStyle={{
                width: "80px",
                height: "80px",
                margin: "5px",
                fontSize: "20px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSignatureDocument}
              disabled={document === "" || otpCode === "" || uf === ""}
            >
              <Typography variant="buttom" sx={{ color: "#ffffff" }}>
                Assinar Documento
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DocumentSignature;
