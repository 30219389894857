import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";

import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownloadTwoTone";
import TextSnippetTwoToneIcon from "@mui/icons-material/TextSnippetTwoTone";
import InfoIcon from "@mui/icons-material/Info";

function MedicalTimeLine(params) {
  const { timeLineInfos, getMedicalRecord, downloadFile } = params;
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [loading, setLoading] = useState(false);
  const [alertInfos, setAlertInfos] = useState({
    type: "",
    message: "",
  });

  const [medicalRecord, setMedicalRecord] = useState({});

  const [toggleWindow, setToggleWindow] = useState(false);
  const [medicalRecordModal, setMedicalRecordModal] = useState(false);
  const [showItemTimeLine, setShowIntemTimeLine] = useState("");

  const handleShowInfos = (timelineId) => {
    if (showItemTimeLine === timelineId) {
      setShowIntemTimeLine("");
      return;
    }
    setShowIntemTimeLine(timelineId);
  };

  const handleDrawer = () => {
    setToggleWindow(!toggleWindow);
  };

  const handleCloseMedicalRecordModal = () => {
    setMedicalRecordModal(false);
    setMedicalRecord({});
  };

  const openMedicalRecord = async (appointmentId) => {
    if (!appointmentId) {
      setAlertInfos({
        type: "error",
        message: "Referência do prontuário não encontrada",
      });
      return;
    }
    setLoading(true);
    try {
      const data = await getMedicalRecord(appointmentId);
      if (!data || data.length === 0) {
        setAlertInfos({
          type: "error",
          message: "Anotação de prontuário não encontrada",
        });
        setLoading(false);
        return;
      }
      setMedicalRecord(data);
      setMedicalRecordModal(true);
    } catch (error) {
      setAlertInfos({
        type: "error",
        message: "Erro ao abrir prontuário",
      });
    }
    setLoading(false);
  };

  const handleDownloadFile = (fileId) => {
    if (!fileId) {
      setAlertInfos({
        type: "error",
        message: "Referência do arquivo não encontrada",
      });
      return;
    }
    try {
      downloadFile(fileId);
    } catch (error) {
      setAlertInfos({
        type: "error",
        message: "Erro ao baixar arquivo",
      });
    }
  };

  const renderTimeLineItemInfos = (infos) => (
    <Box
      sx={{
        backgroundColor: "#F3F3F3",
        borderRadius: "10px",
        marginY: "5px",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {infos.type === "appointment" && infos.appointment.hasReport && (
          <Box
            onClick={() => openMedicalRecord(infos.appointment.id)}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              cursor: "pointer",
              margin: "5px",
            }}
          >
            <TextSnippetTwoToneIcon />
            <Typography variant="caption" sx={{ marginX: 1 }}>
              Anotações
            </Typography>
          </Box>
        )}

        {infos?.appointment?.attachments &&
          infos.appointment.attachments.length > 0 &&
          infos.appointment.attachments.map((item) => (
            <Box
              onClick={() => handleDownloadFile(item.uuid)}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                cursor: "pointer",
                marginX: "5px",
              }}
            >
              <CloudDownloadTwoToneIcon />
              <Typography variant="caption" sx={{ marginX: 1 }}>
                {item.description}
              </Typography>
            </Box>
          ))}

        {infos.type === "attachment" && (
          <Box
            onClick={() => handleDownloadFile(infos.file.uuid)}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              cursor: "pointer",
              marginX: "5px",
            }}
          >
            <CloudDownloadTwoToneIcon />
            <Typography variant="caption" sx={{ marginX: 1 }}>
              {infos.file.description}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  useEffect(() => {
    if (alertInfos.message !== "") {
      setTimeout(() => {
        setAlertInfos({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertInfos]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          position: "fixed",
          left: `${toggleWindow ? "350" : 0}px`,
          backgroundColor: "#FF785B70",
          width: "50px",
          bottom: `${window.innerHeight / 2 - 100}px`,
          borderRadius: "0px 10px 10px 0px",
          zIndex: 20000,
          cursor: "pointer",
        }}
        onClick={handleDrawer}
      >
        <Box sx={{ position: "relative", height: "200px" }}>
          <Typography
            sx={{
              transform: "rotate(90deg)",
              position: "absolute",
              transformOrigin: "center left",
              width: "250px",
              top: 20,
              left: 20,
              fontWeight: "bold",
              fontSize: "14px",
              zIndex: 30000,
            }}
            variant="caption"
          >
            Timeline do Paciente
          </Typography>
        </Box>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </Box>

      <Drawer anchor="left" open={toggleWindow} onClose={handleDrawer}>
        <Box
          style={{
            width: "350px",
          }}
        >
          {alertInfos.message !== "" && (
            <Alert
              severity={alertInfos.type}
              sx={{ marginX: "20px", marginY: "10px" }}
            >
              {alertInfos.message}
            </Alert>
          )}
          <Timeline>
            {timeLineInfos.length > 0 &&
              timeLineInfos.map((item) => {
                if (
                  (item.type === "appointment" &&
                    !item?.appointment?.hasReport) ||
                  (item.type === "attachment" && item?.file && item.file.length === 0)
                ) {
                  return null
                }
                return (
                  <TimelineItem
                    key={item.date}
                    sx={{
                      "&:before": {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    <TimelineSeparator>
                      <TimelineDot
                        onClick={() => handleShowInfos(item.date)}
                        sx={{ cursor: "pointer", backgroundColor: "#000000" }}
                      >
                        <InfoIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box>
                        <Typography fontSize={12} height={15} fontWeight="bold">
                          {dayjs(item.date)
                            .tz("America/Sao_Paulo")
                            .format("DD/MM/YYYY [às] HH:mm")}
                        </Typography>
                        <Typography fontSize={12} height={15}>
                          {item.professional.name}
                        </Typography>
                        <Typography fontSize={10} height={15}>
                          {item.professional.specialty}
                        </Typography>
                        <Box
                          sx={{
                            display:
                              showItemTimeLine === item.date ? "block" : "none",
                          }}
                        >
                          {renderTimeLineItemInfos(item)}
                        </Box>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
          </Timeline>
        </Box>
      </Drawer>

      <Dialog
        open={medicalRecordModal}
        onClose={handleCloseMedicalRecordModal}
        maxWidth="xl"
        fullWidth
        sx={{ zIndex: 20000 }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Prontuário do Paciente</Typography>
          <IconButton onClick={handleCloseMedicalRecordModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box
            sx={{ display: "flex", flexDirection: "column", padding: "10px" }}
          >
            <Box
              sx={{
                display: "flex",
                borderRadius: "5px",
                backgroundColor: "#f3f3f3",
                margin: "2px",
                padding: "5px",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                Data:
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                {dayjs(medicalRecord.date)
                  .add(3, "hour")
                  .tz("America/Sao_Paulo")
                  .format("DD/MM/YYYY [às] HH:mm")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                borderRadius: "5px",
                backgroundColor: "#f3f3f3",
                margin: "2px",
                padding: "5px",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                Profissional:
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                {medicalRecord.professional}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                borderRadius: "5px",
                backgroundColor: "#f3f3f3",
                margin: "2px",
                padding: "5px",
                width: "100%",
              }}
            >
              <ReactQuill
                readOnly
                theme="snow"
                modules={{ toolbar: false }}
                value={medicalRecord.resume}
                style={{ width: "100%", height: "50vh" }}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default MedicalTimeLine;
