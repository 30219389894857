/**
================================================== =======
* Otis Kit PRO - v2.0.1
================================================== =======

* Página do produto: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Codificado por www.creative-tim.com

 ================================================== =======

* O aviso de direitos autorais acima e este aviso de permissão devem ser incluídos em todas as cópias ou partes substanciais do Software.
/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Otis Kit PRO components
import MKTypography from "components/MKTypography";

// Images
import logo from "assets/images/logo_horizontal.png";

const date = new Date().getFullYear();

export default {
  brand: {
    image: logo,
    route: "/",
  },
  menus: [
    {
      name: "empresa",
      items: [
        {
          name: "Sobre Nós",
          href: "/about-us",
        },
        {
          name: "Política de Privacidade",
          href: "/privacy-politic",
        },
        {
          name: "Perguntas Frequentes",
          href: "/faq",
        },
        {
          name: "Segurança",
          href: "https://security.meupsiquiatraonline.com.br",
        },
      ],
    },
    {
      name: "Serviços de Saúde",
      items: [
        {
          name: "Clínico Geral",
          href: "/clinical",
        },
        {
          name: "Psiquiatras",
          href: "/psychiatrists",
        },
        {
          name: "Psicólogos",
          href: "/psychologists",
        },
        {
          name: "CBD",
          href: "/cbd",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Meu Psiquiatra Online &copy; {date} - Todos os direitos reservados.{" "}
      <MKTypography
        component="a"
        href="https://wa.me/5534997153856"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Factor K Tech Lead
      </MKTypography>
      .
    </MKTypography>
  ),
};
