import { useHistory } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import { Button, Typography } from "@mui/material";

function Scheduling() {
  const history = useHistory();
  return (
    <MKBox position="relative" variant="gradient" bgColor="info">
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width={{ xs: "100%", lg: "100%" }}
        height={{ xs: "100%", lg: "auto" }}
        opacity={0.6}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{ pt: { xs: 2, lg: 6 }, pb: 6 }}
        >
          <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h5" color="white" mb={1}>
              A Maior Plataforma de Psiquiatria do Brasil.
            </MKTypography>
            <MKTypography variant="body2" color="white">
              Criada exclusivamente para Você!
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                borderRadius: 5,
                backgroundColor: "#f2f2f2",
                padding: 1,
                margin: 0.5,
                cursor: "pointer",
                width: 200,
                height: 80,
                textAlign: "center",
              }}
              onClick={() => history.push("/psychiatrists")}
            >
              <Typography>PSIQUIATRIA</Typography>
              <Typography
                variant="caption"
                sx={{ color: "red", fontWeight: "700" }}
              >
                clique para agendar
              </Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                borderRadius: 5,
                backgroundColor: "#f2f2f2",
                padding: 1,
                margin: 0.5,
                cursor: "pointer",
                width: 200,
                height: 80,
                textAlign: "center",
              }}
              onClick={() => history.push("/clinical")}
            >
              <Typography>CLÍNICO GERAL</Typography>
              <Typography
                variant="caption"
                sx={{ color: "red", fontWeight: "700" }}
              >
                clique para agendar
              </Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                borderRadius: 5,
                backgroundColor: "#f2f2f2",
                padding: 1,
                margin: 0.5,
                cursor: "pointer",
                width: 200,
                height: 80,
                textAlign: "center",
              }}
              onClick={() => history.push("/psychologists")}
            >
              <Typography>Psicologia</Typography>
              <Typography
                variant="caption"
                sx={{ color: "red", fontWeight: "700" }}
              >
                clique para agendar
              </Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                borderRadius: 5,
                backgroundColor: "#f2f2f2",
                padding: 1,
                margin: 0.5,
                cursor: "pointer",
                width: 200,
                height: 80,
                textAlign: "center",
              }}
              onClick={() => history.push("/cbd")}
            >
              <Typography>CBD</Typography>
              <Typography
                variant="caption"
                sx={{ color: "red", fontWeight: "700" }}
              >
                clique para agendar
              </Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                borderRadius: 5,
                backgroundColor: "#f2f2f2",
                padding: 1,
                margin: 0.5,
                cursor: "pointer",
                width: 200,
                height: 80,
                textAlign: "center",
              }}
              onClick={() => history.push("/healthplan")}
            >
              <Typography>Plano de Saúde</Typography>
              <Typography
                variant="caption"
                sx={{ color: "red", fontWeight: "700" }}
              >
                clique para agendar
              </Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                borderRadius: 5,
                backgroundColor: "#f2f2f2",
                padding: 1,
                margin: 0.5,
                cursor: "pointer",
                width: 200,
                height: 80,
                textAlign: "center",
              }}
              onClick={() =>
                window.open("https://loja.meupsiquiatraonline.com.br")
              }
            >
              <Typography>LOJA MPO</Typography>
              <Typography
                variant="caption"
                sx={{ color: "red", fontWeight: "700" }}
              >
                visite a loja virtual
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Scheduling;
