/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import Menu from "components/Menu";
import DefaultFooter from "components/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// images
import LogoMPO from "assets/images/logo_horizontal.png";
import { useParams } from "react-router-dom";

import { api } from "../../lib/axios";

function Confirmation() {
  const { id } = useParams();
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const [appointment, setAppointment] = useState({});

  const getSchedule = async (scheduleId) => {
    try {
      const response = await api.get(`/schedules/${scheduleId}`);
      return response.data;
    } catch (err) {
      window.location.replace("/");
    }
  };

  useEffect(() => {
    async function getInfos() {
      if (Object.keys(appointment).length === 0) {
        const userAppointment = await getSchedule(id);
        if (userAppointment.status !== "AGENDADO") {
          window.location.replace(`/payment/${userAppointment.id}`);
        }
        setAppointment(userAppointment);
      }
    }
    getInfos();
  }, []);

  return (
    <>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox component="section" pt={20} pb={12}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Card>
                  <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={1}
                    mb={1}
                    textAlign="center"
                  >
                    <MKTypography variant="body2" color="white" mt={0}>
                      Confirmação
                    </MKTypography>
                  </MKBox>
                  {Object.keys(appointment).length > 0 &&
                  appointment.status === "AGENDADO" ? (
                    <MKBox
                      px={6}
                      mt={5}
                      sx={{
                        height: "400px",
                      }}
                    >
                      <MKTypography variant="body2">
                        Parabéns pela decisão!
                      </MKTypography>
                      <MKTypography variant="body2">
                        Seu pedido de agendamento foi confirmado e em breve
                        enviaremos mensagens com os detalhes do agendamento.
                      </MKTypography>
                      <MKTypography variant="body2">
                        <b>Código do Agendamento:</b> {appointment.id}
                      </MKTypography>
                      <MKTypography variant="body2">
                        <b>Status:</b>{" "}
                        {appointment.status === "PROCESSANDO"
                          ? "Aguardando Pagamento"
                          : "Confirmado"}
                      </MKTypography>
                      <MKTypography variant="body2">
                        Conte sempre conosco!
                      </MKTypography>
                      <MKTypography variant="body2">
                        Equipe do Meu Psiquiatra Online.
                      </MKTypography>
                    </MKBox>
                  ) : (
                    <MKBox
                      sx={{
                        height: "500px",
                        textAlign: "center",
                        padding: "200px",
                      }}
                    >
                      <MKTypography>Processando...</MKTypography>
                    </MKBox>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <MKBox pt={1} mt={1}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Container>
    </>
  );
}

export default Confirmation;
