import routes from "routes";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
  Card,
  Divider,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKAlert from "components/MKAlert";
import HorizontalTeamCard from "components/Cards/HorizontalTeamCard";
import MKButton from "components/MKButton";

import LogoMPO from "assets/images/logo_horizontal.png";
// import canabidiolImg from "assets/images/canabidiol.jpg";

import { api } from "../../lib/axios";

function Canabidiol() {
  const serviceId = "68743815-ee9b-4e81-8f1f-c9d3146a5e81";
  const location = useLocation();
  const pathOrigin = location.pathname;
  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [professionals, setProfessionals] = useState(null);
  const [loading, setLoading] = useState(false);
  const [readMore, setReadMore] = useState(false);

  const getProfessionals = async () => {
    setLoading(true);
    try {
      const responseProfessionals = await api.get(
        "/professionals?service_id=68743815-ee9b-4e81-8f1f-c9d3146a5e81"
      );
      const ordernedProfessionalList = responseProfessionals.data.filter(
        (prof) => prof.status === true
      );
      ordernedProfessionalList.sort((a, b) => {
        const orderColumn1 =
          a.order_column !== null
            ? a.order_column.toLowerCase()
            : "ZZZZZZZZZZZZZZZZZZZZZZZZZ";
        const orderColumn2 =
          b.order_column !== null
            ? b.order_column.toLowerCase()
            : "ZZZZZZZZZZZZZZZZZZZZZZZZZ";
        if (orderColumn1 < orderColumn2) {
          return -1;
        }
        return 0;
      });
      setProfessionals(ordernedProfessionalList);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    async function getInfos() {
      if (!professionals) {
        await getProfessionals();
      }
    }
    getInfos();
  }, []);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      {/* <MKBox
        minHeight={{ xs: "30vh", md: "70vh" }}
        width="100%"
        sx={{
          backgroundImage: `url(${canabidiolImg})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="left" mx="auto" />
        </Container>
      </MKBox> */}
      <Card
        sx={{
          mx: { xs: 0, lg: 3 },
          // mt: -8,
          mb: 4,
          pt: 15,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {alertComponent.message && (
          <Grid item xs={12}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}

        <MKBox>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", paddingTop: "2%" }}
          >
            <b>Sobre o Canabidiol</b>
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: "justify", paddingX: "5%" }}
          >
            O canabidiol, ou CBD, é uma substância presente na planta Cannabis
            sativa, que atua no sistema nervoso central, sendo geralmente
            utilizado no tratamento de alguns casos de epilepsia.
            <br />
            Além disso, o canabidiol apresenta possíveis benefícios terapêuticos
            no tratamento da ansiedade, doença de Parkinson, doença de Alzheimer
            e dor crônica, por exemplo. No entanto, por serem necessários mais
            estudos, a utilização nestas condições ainda não está aprovada em
            vários países.
            <br />
            No Brasil, o CBD pode ser encontrado em medicamentos vendidos em
            farmácias, com nomes comerciais como Canabidiol Prati-Donaduzzi,
            Canabidiol Collect ou Canabidiol Farmanguinhos, e a sua venda é
            feita mediante apresentação de receita médica de controle especial.
          </Typography>
          {readMore && (
            <>
              <Typography
                variant="body2"
                sx={{ textAlign: "justify", paddingX: "5%", paddingTop: "1%" }}
              >
                <b>Para que serve o canabidiol</b>
                <br />
                No Brasil, o canabidiol pode ser indicado para o tratamento de
                epilepsia refratária em crianças e adolescentes que não
                respondem a outras terapias disponíveis, principalmente em casos
                de síndrome de Lennox-Gastaut, síndrome de Dravet e esclerose
                tuberosa.
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: "justify", paddingX: "5%", paddingTop: "1%" }}
              >
                <b>Possíveis benefícios</b>
                <br />
                O canabidiol apresenta possíveis benefícios no tratamento da
                doença de Parkinson e Alzheimer, tratamento da dor crônica,
                ansiedade e em pessoas com câncer, por exemplo. No entanto, não
                há comprovação suficiente até o momento para que seja
                recomendado pela ANVISA ou Conselho Federal de Medicina nessas
                situações.
                <br />
                Além disso, é possível que o canabidiol também tenha outros
                benefícios e propriedades, como ação analgésica e
                imunossupressora, podendo vir a ser útil no tratamento de AVC,
                diabetes, náusea, distúrbios do sono e do movimento, o que o
                torna um medicamento com um grande potencial terapêutico.
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: "justify", paddingX: "5%", paddingTop: "1%" }}
              >
                <b>Canabidiol serve para ansiedade?</b>
                <br />O canabidiol pode diminuir sintomas de ansiedade, como
                angústia e estresse. No entanto, são necessários mais estudos
                sobre a sua segurança e efeitos para que possa aprovado para o
                tratamento da ansiedade.
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: "justify", paddingX: "5%", paddingTop: "1%" }}
              >
                <b>O que é o óleo de canabidiol?</b>
                <br />O óleo de canabidiol, também conhecido como óleo de CBD, é
                uma substância obtida a partir da planta Cannabis sativa que
                contém canabidiol e, algumas vezes, pequenas quantidades de
                tetraidrocanabinol (THC). Este óleo normalmente é utilizado na
                produção de medicamentos que contêm canabidiol na sua
                composição.
              </Typography>
            </>
          )}
          {!readMore && (
            <MKBox sx={{ textAlign: "center", paddingY: 2, paddingX: "10%" }}>
              <MKButton
                variant="gradient"
                color="info"
                onClick={() => setReadMore(true)}
                fullWidth
              >
                CONTINUE LENDO...
              </MKButton>
            </MKBox>
          )}
        </MKBox>
        <Divider />
        <MKBox sx={{ textAlign: "center" }}>
          <MKTypography variant="h4">AGENDE UMA CONSULTA</MKTypography>
          <MKTypography variant="body2" opacity={0.8} mx={2}>
            com profissionais capacitados para identificar e receitar o
            Canabidiol para tratamentos especiais
          </MKTypography>
        </MKBox>
        {professionals && professionals.length > 0 && (
          <Grid container sx={{ paddingY: "3%" }}>
            {professionals.length > 0 &&
              professionals.map((professional) => (
                <Grid item xs={12} lg={6} key={professional.id} p="1%">
                  <MKBox mb={1}>
                    <HorizontalTeamCard
                      image={`https://storage.googleapis.com/mpo_images/professionals/photos/${professional.id}.jpeg`}
                      name={professional.name}
                      position={{
                        color: "info",
                        label: `${
                          professional.services.find(
                            (profServ) => profServ.id === serviceId
                          ).group_name
                        }`,
                      }}
                      resume={professional.resume}
                      rating={professional.rating ?? 0}
                      price={
                        professional.services.find(
                          (profServ) => profServ.id === serviceId
                        ).price
                      }
                      duration={
                        professional.services.find(
                          (profServ) => profServ.id === serviceId
                        )?.duration || 60
                      }
                      id={professional.id}
                      service={serviceId}
                      pathOrigin={pathOrigin}
                    />
                  </MKBox>
                </Grid>
              ))}
          </Grid>
        )}
      </Card>
    </>
  );
}

export default Canabidiol;
