import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import routes from "routes";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";

import { DataGrid, ptBR } from "@mui/x-data-grid";
import "@mui/x-data-grid/DataGrid/useDataGridProps";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

// images
import LogoMPO from "assets/images/logo_horizontal.png";
import MKAlert from "components/MKAlert";

import { api } from "../../../lib/axios";

const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

function StoreGroups() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [alertComponentModal, setAlertComponentModal] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const [openNewOrUpdateModal, setOpenNewModal] = useState(false);

  const [groups, setGroups] = useState([]);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState(true);

  const clearGroup = () => {
    setId("");
    setName("");
    setDescription("");
    setType("");
    setStatus(true);
  };

  const setGroupInfos = (groupInfos) => {
    setId(groupInfos.id);
    setName(groupInfos.name);
    setDescription(groupInfos.description);
    setType(groupInfos.type);
    setStatus(groupInfos.status);
  };

  const handleOpenNewModal = (row) => {
    clearGroup();
    if (row) {
      setGroupInfos(row);
    }
    setOpenNewModal(true);
  };

  const handleCloseNewOrUpdateModal = () => {
    clearGroup();
    setOpenNewModal(false);
  };

  const getGroups = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("products_groups");
      setGroups(data);
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message ||
          "Erro na busca pelos grupos de produtos",
        type: "error",
      });
    }
    setLoading(false);
  };

  const hadndleGroup = async () => {
    setLoading(true);
    try {
      const dataInfos = {
        id,
        name,
        description,
        type,
        status: String(status),
      };

      if (id) {
        await api.put(`products_groups/${id}`, dataInfos);
        setAlertComponentModal({
          message: "Grupo atualizado com sucesso!",
          type: "success",
        });
      } else {
        await api.post("products_groups", dataInfos);
        setAlertComponentModal({
          message: "Grupo criado com sucesso!",
          type: "success",
        });
      }
      handleCloseNewOrUpdateModal();
    } catch (error) {
      setAlertComponentModal({
        message: error?.response?.data?.message || "Erro na criação do produto",
        type: "error",
      });
    }
    await getGroups();
    setLoading(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Produto",
      flex: 1,
      headerClassName: "dataGrid-header",
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 1.5,
      headerClassName: "dataGrid-header",
    },
    {
      field: "type",
      headerName: "Tipo",
      flex: 0.3,
      headerClassName: "dataGrid-header",
    },
    {
      field: " - ",
      headerName: "",
      flex: 0.2,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          <IconButton size="large" onClick={() => handleOpenNewModal(row)}>
            <EditIcon />
          </IconButton>
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      if (Object.keys(groups).length === 0) {
        await getGroups();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 4000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (alertComponentModal.message !== "") {
      setTimeout(() => {
        setAlertComponentModal({
          message: "",
          type: "",
        });
      }, 4000);
    }
  }, [alertComponentModal]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        component="section"
        position="relative"
        py={20}
        width="100%"
        height="100vh"
      >
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Configurações dos Grupos da Loja Virtual
        </Typography>
        <Grid
          container
          alignItems="top"
          sx={{ display: "flex", alignContent: "stretch" }}
        >
          <Grid item xs={12} lg={12} py={1} style={{ textAlign: "center" }}>
            <MKButton
              variant="gradient"
              color="info"
              component={Link}
              to="/administrator-area/store"
            >
              Acessar painel de Produtos
            </MKButton>
          </Grid>
          <Grid item xs={12} lg={12} py={1}>
            <MKBox
              sx={{
                height: "70vh",
                width: "100%",
                paddingX: "10%",
                margin: "10px",
                textAlign: "center",
                "& .dataGrid-header": {
                  backgroundColor: "#00bbd4",
                  color: "#fff",
                  fontWeight: "bold",
                },
              }}
            >
              {alertComponent.message && (
                <Grid item xs={12}>
                  <MKAlert color={alertComponent.type}>
                    {alertComponent.message}
                  </MKAlert>
                </Grid>
              )}
              {Object.keys(groups).length > 0 && (
                <DataGrid
                  rows={groups}
                  columns={columns}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              )}
            </MKBox>
            <Fab
              sx={{ position: "absolute", bottom: 50, right: 30 }}
              color="success"
              aria-label="add"
              onClick={() => handleOpenNewModal()}
            >
              <AddIcon color="white" />
            </Fab>
          </Grid>
        </Grid>
      </MKBox>

      {/* Modal de Criação / Edição */}
      <Dialog
        open={openNewOrUpdateModal}
        onClose={handleCloseNewOrUpdateModal}
        fullScreen={false}
        scroll="body"
        aria-labelledby="create-coupon-title"
        aria-describedby="create-coupon-description"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle id="create-coupon-title" style={styles.dialogTitle}>
          <div>
            <h3>{id ? "Editar Produto" : "Criar Produto"}</h3>
          </div>
          <div>
            <IconButton onClick={handleCloseNewOrUpdateModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {alertComponentModal.message !== "" && (
              <Grid item xs={12}>
                <MKAlert color={alertComponentModal.type}>
                  {alertComponentModal.message}
                </MKAlert>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <MKInput
                variant="outlined"
                label="Nome do Grupo"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MKInput
                variant="outlined"
                label="Descrição"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="type">Tipo de Cadastro</InputLabel>
                <Select
                  variant="outlined"
                  labelId="type"
                  id="type"
                  value={type}
                  sx={{ height: "45px" }}
                  onChange={(e) => setType(e.target.value)}
                  required
                >
                  <MenuItem value="digital">Digital</MenuItem>
                  <MenuItem value="fisico">Físico</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <MKBox
                width="100%"
                sx={{ display: "flex", flexDirection: "row", py: 1 }}
              >
                <Switch
                  checked={!!status}
                  onChange={() => setStatus(!status)}
                />
                <MKTypography variant="body2">
                  Status (Ativo/Inativo)
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              {!id && (
                <MKBox width="100%" p={2}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    onClick={clearGroup}
                    fullWidth
                  >
                    Limpar
                  </MKButton>
                </MKBox>
              )}
              <MKBox width="100%" p={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={hadndleGroup}
                  fullWidth
                >
                  {id ? "Salvar Alterações" : "Criar Novo Grupo"}
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default StoreGroups;
