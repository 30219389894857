/* eslint-disable no-shadow */
/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import routes from "routes";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { DataGrid, GridToolbarColumnsButton, ptBR } from "@mui/x-data-grid";

import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKAlert from "components/MKAlert";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";

// images
import LogoMPO from "assets/images/logo_horizontal.png";

import { api } from "../../../lib/axios";

function StoreSales() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.locale("pt-br");

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "",
  });

  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState([]);

  const [openFinalcialModal, setOpenFinalcialModal] = useState(false);
  const [finalcialInfo, setFinancialInfo] = useState({});
  const [dateStartSelected, setDateStartSelected] = useState(
    dayjs().tz("America/Sao_Paulo").format("YYYY-MM-DD")
  );
  const [dateEndSelected, setDateEndSelected] = useState(
    dayjs().tz("America/Sao_Paulo").format("YYYY-MM-DD")
  );

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const actualDate = dayjs().tz("America/Sao_Paulo").format("YYYY-MM-DD");

  const convertCellPhone = (cellPhoneInput) => {
    let cellPhoneReceived = cellPhoneInput;
    if (cellPhoneReceived.length > 11) {
      // Remove os dígitos iniciais extras
      cellPhoneReceived = cellPhoneReceived.substring(
        cellPhoneReceived.length - 11
      );
    }
    const cellPhoneFormated = cellPhoneReceived
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
    return cellPhoneFormated;
  };

  const convertCPF = (cpfInput) => {
    const cpfFormated = cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    return cpfFormated;
  };

  const handleOpenFinalcialModal = (financialSelected) => {
    setFinancialInfo(financialSelected);
    setOpenFinalcialModal(true);
  };

  const handleCloseFinalcialModal = () => {
    setFinancialInfo({});
    setOpenFinalcialModal(false);
  };

  const getFinalcials = async (
    dateStart = actualDate,
    dateEnd = actualDate,
    page = 1,
    pageSize = 10,
    sortModel,
    filterModel
  ) => {
    setLoading(true);
    const sortBy = sortModel?.length
      ? `${sortModel[0].field}_${sortModel[0].sort}`
      : "";
    try {
      const dataRequest = {
        dateStart: `${dateStart}T00:00:00`,
        dateEnd: `${dateEnd}T23:59:59`,
        page: page + 1,
        pageSize,
        sortBy,
        filterModel,
      };

      const response = await api.get("/sales_products/all/byDate", {
        params: dataRequest,
      });

      const finalcialsRegisters = response.data;
      setRows(finalcialsRegisters.result);
      setRowCount(finalcialsRegisters.count);
    } catch (err) {
      setAlertComponent({
        message:
          err.response?.data?.message ||
          "Erro na extração do relatório financeiro",
        type: "error",
      });
    }
    setLoading(false);
  };

  const extractDateTime = (params) => {
    const registerDateMaskared = dayjs
      .tz(params.row.created_at, "America/Sao_Paulo")
      .subtract(3, "hour")
      .format("DD/MM/YYYY HH:mm");

    return <span>{registerDateMaskared}</span>;
  };

  const extractUserName = (params) => {
    const userName = params.row.user.name || "";
    return <span>{userName}</span>;
  };

  const extractUserMail = (params) => {
    const userMail = params.row.user.email || "";
    return <span>{userMail}</span>;
  };

  const extractUserPhone = (params) => {
    const userPhone = params.row.user.phone || "";
    return <span>{convertCellPhone(userPhone)}</span>;
  };

  const extractPaymentValue = (params) => (
    <span>
      {parseFloat(params.row.value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}
    </span>
  );

  const extractProductName = (params) => {
    const professionalName = params.row.product.name || "";
    return <span>{professionalName}</span>;
  };

  const extractCoupon = (params) => {
    const coupon = params.row?.coupon?.code || "NÃO POSSUI";
    return <span>{coupon}</span>;
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Criado em",
      flex: 0.5,
      headerClassName: "data-grid-header",
      exportable: true,
      filterable: false,
      valueGetter: (params) =>
        dayjs
          .tz(params.row.user.created_at, "America/Sao_Paulo")
          .subtract(3, "hour")
          .format("DD/MM/YYYY HH:mm"),
      renderCell: extractDateTime,
    },
    {
      field: "name",
      headerName: "Usuário",
      flex: 1,
      headerClassName: "data-grid-header",
      exportable: true,
      valueGetter: (params) => params.row.user.name,
      renderCell: extractUserName,
    },
    {
      field: "email",
      headerName: "E-mail do Usuário",
      flex: 1,
      headerClassName: "data-grid-header",
      exportable: true,
      valueGetter: (params) => params.row.user.email,
      renderCell: extractUserMail,
      hide: true,
    },
    {
      field: "phone",
      headerName: "Telefone do Usuário",
      flex: 1,
      headerClassName: "data-grid-header",
      exportable: true,
      valueGetter: (params) => convertCellPhone(params.row.user.phone),
      renderCell: extractUserPhone,
      hide: true,
    },
    {
      field: "price",
      headerName: "Valor",
      flex: 0.5,
      headerClassName: "data-grid-header",
      exportable: true,
      valueGetter: (params) =>
        parseFloat(params.row.price).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
      renderCell: extractPaymentValue,
    },
    {
      field: "method",
      headerName: "Método Pagamento",
      flex: 0.5,
      headerClassName: "data-grid-header",
      exportable: true,
      valueGetter: (params) => params.row.payment?.method || "Não Informado",
      hide: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      headerClassName: "data-grid-header",
      exportable: true,
      valueGetter: (params) =>
        params.row.deleted_at ? "CANCELADO" : "PROCESSADO",
    },
    {
      field: "coupon",
      headerName: "Cupom",
      flex: 0.5,
      headerClassName: "data-grid-header",
      exportable: true,
      valueGetter: (params) => params.row?.coupon?.code || "NÃO POSSUI",
      renderCell: extractCoupon,
    },
    {
      field: "productName",
      headerName: "Produto",
      flex: 1,
      headerClassName: "data-grid-header",
      exportable: true,
      valueGetter: (params) => params.row.product.name,
      renderCell: extractProductName,
    },
    {
      field: "actions",
      headerName: "Ações",
      disableColumnMenu: true,
      disableColumnFilter: true,
      flex: 0.2,
      headerClassName: "dataGrid-header",
      exportable: false,
      filtrable: false,
      sortable: false,
      renderCell: ({ row }) => (
        <MKBox>
          <IconButton
            size="large"
            onClick={() => handleOpenFinalcialModal(row)}
          >
            <PreviewIcon />
          </IconButton>
        </MKBox>
      ),
    },
  ];

  const downloadCsv = async () => {
    setLoading(true);
    try {
      if (dateStartSelected && dateEndSelected) {
        const dataRequest = {
          dateStart: `${dateStartSelected}T00:00:00`,
          dateEnd: `${dateEndSelected}T23:59:59`,
          sortBy: "created_at",
          format: "csv",
        };

        const response = await api.get(
          "/sales_products/all/byDate",
          {
            params: dataRequest,
          },
          {
            responseType: "blob",
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `venda-produtos-mpo-${dateStartSelected}-${dateEndSelected}.csv`
        );
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
      } else {
        setAlertComponent({
          message: "Selecione uma data inicial e uma data final",
          type: "error",
        });
      }
    } catch (error) {
      setAlertComponent({
        message: "Erro ao processar o download",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchRows = async () => {
      await getFinalcials(
        dateStartSelected,
        dateEndSelected,
        page,
        pageSize,
        sortModel,
        filterModel
      );
    };

    fetchRows();
  }, [
    dateStartSelected,
    dateEndSelected,
    page,
    pageSize,
    sortModel,
    filterModel,
  ]);

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      if (rows.length === 0) {
        await getFinalcials();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>

      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />

      <Grid
        container
        style={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          paddingTop: "10%",
          paddingInline: "5%",
        }}
        spacing={2}
      >
        {alertComponent.message !== "" && (
          <Grid item xs={12} p={2}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <MKInput
            label="Data inicial"
            type="date"
            value={dateStartSelected}
            onChange={(e) => setDateStartSelected(e.target.value)}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MKInput
            label="Data final"
            type="date"
            value={dateEndSelected}
            onChange={(e) => setDateEndSelected(e.target.value)}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MKButton
            variant="contained"
            onClick={downloadCsv}
            sx={{ width: "100%" }}
          >
            Download de Relatório
          </MKButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            height: "70vh",
            "& .grid-header": {
              backgroundColor: "#f0f0f0",
            },
            "& .rowRemoved": {
              backgroundColor: "#ffbaba",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            pagination
            paginationMode="server"
            onPageChange={(pageSelect) => setPage(pageSelect)}
            onPageSizeChange={(sizePage) => setPageSize(sizePage)}
            onSortModelChange={(sortPage) => setSortModel(sortPage)}
            onFilterModelChange={(filterPage) => setFilterModel(filterPage)}
            disableSelectionOnClick
            rowCount={rowCount}
            rowsPerPageOptions={[10, 25, 50, 100]}
            loading={loading}
            density="compact"
            components={{ Toolbar: GridToolbarColumnsButton }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            getCellClassName={(params) =>
              params.row.deleted_at !== null ||
              params.row.status === "CANCELADO"
                ? "rowRemoved"
                : ""
            }
          />
        </Grid>
      </Grid>

      {/* Modal para exibição dos detalhes da venda */}
      <Dialog
        open={openFinalcialModal}
        onClose={handleCloseFinalcialModal}
        maxWidth="xxl"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography variant="body1">Dados da Venda</Typography>
          <CloseIcon
            onClick={handleCloseFinalcialModal}
            sx={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "40px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">Detalhes do Agendamento</Typography>
            </Grid>
            {finalcialInfo && (
              <>
                <Grid item xs={12} md={5}>
                  <div
                    style={{
                      border: "1px solid #cac9c9",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body1">
                      <b>ID: </b>
                      {finalcialInfo.id}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      border: "1px solid #cac9c9",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body1">
                      <b>Data Pedido: </b>
                      {dayjs
                        .tz(finalcialInfo.created_at, "America/Sao_Paulo")
                        .subtract(3, "hour")
                        .format("DD/MM/YYYY HH:mm")}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div
                    style={{
                      border: "1px solid #cac9c9",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body1">
                      <b>Status: </b>
                      {finalcialInfo.status}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div
                    style={{
                      border: "1px solid #cac9c9",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body1">
                      <b>Produto: </b>
                      {finalcialInfo.product?.name
                        ? finalcialInfo.product.name
                        : "Não Informado"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div
                    style={{
                      border: "1px solid #cac9c9",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body1">
                      <b>Preço: </b>
                      {parseFloat(finalcialInfo.price).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                  <Typography variant="h6">Detalhes do Comprador</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div
                    style={{
                      border: "1px solid #cac9c9",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body1">
                      <b>Nome: </b>
                      {finalcialInfo.user?.name
                        ? finalcialInfo.user.name
                        : "Não informado"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      border: "1px solid #cac9c9",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body1">
                      <b>CPF: </b>
                      {finalcialInfo.user?.cpf
                        ? convertCPF(finalcialInfo.user.cpf)
                        : "Não informado"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div
                    style={{
                      border: "1px solid #cac9c9",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body1">
                      <b>E-mail: </b>
                      {finalcialInfo.user?.email || "Não informado"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      border: "1px solid #cac9c9",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body1">
                      <b>Telefone: </b>
                      {finalcialInfo.user?.phone
                        ? convertCellPhone(finalcialInfo.user.phone)
                        : "Não informado"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                  <Typography variant="h6">Detalhes do Pagamento</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      border: "1px solid #cac9c9",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body1">
                      <b>CUPOM: </b>
                      {finalcialInfo.coupon?.code
                        ? finalcialInfo.coupon.code
                        : "Não informado"}
                    </Typography>
                  </div>
                </Grid>
                {finalcialInfo?.payment && (
                  <>
                    <Grid item xs={12} md={4}>
                      <div
                        style={{
                          border: "1px solid #cac9c9",
                          backgroundColor: "#f0f0f0",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Typography variant="body1">
                          <b>Data: </b>
                          {dayjs
                            .tz(finalcialInfo.payment.date, "America/Sao_Paulo")
                            .subtract(3, "hour")
                            .format("DD/MM/YYYY HH:mm")}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <div
                        style={{
                          border: "1px solid #cac9c9",
                          backgroundColor: "#f0f0f0",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Typography variant="body1">
                          <b>Método: </b>
                          {finalcialInfo.payment?.method
                            ? finalcialInfo.payment.method
                            : "Não informado"}
                        </Typography>
                      </div>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default StoreSales;
