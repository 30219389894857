/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import routes from "routes";

import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import Menu from "components/Menu";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";

import LogoMPO from "assets/images/logo_horizontal.png";

import { api } from "../../lib/axios";

function StorePage() {
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [routeIndex, setRouteIndex] = useState("public");

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "info",
  });
  const [loading, setLoading] = useState(false);

  const [productGroups, setProductGroups] = useState([]);
  const [digitalProducts, setDigitalProducts] = useState([]);
  const [allDigitalProducts, setAllDigitalProducts] = useState([]);

  const getProducts = async () => {
    setLoading(true);
    try {
      const response = await api.get("/products");
      const products = response.data.filter((prod) => prod.status === true);
      products.sort((a, b) => {
        const orderColumn1 =
          a.order_column !== null
            ? a.order_column.toLowerCase()
            : "zzzzzzzzzzzzzzzzzzzzzzzzz";
        const orderColumn2 =
          b.order_column !== null
            ? b.order_column.toLowerCase()
            : "zzzzzzzzzzzzzzzzzzzzzzzzz";
        if (orderColumn1 < orderColumn2) {
          return -1;
        }
        if (orderColumn1 > orderColumn2) {
          return 1;
        }
        // Se as colunas de ordem forem iguais, ordene alfabeticamente
        const name1 = a.name.toLowerCase();
        const name2 = b.name.toLowerCase();
        if (name1 < name2) {
          return -1;
        }
        if (name1 > name2) {
          return 1;
        }
        return 0;
      });

      setDigitalProducts(products);
      setAllDigitalProducts(products);
    } catch (err) {
      setAlertComponent({
        message: err?.response?.data?.message || "Erro na busca dos produtos",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getProductsGroups = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("products_groups");
      const groupOrdened = data.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setProductGroups(groupOrdened);
    } catch (error) {
      setAlertComponent({
        message:
          error?.response?.data?.message ||
          "Erro na busca dos grupos de produtos",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleGroupFilters = (group) => {
    const filtered = allDigitalProducts.filter(
      (prod) => prod.products_group.id === group
    );
    setDigitalProducts(filtered);
  };

  useEffect(() => {
    if (
      authenticatedUser === null &&
      localStorage.getItem("user") &&
      routeIndex === "public"
    ) {
      setAuthenticatedUser(JSON.parse(localStorage.getItem("user")));
    }
    async function getInfos() {
      if (digitalProducts.length === 0) {
        await getProducts();
      }
      if (productGroups.length === 0) {
        await getProductsGroups();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (authenticatedUser !== null) {
      setRouteIndex(authenticatedUser.type);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      const timer = setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Grid container py="15vh">
        {alertComponent.message !== "" && (
          <Grid item xs={12}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <MKTypography variant="h3" textAlign="center">
            Loja virtual
          </MKTypography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <MKTypography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
            Filtre pela Categoria:
          </MKTypography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          {productGroups.map((prodGroup) => (
            <MKButton
              key={prodGroup.id}
              variant="gradient"
              color="info"
              onClick={() => handleGroupFilters(prodGroup.id)}
              sx={{
                width: "100%",
                marginX: "1%",
                height: "48px",
              }}
            >
              {prodGroup.name}
            </MKButton>
          ))}
        </Grid>
        <Grid item xs={12} sx={{ my: 1 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ margin: "1.2rem" }}>
          <Grid container spacing={4}>
            {digitalProducts.length > 0 &&
              digitalProducts.map((product) => (
                <Grid item key={product.id} xs={12} md={6} lg={3}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    component={Link}
                    to={`/productdetails/${product.id}`}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: "auto",
                        paddingY: 2,
                      }}
                      image={`https://storage.googleapis.com/mpo_images/products/photos/${product.id}.jpeg`}
                      alt={product.name}
                    />

                    <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        sx={{ textAlign: "center" }}
                      >
                        {product.name}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: "center" }}>
                        {product.products_group.name}
                      </Typography>
                      <Divider />
                      <Typography
                        variant="h5"
                        sx={{ textAlign: "center", width: "100%" }}
                      >
                        R$ {product.price.toFixed(2).replace(".", ",")}
                      </Typography>
                      <Divider />
                      <MKButton
                        color="secondary"
                        sx={{ padding: 2, width: "90%" }}
                        component={Link}
                        to={`/productdetails/${product.id}`}
                      >
                        Detalhes
                      </MKButton>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: 5 }} />
      </Grid>
    </>
  );
}

export default StorePage;
