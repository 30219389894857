/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Link } from "@mui/material";
import LogoMPO from "assets/images/logo_horizontal.png";
import { api } from "../../lib/axios";

function DownloadPage() {
  const { id } = useParams();

  const [done, setDone] = useState(false);
  const [url, setUrl] = useState("");

  const [downloadMessage, setDownloadMessage] = useState("");

  const handleClick = () => {
    setDownloadMessage("Baixando arquivo...");
    setTimeout(() => {
      setDone(true);
      setDownloadMessage("Arquivo baixado com sucesso.");
    }, 1000);
  };

  const getDownloadInfos = async (attachmentId) => {
    try {
      const { data } = await api.get(`/documents/${attachmentId}`);
      setUrl(data.url);
    } catch (error) {
      setDownloadMessage("Erro ao baixar arquivo");
      setDone(true);
    }
  };

  useEffect(() => {
    getDownloadInfos(id);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box sx={{ margin: 5 }}>
        <img src={LogoMPO} alt="Logo MPO" />
      </Box>
      {done && <h3>{downloadMessage}</h3>}
      {!done && (
        <Box sx={{ margin: 5 }}>
          <Link href={url}>
            <Button
              onClick={handleClick}
              variant="contained"
              color="success"
              sx={{ fontSize: "1.5rem" }}
            >
              CLIQUE AQUI PARA ABRIR O ARQUIVO
            </Button>
          </Link>
        </Box>
      )}
    </div>
  );
}

export default DownloadPage;
