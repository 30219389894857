import React from "react";
import routes from "routes";

import Menu from "components/Menu";

import { Box, Container, Grid, Typography } from "@mui/material";

import LogoMPO from "assets/images/logo_horizontal.png";
import Mascote from "assets/images/mascote.png";

function Unavaliable() {
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  return (
    <>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            height: "100vh",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <img src={Mascote} alt="logo" width="50%" />
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "bold", padding: 2 }}
            >
              Esse profissional não está atendendo no momento
            </Typography>
          </Box>
        </Grid>
      </Container>
    </>
  );
}

export default Unavaliable;
