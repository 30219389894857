/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext } from "react";

import useAuth from "./hooks/useAuth";

const Context = createContext();

function AuthProvider({ children }) {
  const { authenticated, handleLogin, handleLogout, getAuthenticatedUser } =
    useAuth();

  return (
    <Context.Provider
      value={{ authenticated, handleLogin, handleLogout, getAuthenticatedUser }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
