/* eslint-disable no-alert */
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import googlePlay from "assets/images/logos/googlePlay.png";
import appleStore from "assets/images/logos/appleStore.png";

function AppLinks() {
  return (
    <MKBox component="section">
      <Container>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={12} lg={12} textAlign="center">
            <MKTypography variant="body1" fontWeight="medium" mb={3}>
              Baixe nosso APP nas lojas:
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <MKBox
              component="img"
              src={appleStore}
              alt="Apple Store"
              width="100%"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/meu-psiquiatra-online/id6449006140",
                )
              }
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <MKBox
              component="img"
              src={googlePlay}
              alt="Google Play"
              width="100%"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.meupsiquiatraonline.mpo",
                )
              }
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
      </Container>
    </MKBox>
  );
}

export default AppLinks;
