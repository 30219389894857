/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ProfessionalCheckbox({
  groups,
  services,
  professionalServices,
  onSelectionChange,
}) {
  const [servicePrice, setServicePrice] = useState({});
  const [serviceDuration, setServiceDuration] = useState({});

  const handleChangeServiceValue = (value, id) => {
    const numberStr = value.replace(/[^0-9.]/g, "");
    const newValueInput = parseFloat(numberStr);
    setServicePrice((prevState) => ({
      ...prevState,
      [id]: newValueInput,
    }));
  };

  const handleChangeServiceDuration = (duration, id) => {
    const newDurationInput = parseFloat(duration.replace(/[^0-9.]/g, ""));
    setServiceDuration((prevState) => ({
      ...prevState,
      [id]: newDurationInput,
    }));
  };

  const handleChangeSelected = (id) => {
    if (professionalServices.some((item) => item.id === id)) {
      const newValues = professionalServices.filter((item) => item.id !== id);
      onSelectionChange(newValues);
    } else {
      const servValue = servicePrice[id] ?? 0;
      const servDuration = serviceDuration[id] ?? 60;
      const newValues = [
        ...professionalServices,
        { id, price: servValue, duration: servDuration },
      ];
      onSelectionChange(newValues);
    }
  };

  useEffect(() => {
    professionalServices.forEach((item) => {
      setServicePrice((prevState) => ({
        ...prevState,
        [item.id]: item?.price ?? 0,
      }));
      setServiceDuration((prevState) => ({
        ...prevState,
        [item.id]: item?.duration ?? 60,
      }));
    });
  }, []);

  useEffect(() => {
    const updatedServices = professionalServices.map((item) => ({
      ...item,
      price: servicePrice[item.id] ?? 0,
      duration: serviceDuration[item.id] ?? 60,
    }));
    onSelectionChange(updatedServices);
  }, [servicePrice, serviceDuration]);

  return (
    <Box>
      {groups.map((group) => {
        const servicesfromGroup = services.filter(
          (service) => service.service_group_id === group.id,
        );
        if (servicesfromGroup.length === 0) {
          return null;
        }
        return (
          <Box key={group.id} sx={{ mb: 1 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body1">{group.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {servicesfromGroup.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          value={item.id}
                          onChange={() => handleChangeSelected(item.id)}
                          checked={professionalServices.some(
                            (sv) => sv.id === item.id,
                          )}
                        />
                      }
                      label={item.name}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <TextField
                        label="Valor"
                        value={servicePrice[item.id] ?? 0}
                        onChange={(e) =>
                          handleChangeServiceValue(e.target.value, item.id)
                        }
                        sx={{
                          width: "100px",
                          height: "36px",
                          marginY: 1,
                          marginX: 0.3,
                        }}
                      />
                      <TextField
                        label="Duração"
                        value={serviceDuration[item.id] ?? 0}
                        onChange={(e) =>
                          handleChangeServiceDuration(e.target.value, item.id)
                        }
                        sx={{
                          width: "100px",
                          height: "36px",
                          marginY: 1,
                          marginX: 0.3,
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
    </Box>
  );
}

export default ProfessionalCheckbox;
